import React,{useState} from 'react';
//import ModalP from "../Promos/PromoLlamadaModal";
import ModalDatGen from './IncModalDatGen';
import NuevaIncidencia from './NuevaIncidenciaModal';
import ReactTooltip from "react-tooltip";
import XLSX from 'xlsx';
import moment from 'moment';
const IncTablaDetalle = ({valores}) => {
    //let datos=valores;    
    let datos=valores    
    let auxArreglo=[]
    const [tabla,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [mensaje,setMensaje]=useState("");
    const [mensaje2,setMensaje2]=useState("");
    const [arreExcel,setArreExcel]=useState(["#","Horario","Sección","Casilla","Descripción"])
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Incidencias de tipo " +".xlsx")
    };
    const llamdaModal=()=>{
        setMensaje("is-active")
    }
    const llamdaModal2=()=>{
        setMensaje2("is-active")
    }
    function filtro(){        
        let auxCadena=nombre.trim();    
        auxArreglo=[]               
        let info=datos.filter((dat)=>dat.Descr.search(auxCadena ) !== -1)         
        return TablaPromo(info);
    }
    
    function TablaPromo(datos) {
        let numRenglon=0;
        let tot= Object.keys(datos).length;
        let auxi2=["#","Horario","Sección","Casilla","Descripción"];
        auxArreglo.push(auxi2);
       // console.log(tot);
       if(tot===0){
            let auxi3=["","Sin","Incidencias" ,"de este ","tipo"]
            auxArreglo.push(auxi3);            
        }   
        return(
            <div id="TablaIncidenciaDetalle">
                <ReactTooltip place="left" type="success" effect="solid" />
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>                                                        
                            <th style={{padding: 0.2 +'rem'}}>{tot} </th>               
                            <th style={{padding: 0.2 +'rem'}}>Hora</th>
                            <th style={{padding: 0.2 +'rem'}}>Secc</th> 
                            <th style={{padding: 0.2 +'rem'}}>Casilla</th>      
                            <th style={{padding: 0.2 +'rem'}}>Descripción</th>                                           
                        </tr>                                                
                    </thead>  
                    <tbody>          
                        {datos.map((auxIncidencia,index) => {                                                                                    
                            let auxi=[ index+1 , auxIncidencia.Horario , auxIncidencia.SeccionNom, auxIncidencia.CasillaNom , auxIncidencia.Descr ]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === auxIncidencia.Id ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(auxIncidencia.Id,auxIncidencia) }  >                                
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span data-tip="Información de la incidencia">
                                        <a className="" onClick={llamdaModal2}> <img src={tabla === auxIncidencia.Id ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                    </span>                              
                                </th>                                                                                                                                                                                                                  
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxIncidencia.Horario}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxIncidencia.SeccionNom}  </span></th>  
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxIncidencia.CasillaNom}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear3 is-size-7 " >{auxIncidencia.Descr.length>100?(auxIncidencia.Descr.substring(0,100)):(auxIncidencia.Descr)}  </span></th>                                                                                                          
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }

    
	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[_:$!%-({})/*?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
        
    };
    //
    /*const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Promovidos"><a className="pt-2 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  className="pt-2 " alt="" border="0" height="30" width="30"/> 
                        </a></span>*/
    const btnExportar = <span data-tip="Exportar Incidencias"><a className="pt-2 pl-2"  onClick={exportFile}>
                            <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
                        </a></span>                        
    const btnNuevoRegistro=<div className="pl-5"><NuevaIncidencia mostrar={mensaje} setMensaje={setMensaje} /></div>         
    const modal2= mensaje2? (<ModalDatGen datGen={registro} mostrar={mensaje2} setMensaje={setMensaje2} />):(null);      
   //const modal1= mensaje? (<ModalPrograma idValor={tabla} mostrar={mensaje} setMensaje={setMensaje}/>):(null);        
   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= valores?(contenido):(<div><h2>No contiene datos</h2></div>)        
    return (       
        <div className="contenido-tbPromov  pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal ">            
                                <div className=" field-body  ">
                                    <input
                                        className="input " 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar incidencias"
                                        value={nombre}
                                        onChange={onChange}/>
                                      
                                
                                </div>                                
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                
                                {/* btnNuevoRegistro */ btnNuevoRegistro}
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>    
            {modal2}
            {/* modal1 */}
        </div>
           
     
    );
}
 
export default IncTablaDetalle;