import React,{useState,useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
import ListaPromotor from './MapaListaPromotor';
const MapaConsultar = ({poligonos}) => {
    const [lista,setLista] = useState("");
    const [vacio,setVacio] = useState(true);    
    let idusu =localStorage.getItem('UsuNum') ;
    let history = useHistory();
    useEffect(()=>{         
        //console.log("llamada a consultar mapa");
        const source = axios.CancelToken.source();
        async function conectarJSon()  {       
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promotor-lista`;   
            let auxU=process.env.REACT_APP_LINK +`/promotor-lista`;   
            let token =localStorage.getItem('token20') ;          
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {
               //console.log("entrando ");
                aux=  response.data;
                //console.log(aux);                
                if (aux.resultado) 
                {   setLista(aux.resultado);                    
                }else
                {   setVacio(false)
                }                                            
            })
            .catch(function (error) {                                
                if (error.response.status>200)
                {
                //  console.log("error con el token");
                //console.log(`Error: ${error.response.data.mensaje} `);
                    if ( error.response.status!==401 && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
                    { //  console.log(error.response.data.error.name);
                        //console.log('expiro token , loguearse de nuevo');
                        localStorage.clear();
                        //window.location.replace('');
                        history.push("/");                
                        window.location.reload(true);
                        //console.log("seguir imprimiendo");                
                    }
                    else{
                        if (error.response.data.mensaje==="No se agrego el Token en los Headers de la petición.") {
                          //  console.log('loguearse de nuevo');
                            localStorage.clear();
                            //window.location.replace('');
                            history.push("/");                
                            window.location.reload(true);
                        }
                    }
                }
            });                            
        };
        conectarJSon();                           
    },[]);
    const contenido = lista? (<ListaPromotor listaPromotores={lista} poligonos={poligonos}/>):(<Cargando/>)
    return (
        <div>            
            {contenido}
        </div>
    );
}
 
export default MapaConsultar;