import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Autocomplete,Polyline,Polygon, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "600px",
  width: "1000px"
}

const center = {
  lat: 19.5953512,  lng: -98.9210809

}
const MyMapWithAutocomplete = ({poligonos,direcc,setPromDir,setPromGPS,esperando}) => {
  const [gps, setGps] = useState([])
  const [rutaPol, setRutaPol] = useState([]);
  const [autocomplete, setAutocomplete] = useState([]);
  let arreColores= ["#F692158C","#F615159E","#15F67CA1","lightgreen","#15F6E6FF","lightblue","#F615159E","lightred","lightgreen","lightyellow"];
  //let autocomplete = [];
  let arreRutas=[];   
  let arreRutaSeccion=[];   
  useEffect(()=>{ 
             
      if (!esperando)
      {
        setGps([]);
      }else
      {
        if (poligonos)
        {
          let contador=0;
          let variable=0;
          poligonos.map((poligono) => {
            if (variable!==poligono.seccionId) {
              variable=poligono.seccionId;
              arreRutas=[]
              arreRutaSeccion.push(arreRutas);                        
            }
            if(poligono.seccionId===variable)
            { 
              let aux1=poligono.seccionLat;            
              aux1=parseFloat(aux1)
              let aux2=poligono.seccionLon;            
              aux2=parseFloat(aux2)
            // console.log(aux1,aux2 );
              arreRutas.push({ lat : aux1, lng :aux2 })
              
            }
              
          })
        //  console.log(arreRutaSeccion);
          setRutaPol(arreRutaSeccion);
        }
      }
},[poligonos,esperando]);

  const onLoad =(respuesta)=> {
   // console.log('autocomplete: ', respuesta)

    //autocomplete = respuesta
    setAutocomplete(respuesta);
    //this.gps = autocomplete.getPlace().geometry.location
  }

  const onPlaceChanged =() =>{
    if ( autocomplete !== null||autocomplete.length>0  ) {

      console.log(autocomplete.getPlace())
      console.log(`location[${JSON.stringify(autocomplete.getPlace().geometry.location)}]`);
      //console.log(`location[${JSON.stringify(autocomplete.getPlace().formatted_address)}]`)
        // this.gps=this.autocomplete.getPlace().geometry.location
      //console.log(`lat[${this.autocomplete.getPlace().location.lat}] lon[${this.autocomplete.getPlace().location.lng}]`)
      setPromDir(autocomplete.getPlace().formatted_address)
      setGps(autocomplete.getPlace().geometry.location);        
      setPromGPS(autocomplete.getPlace().geometry.location);
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }
  
  const ruta = [
    { lat:19.5918776, lng: -98.9458509}, 
    { lat:19.5919335, lng: -98.9461079}, 
    { lat:19.5920984, lng: -98.9459063}, 
    { lat:19.5920167, lng: -98.9460195}, 
    { lat:19.5920691, lng: -98.9460953}, 
    { lat:19.5919833, lng: -98.9462753}, 
    { lat:19.5919122, lng: -98.9460067}, 
    { lat:19.5919633, lng: -98.9462833}, 
    { lat:19.5921235, lng: -98.9460379}, 
    { lat:19.5920298, lng: -98.9459322}, 
    { lat:19.591911, lng: -98.9458206 }, 
    { lat:19.5920739, lng: -98.9459838 }, 
    { lat:19.5915548, lng: -98.9460917 }, 
    { lat:19.5921164, lng: -98.9459728 }, 
    { lat:19.591688, lng: -98.9458855 }, 
    { lat:19.591955, lng: -98.9448117 }, 
    { lat:19.5918784, lng: -98.9460083 }, 
  ];
  const opcionRuta = {
    strokeColor: '#3273DC',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#3273DC',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
  };
  const onLoadPoliLinea = polyline => {
    //console.log('polyline: ', polyline)
  };
  const paths = [
    { lat:19.596506, lng:-98.9723341 },
    { lat:19.5969407, lng:-98.9724146 },
    { lat:19.5979211, lng:-98.9724039 },
    { lat:19.598482, lng:-98.9724843 },
    { lat:19.5989267, lng:-98.9724199 },
    { lat:19.6003316, lng:-98.9725004 },
    { lat:19.604046, lng:-98.9727096 },
    { lat:19.6038944, lng:-98.9719801 },
    { lat:19.6041319, lng:-98.9719211 },
    { lat:19.6057288, lng:-98.9709662 },
    { lat:19.6059815, lng:-98.9708857 },
    { lat:19.6085587, lng:-98.9694588 },
    { lat:19.6088467, lng:-98.9696144 },
    { lat:19.6090488, lng:-98.9672486 },
    { lat:19.60916, lng:-98.9628498 },
    { lat:19.6098119, lng:-98.9541166 },
    { lat:19.6101505, lng:-98.9485107 },
    { lat:19.6097512, lng:-98.9431946 },
    { lat:19.609731, lng:-98.9419071 },
    { lat:19.6097614, lng:-98.9414994 },
    { lat:19.6096098, lng:-98.9393429 },
    { lat:19.6097411, lng:-98.9391874 },
    { lat:19.6093015, lng:-98.9388172 },
    { lat:19.6089326, lng:-98.9383237 },
    { lat:19.6088113, lng:-98.9373367 },
    { lat:19.6085261, lng:-98.9373633 },
    { lat:19.6077984, lng:-98.937417 },
    { lat:19.6087585, lng:-98.9430121 },
    { lat:19.6088949, lng:-98.9484087 },
    { lat:19.609562, lng:-98.9484462 },
    { lat:19.6094205, lng:-98.9509514 },
    { lat:19.6091021, lng:-98.9543954 },
    { lat:19.6089455, lng:-98.9567074 },
    { lat:19.6090213, lng:-98.9567289 },
    { lat:19.6089505, lng:-98.9579144 },
    { lat:19.6092184, lng:-98.9581987 },
    { lat:19.6091072, lng:-98.9582846 },
    { lat:19.6091375, lng:-98.9587835 },
    { lat:19.6090769, lng:-98.9587995 },
    { lat:19.6089253, lng:-98.9642283 },
    { lat:19.6087484, lng:-98.9664492 },
    { lat:19.6086726, lng:-98.9665833 },
    { lat:19.608708, lng:-98.9668623 },
    { lat:19.6086221, lng:-98.9672056 },
    { lat:19.6085915, lng:-98.967592 },
    { lat:19.6085362, lng:-98.9682356 }
  ]
  const options =(numero)=> {
    

    const auxiliar={  
    fillColor: arreColores[numero],
    fillOpacity: 0.5,
    strokeColor: "orange",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }
  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }
  return (    
    <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" libraries={["places"]}> 
      <GoogleMap
        id="searchbox-example"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
      >
      {esperando?(  <Autocomplete className="pt-3" onLoad={onLoad} onPlaceChanged={onPlaceChanged}
          restrictions={{country: ['mx']}}
        >
          <input
          
            type="text"
            placeholder="Escribe dirección"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `540px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px"
            }}
          />
      
    </Autocomplete>):null
    }
    { gps!==null&&esperando? <div><Marker
        onLoad={onLoad}
        position={gps}
    />      
        </div>:null        
        /*<Polyline
        onLoad={onLoadPoliLinea}
        path={ruta}
        options={opcionRuta}
    />*/
    }
    { direcc!==null? <div><Marker
        onLoad={onLoad}
        position={direcc}
    />      
        </div>:null             
    }
    { rutaPol!==null?
      rutaPol.map((poligono,index) => 
      {//console.log(poligono);
        return(
      <Polygon
          onLoad={onLoadPolygono}
        paths={poligono}
        options={options(index)}
      />)
    })
      
      :null
    }
       
  </GoogleMap>
</LoadScript>  );
}
 
export default MyMapWithAutocomplete;
