const c=console.log

export const LimpiarCadena=(cadena)=>{
    c(cadena);
    let resultado='';
    if (cadena.length>=1) {
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        let aux=cadena.substring(cadena.length-1,cadena.length);
        
        if (expCadena.test(aux)&&!expNopermitida.test(aux)&&!expMenos.test(aux)&&!expMas.test(aux)||aux===' '||aux==='') 
        {   c(aux);
            resultado=cadena  
            
        } else{
            resultado =cadena.substring(0,cadena.length-1);;
        }  
    }
    
    return resultado; 
}

