import React,{useState,useEffect} from 'react';
import Modal from '../Promos/PromoModal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
const NuevaIncidenciaModal = ({promovido,datos,setDatos,mostrar,setMensaje}) => {
    let idusu =localStorage.getItem('UsuNum') ;
    
    const [classNameModal,setclassNameModal]=  useState(mostrar);         
    //const [nuevaIncidencia,setNuevaIncidencia] = useState({ Tipo:0,Usuario:0,Casilla:0,Descripcion:"",Nota:""});
    
    const[espera,setEspera]=useState(true);
    const[espera2,setEspera2]=useState(true);
    const [seccion,setSeccion]=  useState("");
    const[registros,setRegistros]=useState(true);
    const[leyenda,setLeyenda]=useState("");
    useEffect(()=>{                         
        let auxMensaje=""
        if (promovido.Moviliza===1) 
        {
            auxMensaje="¿Desea Quitar Movilización?";
            setLeyenda(auxMensaje)
        }
        else
        {
            auxMensaje="¿Desea  Movilizar?";
            setLeyenda(auxMensaje)
        }

                
    },[]);                   
    
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    {         
        setMensaje("");
        setclassNameModal("");
    }

    
    const llamarApi=()=>{
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {                    ; 
            setEspera(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-movil-promocion`;               
            let auxU=process.env.REACT_APP_LINK +`/promovido-movil-promocion`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                "usuario":idusu,
                "promovido":promovido.Id,
                "status":(promovido.Moviliza === 1 ? (0) : (1)    )
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            setRegistros(true)
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux.respuesta===1) 
                   {                   
                        const mensaje="Se  realizo cambio"//aux.mensaje ;            
                        toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaMovilizacion'} );   
                        let info=datos.filter((dat)=>dat.Id !== promovido.Id)         
                        console.log(info);
                        let today = new Date();                    
                        let fec = moment(today);
                        
                        let auxi={ 
                                Id:promovido.Id ,
                                UserCambio:promovido.UserCambio,
                                Moviliza:(promovido.Moviliza === 1 ? (0) : (1)    ),
                                Hora:fec.format("HH:mm"), 
                                Nombre:promovido.Nombre, 
                                Tel:promovido.Tel, 
                                Cel:promovido.Cel
                            }
                        info.push(auxi);   
                        console.log(auxi);     
                        console.log(info); 
                        setDatos(info)                     
                                                                                                
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });
            esconderModal();                    
            setEspera(true);            
        };
        conectarJSon();
    }
    const onSubmit = e =>
	{      
		e.preventDefault();
        /*if (seccion!=="")
        {            
        
        }else 
        {
            toast.warning("Debe seleccionar la sección", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaMovilizacion'} );   
        }*/
        llamarApi();                                                 
        
        		
	}
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
       
    }
    
                
    
    
    
    const botones = espera&&espera2 ? (<div className="pt-2 has-text-centered pb-0"><span className="pr-4"><button className="button is-aceptar " onClick={mostrarModal} type="submit">Si</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >No</a></div>  ) : (<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>);

    function llenarTodosSelects()
    {        
        
        return(
            <div> 
                                                    
                <article className="pb-1 pt-4  ">                    
                <p  className="has-text-centered is-size-4">{leyenda}</p>
                <p  className="has-text-centered is-size-4">{promovido.Nombre}</p>
                <br/>
                    
                </article> 
               
                    
            </div>
        )

    };
    const auxiliarFormulario = promovido  ? ( llenarTodosSelects()  ): ( null ) 
    const auxPrincipal= espera2 ?(auxiliarFormulario ):null;
    return (
        <div>
             
        <Modal 
            id={"ModalDatosGenerales"}
            onClose={()=>esconderModal()}
            className={classNameModal}
            title={"Movilización"} 
            ancho={600}
            >
            <form className="" onSubmit={onSubmit}>  
                {auxiliarFormulario}
                
{/*                 <div className="tile pt-2">
                    <div className="tile is-vertical">
                                
                    </div>      
                </div>     */}
                <div className="">
                    {botones }
                </div>
                
                <ToastContainer enableMultiContainer containerId={'ModalNvaMovilizacion'}/>    
            </form>     
    </Modal>
    </div>
);
}
 
export default NuevaIncidenciaModal;