import React,{Component } from 'react';
import { GoogleMap, LoadScript,Autocomplete,Polyline, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "700px",
  width: "1000px"
}

const center = {
  lat: 19.5953512,  lng: -98.9210809

}

class MyMapWithAutocomplete extends Component {
  constructor (props) {
    super(props)
    this.state={
      gps:[]}
    this.autocomplete = null
    
    this.onLoad = this.onLoad.bind(this)
    this.onPlaceChanged = this.onPlaceChanged.bind(this)
  }

  onLoad (autocomplete) {
    console.log('autocomplete: ', autocomplete)

    this.autocomplete = autocomplete
    //this.gps = autocomplete.getPlace().geometry.location
  }

  onPlaceChanged () {
    if (this.autocomplete !== null) {
      console.log(this.autocomplete.getPlace())
      console.log(`location[${JSON.stringify(this.autocomplete.getPlace().geometry.location)}]`)
        // this.gps=this.autocomplete.getPlace().geometry.location
      //console.log(`lat[${this.autocomplete.getPlace().location.lat}] lon[${this.autocomplete.getPlace().location.lng}]`)
      this.setState({
        gps:this.autocomplete.getPlace().geometry.location        
       })
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  render () {
    const { gps} = this.state;
    return (
      <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" libraries={["places"]}> 
        <GoogleMap
          id="searchbox-example"
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={center}
        >
          <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged}
             restrictions={{country: ['mx']}}
          >
            <input
              type="text"
              placeholder="Escribe direccion"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `540px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
            
          </Autocomplete>
          
          { gps!==null? <Marker
              onLoad={this.onLoad}
              position={gps}
          />:null
          
          /*<Marker
              onLoad={this.onLoad}
              position={{"lat":19.2752881,"lng":-99.6715097}}
          />*/
          }
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default MyMapWithAutocomplete