import React,{useState,useEffect} from 'react';
import TablaPromotor from './BusqTablaPromotor';
import TablaUnPromotor from './BusqTablaUnPromotor';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
const Busqueda = () => {
    const [datos,setDatos] = useState("");
    const [lista,setLista] = useState("");
    const [validoPromotor,setValidoPromotor] = useState(false);
    let history = useHistory();
  
    const [vacio,setVacio] = useState(true);    
    let idusu =localStorage.getItem('UsuNum') ;
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
    let idPromot =localStorage.getItem('idPromot') ;
    let mensaje="";
    
    const [secciones,setSecciones] = useState("");  
    useEffect(()=>{ 
        const source = axios.CancelToken.source();
        async function conectarJSon()  {

       
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promotor-lista`;   
            let auxU=process.env.REACT_APP_LINK +`/promotor-lista`;   
            let token =localStorage.getItem('token20') ;          
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {
               // console.log("entrando ");
                aux=  response.data;
                //console.log(aux.status);
                // const auxdat =  await aux; 
                aux=  response.data;
           
                if (aux.resultado) {                    
                    //console.log(aux.resultado);
                    let barrido= aux.resultado;            
                    if (nivelUsuario==='3'||nivelUsuario==='5') 
                    {   /*if (idusu==='1'||idusu==='3'||idusu==='4'||idusu==='257')
                        {
                            setDatos(aux.resultado);
                        }else
                        {
                            let info=barrido.filter((dat)=>dat.PromotId.toString()=== idPromot)
                            setLista(aux.resultado);
                            setDatos(info);
                        }*/
                        let info=barrido.filter((dat)=>dat.PromotId.toString()=== idPromot)
                        setLista(aux.resultado);
                        setDatos(info);
                    }
                    else
                    {   
                        setDatos(aux.resultado);
                    }
                }else{
                    setVacio(false)
                }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`) 
                //console.log("contador es : "+contador+" "+error);                    
                //contador++;
                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    //console.log("seguir imprimiendo");                
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                    setVacio(false)  
                }
            });
                
            //console.log(auxdat.promotores);   
                
            //}
        };
        async function llenaSeccion () 
        {
            // let auxU=`http://`+process.env.REACT_APP_LINK +`/seccion`;                  
        let auxU=process.env.REACT_APP_LINK +`/seccion`;                  
        let token =localStorage.getItem('token20') ;                 
        let dataInfo = qs.stringify({
            'usuario': idusu,             
            'idDispositivo': 'csdq21weddewde' 
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};
        await axios(config)
        .then(function (response) {
            aux=  response.data;                                
            if (aux.secciones) {
                     
                //console.log(`dentro secciones API: ${JSON.stringify(aux.secciones)}`) 
                setSecciones(aux.secciones);                                       
            }                            
        })
        .catch(function (error) {
            console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)             
            //console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
            
            if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
            {   console.log('loguearse de nuevo');
                localStorage.clear();
                //window.location.replace('');
               // history.push("/");                
                window.location.reload(true);
                //console.log("seguir imprimiendo");                
            }
            if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
            {
                console.log('sin datos' );
                //setVacio(false)  
            }
        }); 
        }
        llenaSeccion();           
        conectarJSon();
        if(/*idusu==='4'||*/idusu==='1'||idusu==='3'||idusu==='257'){
            setValidoPromotor(true)
        }
        return ()=> {
            source.cancel();
        }
            
        

},[]);

    //const cargando = vacio ? (<div> <Facebook/></div>):(<h1 className="title is-l has-text-centered">Ruta sin promotores</h1>);
//    const auxmensaje = error ? (<h1 className="title is-l has-text-centered">Falta seleccionar {error}</h1>) : (cargando);
    const carga = <TablaPromotor datosJS={datos} seccion={secciones} validoPromotor={validoPromotor} />;
    const tablaPromo = (nivelUsuario==='3'||nivelUsuario==='5')/*&& !(idusu==='1'||idusu==='3'||idusu==='4'||idusu==='257')*/ ? (<div ><TablaUnPromotor datosJS={datos} seccion={secciones}  lista={lista} validoPromotor={validoPromotor}/>     </div> ) : ( carga);
    const content =secciones&&datos ? (<div >{ tablaPromo}     </div> ) : ( <Cargando />);

    return (<div className="is-flex-direction-row ">
            
                {content}                      
        </div> 
         );
}
 
export default Busqueda;