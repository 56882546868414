import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';
//import BuscaColor from "../Color";
//import Chart from 'chart.js';

const GraficaCoord = ({valor,descripcion,Auxtam}) => {  
  
    let colorTabla =[];
    const auxCoordi = valor;
    let contador=0; 
    let miLabel=[];
    let midata=[];
    let auxDis ="espacioGraf2";
        
    function ColorTab (elcolor) {
      let colorEfecti= '';
      if(elcolor<60){
          colorEfecti= '#FF0505E8';
      }
      else{
          if(elcolor>=60 && elcolor<80)
          {
              colorEfecti= '#F3A80B';               
          }
          else{
              if(elcolor>=80 )
              {
                  colorEfecti= '#1CC63D';
              }
          }
      }
      return (  
            colorEfecti   
          );
    } 
    auxCoordi.forEach( coordi => {
      midata[contador]=coordi.promovidos;
      miLabel[contador]=contador+1;
      let auxiliar =coordi.efectividad;                        
      colorTabla[contador] =ColorTab(auxiliar);                    
      contador++; 
       
   });/*
   const imprime= auxCoordi.map((coordi) => {      
      
        midata[contador]=coordi.promovidos;
        miLabel[contador]=contador+1;
        let auxiliar =coordi.efectividad;                        
        colorTabla[contador] =ColorTab(auxiliar);                    
        contador++; 
         
    })*/
    contador = 0;
    
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Promovidos',//
          backgroundColor: colorTabla,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: 'rgba(255,0,54,0.4)',
          hoverBorderColor: 'rgb(0,88,101)',
          data: midata 
        }
      ]
    };    
    if (Auxtam===1) {
      auxDis="espacioGraf";
    }
  return ( 
    <div id={auxDis}>    
      <div ><h1 id="ajustarTitulo">{descripcion}</h1></div>
        <HorizontalBar
            id="graficaHorBar"
          data={horizontalRuta}
          width={350}
          height={350}
        />                                      
    </div>
   );
}

 
export default GraficaCoord;