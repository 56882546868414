import React,{useState,useEffect} from 'react';
import {HorizontalBar} from 'react-chartjs-2';
//import BuscaColor from "../Color";
//import Chart from 'chart.js';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
const PGraficaSeccion = ({valor,descripcion,tituloSeccion,Auxtam}) => {  
  

//    const[espera,setEspera]=useState(false);
    const [listaXCasilla,setListaXCasilla]=  useState([]);      
    const [miLabel,setMiLabel]=  useState([]);      
    const [midata,setMidata]=  useState([]);      
    const [miColor,setMiColor]=  useState([]);    
    const [btnSeleccionado,setBtnSeleccionado]=  useState(0);      
    const [miCasilla,setMiCasilla]=  useState("Sección");      
    const auxCoordi = valor;
    let contador=0; 
    let auxmiLabel=[];
    let auxmidata=[];
    let auxmiColor=[];
  //let auxDis ="espacioGraf2";
    useEffect(()=>
    {  contador=0;
        let auxiliar =""
        let auxArreglo=[];
        auxArreglo.push({ value : -1 , label : "Excel" })
        auxArreglo.push({ value : 0 , label : "Secc" })
        function SumaPartidos() 
        {
          let auxArreTotal =[];          
          //console.log(valor);
          auxCoordi.forEach( secc =>
          {             
            let resul=[]
            let resul2=[]
            if(auxArreTotal.length===0)
            {  // console.log("una vez");
                resul2=auxCoordi.filter((dat)=>dat.ParId===secc.ParId )
                //console.log(resul);
            }
            else
            {
                resul2=auxCoordi.filter((dat)=>dat.ParId===(secc.ParId ) )  
              //  console.log(resul2);
                resul=auxArreTotal.filter((dat)=>dat.ParId===(secc.ParId ) )             
            }
            if (  resul2.length>1&&resul.length===0)
            {              
                let sumTotalnum=0;
                let sumNombre=secc.ParId;
                resul2.map((seccion) => {                
                  sumTotalnum=sumTotalnum+ seccion.Voto;
                  
                })
                auxArreTotal.push({ value : sumTotalnum , ParId : sumNombre })  
                const voto=sumTotalnum>0?sumTotalnum:""        
                auxmidata[contador]=sumTotalnum;
                auxmiLabel[contador]=secc.ParImg+" "+voto;                
                auxmiColor[contador]=secc.ParColor.substring(0, 7);                    
                contador++;             
            }
            else
            {
              if (resul.length===0) {
                auxArreTotal.push({ value : secc.Voto , ParId : secc.ParId })         
                const voto=secc.Voto>0?secc.Voto:""      
                auxmidata[contador]=secc.Voto;
                auxmiLabel[contador]=secc.ParImg+" "+voto;                  
                auxmiColor[contador]=secc.ParColor.substring(0, 7);    
                contador++;             
              }
                
            }
          });  
        }
        SumaPartidos();
        auxCoordi.forEach( coordi => {
            if (auxiliar!==coordi.Tipo) 
            {
                auxiliar=coordi.Tipo                
                auxArreglo.push({ value : coordi.CasId , label : auxiliar })         
            }
            //auxmidata[contador]=coordi.Voto;
            //auxmiLabel[contador]=coordi.ParImg;                
           // auxmiColor[contador]=coordi.ParColor;          
            //contador++;             
        });
        setMiLabel(auxmiLabel);
        setMidata(auxmidata);
        setMiColor(auxmiColor);
        //console.log(auxArreglo);
        setListaXCasilla(auxArreglo)
        setBtnSeleccionado(0)
    },[valor]);
    const exportFile = () => 
    {
      /* convert state to workbook */
      let auxArregloExcel=[];  
      let auxi2=["#","Casilla","Partido","Votos"];
      let auxnumero = miLabel.length;
      let auxdescripcion="";
      auxArregloExcel.push(auxi2);  
      if (miCasilla==="Sección") 
      { auxdescripcion= miCasilla+" "+tituloSeccion;
        auxCoordi.map((seccion,index) => {                
          let auxi=[index+1,seccion.Tipo,seccion.ParNom, seccion.Voto]
          auxArregloExcel.push(auxi);   
          
        })
      }
      else
      { 
        if (auxnumero>0) {
          auxdescripcion= tituloSeccion+" "+miCasilla ;
          for (let index = 0; index < auxnumero; index++) {

            let auxi=[index+1,miCasilla,miLabel[index], midata[index]]
            auxArregloExcel.push(auxi);   
          }
        }else{
          auxi2=["Sin","Partidos","con Votos"];
          auxArregloExcel.push(auxi2);   
        }
      }
      let today = new Date();                    
      let fec = moment(today);
      //console.log(fec.format("YYMMDDHHMMSS"));
      const ws = XLSX.utils.aoa_to_sheet(auxArregloExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Reporte");
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Votación de " +auxdescripcion+".xlsx")
    };
    function filtrarVot(nombre, numID) {
        contador=0;
        if (numID=== -1) {
          exportFile()
        }
        else
        {
          if (numID===0) 
          {    setMiCasilla(nombre)         
              let auxArreTotal =[];                        
            auxCoordi.forEach( secc =>
            { 
              let resul=[]
              let resul2=[]
              if(auxArreTotal.length===0)
              {   //console.log("una vez");
                  resul2=auxCoordi.filter((dat)=>dat.ParId===secc.ParId )
                  //console.log(resul);
              }
              else
              {
                  resul2=auxCoordi.filter((dat)=>dat.ParId===(secc.ParId ) )  
                  //console.log(resul2);
                  resul=auxArreTotal.filter((dat)=>dat.ParId===(secc.ParId ) )             
              }
              if (  resul2.length>1&&resul.length===0)
              {              
                  let sumTotalnum=0;
                  let sumNombre=secc.ParId;
                  resul2.map((seccion) => {                
                    sumTotalnum=sumTotalnum+ seccion.Voto;
                    
                  })
                  auxArreTotal.push({ value : sumTotalnum , ParId : sumNombre })         
                  const voto=sumTotalnum>0?sumTotalnum:"" 
                  auxmidata[contador]=sumTotalnum;
                  auxmiLabel[contador]=secc.ParImg+" "+voto;                
                  auxmiColor[contador]=secc.ParColor.substring(0, 7);                    
                  contador++;             
              }
              else
              {
                if (resul.length===0) {
                  auxArreTotal.push({ value : secc.Voto , ParId : secc.ParId })         
                  const voto=secc.Voto>0?secc.Voto:"" 
                  auxmidata[contador]=secc.Voto;
                  auxmiLabel[contador]=secc.ParImg+" "+voto;                   
                  auxmiColor[contador]=secc.ParColor.substring(0, 7);    
                  contador++;             
                }
                  
              }
          });  
          }
          else
          {   setMiCasilla(nombre)    
              //console.log("entrando");
              let info=valor.filter((dat)=>dat.Tipo.search(nombre ) != -1)          
              info.forEach( coordi => {               
                const voto=coordi.Voto>0?coordi.Voto:"" 
                  auxmidata[contador]=coordi.Voto;
                  auxmiLabel[contador]=coordi.ParImg+" "+voto;                
                  auxmiColor[contador]=coordi.ParColor.substring(0, 7);          
                  contador++;             
              });
          }
          //console.log(auxmidata);
          //console.log(auxmiLabel);
          //console.log(auxmidata);
          setMiLabel(auxmiLabel);
          setMidata(auxmidata);
          setMiColor(auxmiColor);
          setBtnSeleccionado(numID)
      }
    }
      
    const CrearBotones=(auxArr)=> {
       
        return(
            <div>     
              
            {auxArr.map((casilla,index) =>
            {   let aux=btnSeleccionado!==casilla.value ? "etiqColorSecc has-text-weight-medium is-size-5" :"etiqColorSinSecc has-text-weight-medium is-size-5"                
               // console.log(casilla.label + btnSeleccionado +casilla.value);
               let nada=<div></div>
               if (index===16) {
                return(
                  <span>
                  <br/>
                  <a  className=" pt-0" onClick={()=>filtrarVot(casilla.label,casilla.value)} className= {aux} >
                     {casilla.label}
                  </a>
                  </span>
                  
                )
               }
                return(
                       <a  className=" pt-0" onClick={()=>filtrarVot(casilla.label,casilla.value)} className= {aux} >
                          {casilla.label}
                       </a>
                       
                )
            })}
            </div>
       )
    }
    contador = 0;
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
      },
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 5
        }
      },
      title:{
        fontStyle: 'bold',
      },
     scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            scaleLabel :{
              fontSize:6,
            },
          },
        ],
      },
    }  
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Votos',//
          backgroundColor: miColor,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: '#3273DC',
          hoverBorderColor: 'rgb(0,88,101)',
          data: midata 
        }
      ]
    };    
    /*if (Auxtam===1) {
      auxDis="espacioGraf";
    }*/
  
  const grafica = listaXCasilla?(CrearBotones(listaXCasilla)):null
  /*const btnExportar = <span data-tip="Exportar Votacion"><a className="pt-2 pl-2"  onClick={exportFile}>
                            <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
                        </a></span>*/
  return ( 
    <div className="card  pl-0"id="Prepgrafica" >
      <div className=" marcoGraficaPrepSecc" >
        <div className="content">
          {<h4 className=" pt-1 pb-0"  style={{fontWeight: 700},{fontSize: 10+"px"},{textAlign: "center"}}>{descripcion}</h4>
          }
                 
          {grafica}
          <HorizontalBar
              id="graficaHorBarIncidencia"
              data={horizontalRuta}
              width={1200}
              height={400}
              options={options}
            />                   
        </div>
        <ReactTooltip place="left" type="success" effect="solid" />
      </div>
  </div>
   );
}

 
export default PGraficaSeccion;