import React, { Component} from 'react';

const Modal2 = ({id, children, className, onClose, buttonsFooter, title, ancho}) => {
        return(
            <div id={id} className={`modal ${className}`}>
              <div className="modal-background" onClick={onClose}/>
              <div className="modal-card " style={{width:ancho}}  >
                <header className="modal-card-head">
                  <p className="modal-card-title help is-link" style={{fontSize:1.10+'rem'}, {fontWeight: 'bold'}}>{title}</p>
                  <button className="delete" onClick={onClose}/>
                </header>
                <section className="modal-card-body  " style={{width:ancho}}>
                  {children}
                </section>
                <footer className="modal-card-foot">
                  {buttonsFooter}
                 
                </footer>
              </div>
            </div>
          );
}

export default Modal2;