import React,{useState,useEffect} from 'react';
import MapaConEntrada from "./autocomplete2";
import ModalRegistro from './MapaModalRegistro'
const MapaPanelMap = ({poligonos,setActualizar, actualizar,arrProm,setArregloProm,setDatos,datos}) => {
    const [promDir,setPromDir]=  useState([]);
    const [promGPS,setPromGPS]=  useState([]);
    const [vacio,setVacio]=  useState(false);    
    //const [autocomplete, setAutocomplete] = useState([]);
    const [esperando, setEsperando] = useState(true);
    useEffect(()=>{         
        let auxdatos=[];   
        if (arrProm.length>0) {            
            setVacio(true);
        }
        else{
            setVacio(false);
        }                           
    },[arrProm]);
    const registro = vacio&&promDir.length>0&&promDir!==null? (<span className="pt-2 pl-6 "><ModalRegistro arrProm={arrProm} setArregloProm={setArregloProm} promDir={promDir} promGPS={promGPS} setPromDir={setPromDir} setDatos={setDatos} datos={datos}  setEsperando={setEsperando}/></span>):(" ") 
    const cardDireccion =<div className="tile is-parent">
                            <article className="tile is-child ">      
                            <div className="content">
                                <p className="columns"><span className="column box" >{promDir }</span>{ registro} </p>
                            </div>
                            </article>
                        </div>
    const direccion = promDir? (<div className="is-flex-direction-row pb-2 ">
                                    <span className=" columns" >
                                        <span className="px-2 pb-1 has-text-weight-bold mi-contenedor has-text-left" id="micardDireccion">
                                            {promDir} 
                                        </span>
                                        {registro}
                                    </span>
                                </div>):(null)
    const mapa =<MapaConEntrada poligonos={poligonos} setPromDir={setPromDir} promGPS={promGPS} setPromGPS={setPromGPS}  esperando={esperando}/>
    return (
        <div className="pl-2">
            <div className="pl-3 pb-3">
            {direccion}
            </div>
            <div className="card content">
            {mapa}  
            </div>
            
    
        </div>
    );
}
 
export default MapaPanelMap;