import React,{useState,useEffect} from 'react';
import TablaPromovidos from './RPTablaPromovidos'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
import {Facebook} from "react-content-loader"

const RPLlenaPromovidos = ({promid,nombrePromot}) => {

    const [datos,setDatos] = useState("");
    let history = useHistory();
    const [error,setError] = useState("");
    const [vacio,setVacio] = useState(true);    
    let idusu =localStorage.getItem('UsuNum') ;
    let mensaje="";

    useEffect(()=>{ 
        
        const conectarJSon = async () => {
            
            //if( valorRuta > 0 && idruta !== valorRuta ){                
                //let auxU=`http://`+process.env.REACT_APP_LINK +`/reporte-seccion-promotor-detalle`; 
                let auxU=process.env.REACT_APP_LINK +`/reporte-seccion-promotor-detalle`;                  
                let token =localStorage.getItem('token20') ;  
                let seccion =localStorage.getItem('SeccNum') ;                 
                let dataInfo = qs.stringify({
                    'usuario': idusu,
                    'seccion':seccion,    
                    'promotor':promid,
                    'idDispositivo': 'csdq21weddewde' 
                    });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo
                };
                let aux ={};
                await axios(config)
                .then(function (response) {
                    aux=  response.data;
                   // const auxdat =  await aux; 
                //   console.log("dentro de la tercer api datos: "+idusu+" ,seccion: "+seccion+" , promotor: "+promid);
                    if (aux.promotores) {
                        
                        //console.log(`dentro secciones API: ${JSON.stringify(aux.secciones)}`) 
                        setDatos(aux.promotores);
                    }
                                
                    //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
                })
                .catch(function (error) {
                    //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`) 
                    console.log("mando error");
                    console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                    if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();
                        //window.location.replace('');
                        history.push("/");                
                        window.location.reload(true);
                        //console.log("seguir imprimiendo");                
                    }
                    if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                    {
                        console.log('sin datos' );
                        setVacio(false)  
                    }
                });
                  
                //console.log(auxdat.promotores);   
                
            //}
        };
       
                conectarJSon();
        
},[promid]);
    //const cargando = vacio ? (<div> <Cargando /> <Facebook/></div>):(<h1 className="title is-l has-text-centered">Ruta sin datos</h1>);
    //const auxmensaje = error ? (<h1 className="title is-l has-text-centered">Falta seleccionar {error}</h1>) : (cargando);
    const auxmensaje=<div> <Cargando /> <Facebook/></div>;
    const content = datos ? (<div ><TablaPromovidos valores= {datos} nombrePromot={nombrePromot}/>    </div> ) : (auxmensaje);

    return (
        <div className= "cabeza-promotor">
             {content}
        </div>
    
      );
}
export default RPLlenaPromovidos;
