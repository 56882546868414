import React,{useEffect} from 'react';
import Tablas from './TablasCoordi';
import { useHistory } from "react-router-dom";
const CoordinadorDetalle = () => {
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
    let history = useHistory();
    useEffect(()=>{ 
        
        if (nivelUsuario==='3'||nivelUsuario==='5'||nivelUsuario===''||nivelUsuario===' '||nivelUsuario===null) 
        {                  
            localStorage.clear();            
            history.push("/");                
            window.location.reload(true);      
        }        
    
    },[]);
    return (
                     
            <div /* className="contentTabGraf" */>
                <Tablas/>
            </div>                
        
         );
}
 
export default CoordinadorDetalle;