import React,{useState} from 'react';
//import ModalP from "../Promos/PromoLlamadaModal";
//import ModalDatGen from './BusqDatosGeneralModal';
//import ModalPrograma from './BusqProgramasModal';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
const BusqTablaPromovidos = ({valores,promotor,infoPromot,setActualizar,actualizar}) => {
    //let datos=valores;    
    let datos=valores
    let auxArreglo=[]//["#","INE","Promovido","Dirección","Nacimiento"]
    const [tabla,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [mensaje,setMensaje]=useState("");
    const [mensaje2,setMensaje2]=useState("");
    const [arreExcel,setArreExcel]=useState(["#","INE","Promovido","Dirección","Nacimiento"])
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Apoyos de Promovidos de "+infoPromot.promoPromotorNom +".xlsx")
    };
    const llamdaModal=()=>{
        setMensaje("is-active")
    }
    const llamdaModal2=()=>{
        setMensaje2("is-active")
    }
    function filtro(){        
        let auxCadena=nombre.trim();  
        auxArreglo=[]                
        let info=datos.filter((dat)=>dat.promoNombre.search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info);
    }
    function obtenerDireccion(promovido)
    {   let direccion="";
        direccion= promovido.promCalle ? (promovido.promCalle) : (direccion);
        direccion= promovido.promNoExt ? (direccion+" "+promovido.promNoExt) : (direccion);
        direccion= promovido.promNoInt ? (direccion+" "+promovido.promNoInt) : (direccion);
        direccion= promovido.promColonia ? (direccion+", "+promovido.promColonia) : (direccion);
        return direccion;
    }
    function TablaPromo(datos) {
        let numRenglon=0;
        let tot= Object.keys(datos).length;
        let auxi2=["#","Teléfono","Nombre","Sección","RG","RC","Banda","Lona","Redes","Brigadas"];
        auxArreglo.push(auxi2);
       // console.log(tot);
        if(tot===0){
            let auxi3=["","Sin","Promovidos"]
            auxArreglo.push(auxi3);            
        }   
        return(
            <div id="tamTablaPromovidosApoyo">
                <ReactTooltip place="left" type="success" effect="solid" />
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>
                        <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>#</div></th>    
                            <th style={{padding: 0.2 +'rem'}}>Telefono</th>                                                      
                            <th style={{padding: 0.2 +'rem'}} className=" has-text-centered">Nombre</th> 
                            <th style={{padding: 0.2 +'rem'}}>Sección</th>  
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>RG</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>RC</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>Barda</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>Lona</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>Redes</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>Brigadas</div></th>                                
                        </tr>
                        <tr>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{tot}</div></th>    
                            <th style={{padding: 0.2 +'rem'}}></th>                                                      
                            <th style={{padding: 0.2 +'rem'}} className=" has-text-centered"></th> 
                            <th style={{padding: 0.2 +'rem'}}></th>  
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{promotor.promoOpcion1}</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{promotor.promoOpcion2}</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{promotor.promoOpcion3}</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{promotor.promoOpcion4}</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{promotor.promoOpcion5}</div></th>
                            <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}>{promotor.promoOpcion6}</div></th>                             
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {datos.map((promo,index) => {
                            let auxNombre="";
                            //auxNombre = promotor.promovPaterno+" "+promotor.promovMaterno+" "+promotor.promovNombre ;
                            auxNombre = promo.promoNombre;
                            let auxRG=  promo.promoOpcion1 ===1 ? ( "Si"):( "No") ; 
                            let auxRC=  promo.promoOpcion2 ===1 ? ( "Si"):( "No") ; 
                            let auxBarda=  promo.promoOpcion3 ===1 ? ( "Si"):( "No") ; 
                            let auxLona=  promo.promoOpcion4 ===1 ? ( "Si"):( "No") ; 
                            let auxRedes=  promo.promoOpcion5 ===1 ? ( "Si"):( "No") ; 
                            let auxBrigadas=  promo.promoOpcion6 ===1 ? ( "Si"):( "No") ; 
                            let auxi=[index+1,promo.promoTel,auxNombre,promo.promoSeccionId,auxRG,auxRC,auxBarda,auxLona,auxRedes,auxBrigadas]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla ===index? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(index,promo) }  >
                                
                                <th style={{padding: 0.2 +'rem'}}><div className= "has-text-centered" style={{width: 60+'px'}}><span className="alinear is-size-7">{numRenglon}</span></div></th>                                                                                       
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promoTel}  </span></th>                               
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>  
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promoSeccionId}</span></th>  
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>                                    
                                <div style={{paddingTop: 0.2+'rem'}}></div>     
                                   <img src={1 === promo.promoOpcion1 ? (tabla ===index ? "ic_palomaBlanco.svg": "ic_paloma.svg") : (tabla ===index ?"ic_circuloBlanco.svg": "ic_circulo.svg")}   alt="" border="0" height="20" width="20"/> 
                                </th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}> 
                                <div style={{paddingTop: 0.2+'rem'}}></div>                                        
                                   <img src={1 === promo.promoOpcion2 ? (tabla ===index ? "ic_palomaBlanco.svg": "ic_paloma.svg") : (tabla ===index ?"ic_circuloBlanco.svg": "ic_circulo.svg") }     alt="" border="0" height="20" width="20"/> 
                                </th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>                                    
                                <div style={{paddingTop: 0.2+'rem'}}></div>     
                                   <img src={1 === promo.promoOpcion3 ? (tabla ===index ? "ic_palomaBlanco.svg": "ic_paloma.svg") : (tabla ===index ?"ic_circuloBlanco.svg": "ic_circulo.svg")}   alt="" border="0" height="20" width="20"/> 
                                </th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>                                    
                                <div style={{paddingTop: 0.2+'rem'}}></div>     
                                   <img src={1 === promo.promoOpcion4 ? (tabla ===index ? "ic_palomaBlanco.svg": "ic_paloma.svg") : (tabla ===index ?"ic_circuloBlanco.svg": "ic_circulo.svg")}   alt="" border="0" height="20" width="20"/> 
                                </th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>    
                                <div style={{paddingTop: 0.2+'rem'}}></div>                                     
                                   <img src={1 === promo.promoOpcion5 ? (tabla ===index ? "ic_palomaBlanco.svg": "ic_paloma.svg") : (tabla ===index ?"ic_circuloBlanco.svg": "ic_circulo.svg")}   alt="" border="0" height="20" width="20"/> 
                                </th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>      
                                <div style={{paddingTop: 0.2+'rem'}}></div>                                   
                                   <img src={1 === promo.promoOpcion6 ? (tabla ===index ? "ic_palomaBlanco.svg": "ic_paloma.svg") : (tabla ===index ?"ic_circuloBlanco.svg": "ic_circulo.svg")}   alt="" border="0" height="20" width="20"/> 
                                </th>  
                                                                          
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }

    
	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[0-9._:$!%-({})/*?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
        
    };
    //
    const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    /* const btnRecargar = <span data-tip="Recuperar Promovidos"><a className="pt-1 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/>  </a></span>
     */                   
    //const btnNuevoRegistro=<div className="pl-5"><ModalP valProm={infoPromot} seccion={seccion}/></div>         
   //const modal2= mensaje2? (<ModalDatGen datGen={registro} mostrar={mensaje2} setMensaje={setMensaje2}/>):(null);      
   //const modal1= mensaje? (<ModalPrograma idValor={tabla} mostrar={mensaje} setMensaje={setMensaje}/>):(null);        
   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= valores?(contenido):(<div><h2>No contiene datos</h2></div>)   
    const btnExportar = <span data-tip="Exportar Promovidos"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>     
    return (       
        <div className="contenido-tbPromov pl-2 pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent"  style={{padding: 0 +'rem'}}>
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal ">            
                                <div className=" field-body  ">
                                    <input
                                        className="input " 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar promovido"
                                        value={nombre}
                                        onChange={onChange}/>
                                      
                                
                                </div>
                                {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>    
           
        </div>
           
     
    );
}
 
export default BusqTablaPromovidos;