import React,{useState,useEffect} from 'react';
import Modal from '../Promos/PromoModal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import Select, { components } from 'react-select';
const IncModalDatGen = ({datGen,mostrar,setMensaje}) => {
         
    const [classNameModal,setclassNameModal]=  useState(mostrar);            
    const [tabla,setTabla]=  useState("");    
    const [datos,setDatos]=  useState("");         
    const[espera,setEspera]=useState(true);
    const [registro,setRegistro]=useState("")
    useEffect(()=>{         
        const source = axios.CancelToken.source();
          async function conectarJSon()  {           
              setEspera(false);
              //let auxU=`http://`+process.env.REACT_APP_LINK +`/incidencia-evidencia-list`;
              let auxU=process.env.REACT_APP_LINK +`/incidencia-evidencia-list`;               
              let token =localStorage.getItem('token20') ;                
              let dataInfo = qs.stringify({
                  'incidencia': datGen.Id,                                 
                  'idDispositivo': 'csdq21weddewde' 
                  });
              let config = {
                  url:auxU ,
                  method: 'POST',
                  headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                  data: dataInfo,
                  cancelToken: source.token
              };
              let aux ={};
             console.log("entrando api");
              await axios(config)
              .then(function (response) {
                  aux=  response.data;                           
                  if (aux)
                  {  
                      setDatos(aux);                                                              
                  }
                              
                  //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
              })
              .catch(function (error) {
                //  console.log("se tuvo un error");
                  //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
  //                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                  /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                  {                  
                  }
                  if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                  {
                      console.log('sin datos' );
                   
                  }*/
              });
              
               
              setEspera(true);    
          };
          if (datGen.TotalFoto>0) {
            conectarJSon();    
          }
          
          return ()=> {
            source.cancel();
        }  
        

       
    },[datGen]);
    
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
    }        

    let auxDescripcion=datGen.Descr;
    let auxNota=datGen.Nota;
    function auxFoto(auxfoto){
        return (        
        
        <div id="card-img-der">                                
        <article className="tile is-child ">                    
            <figure className="image " style={{maxWidth: 100+'%'},{height: 'auto'}}>
                <img src={auxfoto.Link}/>                        
            </figure>
        </article>               
                                                                                                                                                                  
    </div>)
    }

    function auxFoto2(auxfoto){
        return (
            <div id="card-img-izq ">        
                <article className="tile is-child ">                    
                    <figure className="image  is-128x128">
                        <img src={auxfoto}/>                        
                    </figure>
                </article>                
            </div>
        )
    }
    function TablaFotos(auxdatos) {
        let numRenglon=0;
        let tot= Object.keys(auxdatos).length;
       
        return(
            <div id="TablaFotos">                
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>                                                        
                            <th style={{padding: 0.2 +'rem'}}>{tot} Fotos</th>                                           
                        </tr>                                                
                    </thead>  
                    <tbody>          
                        {auxdatos.map((auxFotos,index) => {                                                                                                                
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === auxFotos.Id ? 'is' : '' }  key={numRenglon} onClick={ () => RenglonNum(auxFotos.Id,auxFotos) }  >                                                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxFoto2(auxFotos.Link)}  </span></th>
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    {         
        setMensaje("");
        setclassNameModal("");
    }


    
    
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
       
    }
    const auxFotoSeleccionada = registro ? ( auxFoto(registro) ):( null )
    const auxTamanio=datGen.TotalFoto>0?(750):(400)
    const auxFotografia=datGen.TotalFoto>0?(<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>):(<div className="box is-1"><h1 className="has-text-centered">Sin fotografia</h1></div>)
    const fotografia = espera &&datos? (TablaFotos(datos)): (auxFotografia);    
    
    const botones = espera ? (<div className="pt-2 pb-0"> <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (null);
    const titulo ="Sección "+datGen.SeccionNom+" "+datGen.CasillaNom
    return (
        <div>
      
        <Modal 
            id={"ModalDatosGenerales"}
            onClose={()=>esconderModal()}
            className={mostrar}
            title={titulo} 
            ancho={auxTamanio}
            >
            
            
            <div className="tile pt-2 is-ancestor">
                <div className="tile is-vertical">
              
                    <div className=" tile is-parent">  
                        <div className=" column">
                            {fotografia}
                        </div>                                  
                        <div className=" column">
                            {auxFotoSeleccionada}
                        </div>
                        
                    </div>
                    
                    <article className="tile is-child box  ">                            
                            {(auxDescripcion !==''&&auxDescripcion!==null&&auxDescripcion!==" ") ? (<p className="tamLetra2">Descripcion<p className="tamLetra2">{auxDescripcion} </p> </p>):(null)}
                            
                    </article>
                    <article className="tile is-child box  ">                            
                            
                            {(auxNota !==''&&auxNota!==null&&auxNota!==" ") ? (<p className="tamLetra2">Nota<p className="tamLetra2">{auxNota} </p> </p>):(<p className="tamLetra2">Nota: Sin notas. </p>)}                                                                       
                    </article>
                </div>      
            </div>    
            <div className="pt-3 is-flex is-justify-content-center">
            {botones}
            </div>
                        
            
    </Modal>
    </div>
);
}
 
export default IncModalDatGen;