import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Autocomplete,Polyline,Polygon, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "600px",
  width: "1000px"
}

const center = {
  lat: 19.5953512,  lng: -98.9210809

}
const MapaConsultaMarcador = ({poligonos,listaPromov,setPromDir,setPromGPS,centro,cambiaMarcador,setCambiaMarcador,promoSeleccionado}) => {
  const [gps, setGps] = useState([])
  const [rutaPol, setRutaPol] = useState([]);
  const [autocomplete, setAutocomplete] = useState([]);
  let arreColores= ["#F692158C","#F615159E","#5515F670","#9FC29FE3","#5515F670","lightblue","#F615159E","lightred","#9FC29FE3","lightyellow"];
  //let autocomplete = [];
  let arreRutas=[];   
  let arreRutaSeccion=[];   
  let arreMarcador=[];   
  let listaMarcadores=[];
  useEffect(()=>{ 
             //console.log("entrando al mapa marcador");
             //console.log(poligonos);
      
        if (poligonos)
        {
          let contador=0;
          let variable=0;
          poligonos.map((poligono) => {
            if (variable!==poligono.seccionId) {
              variable=poligono.seccionId;
              arreRutas=[]
              arreRutaSeccion.push(arreRutas);                        
            }
            if(poligono.seccionId===variable)
            { 
              let aux1=poligono.seccionLat;            
              aux1=parseFloat(aux1)
              let aux2=poligono.seccionLon;            
              aux2=parseFloat(aux2)
            // console.log(aux1,aux2 );
              arreRutas.push({ lat : aux1, lng :aux2 })
              
            }
              
          })
         // console.log(arreRutaSeccion);
          setRutaPol(arreRutaSeccion);
        
      }

      /*listaPromov.map((marcador,index) => 
        {//console.log(parseFloat(marcador.promLat),parseFloat(marcador.promLon));
            let aux1=marcador.promLat;            
            aux1=parseFloat(aux1)
            let aux2=marcador.promLon;            
            aux2=parseFloat(aux2)            
            let arreMarcador=[];   
            arreMarcador= { lat : aux1, lng :aux2 }
            if (promoSeleccionado===marcador.promId) {
            
                listaMarcadores.push(          <Marker
                        onLoad={onLoad}
                        title={marcador.promNombre}
                        position={ arreMarcador}
                        icon= {image2}    
                        
                    />)
                   
            }else{
            
                listaMarcadores.push(<Marker
                    onLoad={onLoad}
                    title={marcador.promNombre}
                    position={ arreMarcador}
                   
                    icon= {image}
                    options={ opcionRuta}
                    
                />)
            }
    })*/
    setCambiaMarcador(true)      
},[centro]);

  const onLoad =(respuesta)=> {
   // console.log('autocomplete: ', respuesta)

    //autocomplete = respuesta
    setAutocomplete(respuesta);
    //this.gps = autocomplete.getPlace().geometry.location
  }

  const onPlaceChanged =() =>{
    if ( autocomplete !== null||autocomplete.length>0  ) {

      console.log(autocomplete.getPlace())
      console.log(`location[${JSON.stringify(autocomplete.getPlace().geometry.location)}]`);
      //console.log(`location[${JSON.stringify(autocomplete.getPlace().formatted_address)}]`)
        // this.gps=this.autocomplete.getPlace().geometry.location
      //console.log(`lat[${this.autocomplete.getPlace().location.lat}] lon[${this.autocomplete.getPlace().location.lng}]`)
      setPromDir(autocomplete.getPlace().formatted_address)
      setGps(autocomplete.getPlace().geometry.location);        
      setPromGPS(autocomplete.getPlace().geometry.location);
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }
  
  
  const opcionRuta = {
    strokeColor: '#3273DC',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#3273DC',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
  };
  const onLoadPoliLinea = polyline => {
    //console.log('polyline: ', polyline)
  };
  
  const options =(numero)=> {
    

    const auxiliar={  
    fillColor: arreColores[numero],
    fillOpacity: 0.5,
    strokeColor: "orange",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }
  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }
  const image = {
    url:
      //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      "marcador.svg"
    // This marker is 20 pixels wide by 32 pixels high.
   
  };
  const image2 = {
    url:
      //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      "marcador_seleccionado.svg"
    // This marker is 20 pixels wide by 32 pixels high.
   
  };
  return (    
    <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" libraries={["places"]}> 
      <GoogleMap
        id="searchbox-example"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={centro}
      >         
    {    cambiaMarcador&&listaPromov?
        listaPromov.map((marcador,index) => 
        {//console.log(parseFloat(marcador.promLat),parseFloat(marcador.promLon));
            let aux1=marcador.promLat;            
            aux1=parseFloat(aux1)
            let aux2=marcador.promLon;            
            aux2=parseFloat(aux2)            
            let arreMarcador=[];   
            arreMarcador= { lat : aux1, lng :aux2 }
            if (promoSeleccionado===marcador.promId){
                return(
                    <Marker
                        onLoad={onLoad}
                        title={marcador.promNombre}
                        position={ arreMarcador}
                        icon= {image2}                            
                    />
                )    
            }else{
                //console.log(centro);
                //console.log(arreMarcador);
            return(
                <Marker
                    onLoad={onLoad}
                    title={marcador.promNombre}
                    position={ arreMarcador}
                   
                    icon= {image}
                   
                />
            )}
        })      
      :null 
    }
    {   /* listaMarcadores?
        listaMarcadores.map((marcador,index) => 
        {//console.log(parseFloat(marcador.promLat),parseFloat(marcador.promLon));
           
            return marcador
                    
        }
        )      
      :null */
    }
    

    
    
    { rutaPol!==null?
      rutaPol.map((poligono,index) => 
      {//console.log(poligono);
        return(
      <Polygon
          onLoad={onLoadPolygono}
        paths={poligono}
        options={options(index)}
      />)
    })
      
      :null
    }
       
  </GoogleMap>
</LoadScript>  );
}
 
export default MapaConsultaMarcador;
