import React, { useEffect } from 'react';
import sistemaHooks from '../hooks/sistema_hook';
import TabPage from './TabPage';
import { useHistory } from "react-router-dom";
const { forwardRef, useImperativeHandle } = React;

const Barra = forwardRef((props, ref) => {
    const [usuarioLoc, setUsuario] = sistemaHooks("UsuarioNom");
    const [currentTab,setTab] = sistemaHooks("TabSelected");
    const [currentMenu,setMenu] = sistemaHooks("MenuSelected");
    const { useRef } = React;
    const childRef = useRef();
    let history = useHistory();
    useEffect(()=>{ 
		const verificaNombre = async () => {
			//console.log("dentro BARRA de la funcion ="+usuarioLoc);
	    }
	    verificaNombre();
	    console.log('dentro2 del effect');
	},[usuarioLoc]);

    useImperativeHandle(ref, () => ({
        setAppBarra(tabSelect,menuSelect) {
           // console.log(`Barra Dentro setAppBarra TAB[${tabSelect}] MENU[${menuSelect}] `);
            setMenu(menuSelect);
            setTab(tabSelect);
            childRef.current.setAppTab(tabSelect,menuSelect)
    }}));
    function cerrarSesion() {
        localStorage.clear();
                //window.location.replace('');
        history.push("/");                
        window.location.reload(true);
    }
    return (<div>
        <section className="hero is-link  is-mas-small" >
            <div  className="hero-body" >
                <div className="container" id="cabecera" >
                    <div id="cabecera-cont">
                    <div  >
                        <div className="card-image" >
                            <figure id="imgLogo" style={{backgroundColor:'white',borderRadius:'2px',width:'8rem'}}>
                            <img src="rectangular.svg" alt="Daduva imagen"/>
                            </figure>
                        </div>
                    </div >
                    </div> 
                    <div id="cabecera-cont2">
                    
                    <h1 className="title" >
                        SPE Tezoyuca
                    </h1>
                    </div> 
                    <div id="cabecera-cont3">
                        <h6 className="title UserPrincipal" >
                            <span id="cabecera_cont_usuario">{usuarioLoc ? usuarioLoc : "" }</span>                            
                            <div className="has-text-right"><a id="cabecera_btn_cerrar2" onClick={()=>cerrarSesion()} style={{color:'white'}}>{usuarioLoc ? "Salir" : "" }</a></div>
                        </h6>                        
                        {/* <a className={usuarioLoc ? "delete" : "" } id="cabecera_btn_cerrar" onClick={()=>cerrarSesion()}>Salir</a> */}                       
                        {/*<a href="/" className="fuenteBar"></a>*/}
                    </div> 
                    
                </div>
            </div>
            <TabPage onValueTabChanged={props.onValueTabChanged} ref={childRef}/>
        </section>
    </div>);
})

export default Barra;  
