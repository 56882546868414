import React,{useState} from 'react';
//import LlenaPromovidos from './RPLlenaPromovidos';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import qs from 'qs';
const IncTablaTipo = ({datosJS,setListaXTipo,setActualizar,actualizar}) => {
    let datos=datosJS;    
    const [tabla2,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [nombrePromot,setNombrePromot]=useState("")
    let idusu =localStorage.getItem('UsuNum') ;
    const llamarApi=(numTipo)=>{
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {                                             
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/incidencia-web-list-tipo`;    
            let auxU=process.env.REACT_APP_LINK +`/incidencia-web-list-tipo`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                'usuario':idusu,                      
                'tipo':numTipo,
                'idDispositivo':'89wuuidsddsfe'      
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux) 
                   {//console.log(aux);                       
                    setListaXTipo(aux);
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });                                  
        };
        conectarJSon();
    }
    let auxArreglo=[];    
    const RenglonNum =(valorId,regis,PromNomb)=>{
        //console.log(regis);
        setTabla(valorId);
        //setNombrePromot(PromNomb);
        llamarApi(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Totales de incidencias " +".xlsx")
    };
    function filtro(){        
        let auxCadena=nombre.trim();   
        auxArreglo=[]                  
        let info=datos.filter((dat)=>dat.TipoNom.toUpperCase().search(auxCadena.toUpperCase() ) != -1)         
        return TablaPromo(info);
    }

    function TablaPromo(auxdatos) {
        let numRenglon=0;
        let auxi2=["#","Total","Tipo"];
        auxArreglo.push(auxi2);
        let tot= Object.keys(auxdatos).length;
        if(tot===0){
            let auxi3=["","Sin","Tipos"]
            auxArreglo.push(auxi3);            
        }           
        return(
            <div id="TablaIncidencias">
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>            
                            <th style={{padding: 0.2 +'rem'}}>Total</th>  
                            <th style={{padding: 0.2 +'rem'}}>Tipo</th>            
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {auxdatos.map((incidencia,index) => {
                            let auxNombre="";
                            auxNombre= incidencia.TipoNom;    
                            //console.log(auxNombre);
                            let auxi=[index+1,incidencia.Total,auxNombre]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla2 === incidencia.TipoId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(incidencia.TipoId,incidencia,auxNombre) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{incidencia.Total}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear6 is-size-7">{auxNombre}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
    

	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[0-9._:$!%-({})?/*|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
        guardarPromovido({
            ...promovido, //copiamos el nombre
            [e.target.name] : e.target.value  
        })
    }        
    };
    const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Tipos"><a className="pt-2 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  className="pt-2 " alt="" border="0" height="30" width="30"/> 
                        </a></span>
    //const datGeneral =registro? ( <LlenaPromovidos promid={tabla2} nombrePromot={nombrePromot}/> ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    const btnExportar = <span data-tip="Exportar Incidencias"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>
    return (

        <div className=" pr-2">
            <div className="tile is-ancestor ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className="tile is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal">            
                                <div className="field-body">
                                    <input
                                        className="input" 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar Tipo"
                                        value={nombre}
                                        onChange={onChange}/>
                                </div>
                                {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                {btnRecargar}
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        
      );
}
  
export default IncTablaTipo;