import React from 'react';
import NumberFormat from 'react-number-format';
const PCardTotal = ({valores}) => {
console.log(valores);
function Color(elcolor,auxvotos)
{let colorEfecti= "etiqueta";      
 if(elcolor<30){
     colorEfecti= "etiqRojaTab";
 }
 else{
     if(elcolor>=30 && elcolor<60)
     {
         colorEfecti= "etiqNaraTab";               
     }
     else{
         if(elcolor>=60 )
         {
             colorEfecti= "etiqVerdTab";
         }
     }
 }
 elcolor=Number(elcolor).toFixed(2);
 return (               
       <span id={colorEfecti} className="is-size-3 has-text-weight-bold has-text-centered" >
            
            <NumberFormat value= {auxvotos}displayType={'text'}  thousandSeparator={true}/>
       </span>         
     )      
};
//const datos=     
    return (<div className=" micard2 is-flex is-justify-content-center" >
    <article className="pt-5 ">                
        
        <p className="is-size-3 has-text-weight-bold has-text-centered"  >{}
        <NumberFormat value= {valores[0].lNominal}displayType={'text'}  thousandSeparator={true}/></p>
        <p className="is-size-6 pb-4   has-text-weight-bold has-text-centered"  >L Nominal </p>
        <p className=" has-text-centered">{Color((valores[0].votacion/valores[0].lNominal)*100 , valores[0].votacion)} </p>
        
        <p className="is-size-6 pb-4 has-text-weight-bold has-text-centered"  >Votación </p>
        {/* <span className="is-size-4 has-text-weight-bold"  >{valores[0].votacion}</span> */}
        
        
        
              
        <table >
            <tbody >
                
        <tr   >                                
            <th style={{paddingLeft: 0.2 +'rem'}} className="tablaTR " >
            <img  src= {valores[0].partImgWeb}  alt="" border="0" height="40" width="40"/>    
            </th>                                
            <th className="tablaTR" >
            <span className="is-size-3 has-text-weight-bold has-text-centered"  >
            <NumberFormat value= {valores[0].partVoto}displayType={'text'}  thousandSeparator={true}/>
                </span>       
            
            </th>
            
        </tr>   
            </tbody>
        </table>

           
                            

        <p className="is-size-6 has-text-weight-bold has-text-centered"  >Mayoria</p>  
        
    </article>
</div>  );
}
 
export default PCardTotal;

