import React from 'react';
import PromoTabla from './PromovidoTabla2';

const PromovidoLista2 = ({Promotor}) => {

function llenado(){
    return(
        <div >
             <PromoTabla datosJS={Promotor} />                                         
        </div>
    );
}
const cargando=<div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div> ;
//const content = null ===Promotor ? (llenado()  ) : (<div className="Container box"><h2>Sin promovidos</h2></div>)
const content = Promotor ? (llenado()  ) : (<div className="Container box"><h2>Sin promovidos</h2></div>)
return (
    <div>
        <div>  
        {
            content
        } 
        
        </div>        
    </div>          
    );
}
 
export default PromovidoLista2;