import React,{useEffect,useState} from 'react';
import NumberFormat from 'react-number-format';
//import Coordinador from './coordinador';
import GraficaCoord  from '../Graficas/GraficaCoord';
import axios from 'axios';
import qs from 'qs';
import TabRuta  from './TablasRutas';
import sistemaHooks from '../hooks/sistema_hook';
import { useHistory } from "react-router-dom";
const TablasCoordi = () => {
    const [datos,setDatos] = useState("");   
    const [tabla2,setTabla]=  useState("");   
    let history = useHistory();
    const [coordinador,setCoord] = sistemaHooks("CoordNum");
    //const []
    let auxTtRutas =0;
    let auxTMetFinal =0;
    let auxTPromov =0;
    let auxTAvaGen =0;
    let auxTMetActual =0;
    let auxTEfec =0;
    const [auxJSRuta,setInfo]=  useState("");   
    const [rutaNum,setNumRuta]=  useState("");


    let idruta =localStorage.getItem('RutaNum') ;
    let idcoord =localStorage.getItem('CoordNum') ;

    const RenglonNum =(valor,ruta)=>{
        //console.log(valor);
        setCoord(valor);
        setInfo(ruta);
       // console.log(auxJSRuta);
        setTabla(valor);
        let CoorNum=localStorage.getItem('CoordNum') ;        
        localStorage.setItem('RutaNum',"");
        //console.log("numero coord");
        //console.log(CoorNum);
        //setTabla(CoorNum);
    }

    useEffect(()=>{ 
        const conectarJSon = async () => {    
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/coordinador`;    
            let auxU=process.env.REACT_APP_LINK +`/coordinador`;    
            let token =localStorage.getItem('token20') ;
            let id =localStorage.getItem('UsuNum') ;
            //console.log('token es: '+token);
            let dataInfo = qs.stringify({
                'usuario': id,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo
            };

            let aux ={};
            await axios(config)
            .then(function (response) {
                //console.log('mostrando datos');  
                //console.log(JSON.stringify(response.data));     
                aux=  response.data;
                if(aux)
                {
                    setDatos(aux);    
                }                
                
            })
            .catch(function (error) {
                //console.log(`Error: ${error.message} Codigo: ${error.status}`);
                //console.log(`Error: ${JSON.stringify(error.response.data)} Codigo: ${error.status}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {   
                    console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    
                }
            });
            
        }
        conectarJSon();
       // console.log(idruta);
        
        /*if(idcoord !== null&&idcoord!=="") 
        {
            console.log("el idc"+idcoord);
            //RenglonNum(idcoord,coordi.rutas) 
        }*/
    },[]);

    function Color(elcolor)
     {let colorEfecti= "etiqueta";      
      if(elcolor<60){
          colorEfecti= "etiqRojaTab";
      }
      else{
          if(elcolor>=60 && elcolor<80)
          {
              colorEfecti= "etiqNaraTab";               
          }
          else{
              if(elcolor>=80 )
              {
                  colorEfecti= "etiqVerdTab";
              }
          }
      }
      elcolor=Number(elcolor).toFixed(2);
      return (               
            <span id={colorEfecti} >
                 {elcolor+"%"}
            </span>         
          )      
    };
    function Totales(valorTotal)
    {   auxTtRutas =0;
        auxTMetFinal =0;
        auxTPromov =0;
        auxTAvaGen =0;
        auxTMetActual =0;
        auxTEfec =0;
        valorTotal.map((coordi) => 
        { 
            auxTtRutas++;       
            auxTMetFinal=auxTMetFinal+coordi.meta_final;
            auxTPromov= auxTPromov+coordi.promovidos;        
            auxTMetActual=auxTMetActual+coordi.meta_actual;        
        })
        auxTAvaGen= ((auxTPromov/auxTMetFinal)*100)
        auxTAvaGen= auxTAvaGen ? (auxTAvaGen):(0);
        
        auxTEfec=(auxTPromov/auxTMetActual)*100;  
        auxTEfec= auxTEfec ? (auxTEfec):(0);            
    };
    
    function TablaCoord() {
        let numRenglon=0;
        return(
        <div id="mitabla2">
        <table className="table is-sobrepuesto" >
        <thead >    
          <tr>
            <th style={{padding: 0.1  +'rem'}}>Total </th>
            <th style={{padding: 0.1 +'rem'}}>Meta <br/>Final</th>
            <th style={{padding: 0.1 +'rem'}}>Promovidos</th>
            <th style={{padding: 0.1 +'rem'}}>Avance<br/> Global</th>
            <th style={{padding: 0.1 +'rem'}}>Meta <br/> Actual</th>
            <th style={{padding: 0.1 +'rem'}}>Efectividad</th>      
          </tr>
          {Totales(auxcoordina)}
          <tr >
            <th style={{padding: 0.2 +'rem'}}><span className="alinear" ><span id="etiqBlanca">{auxTtRutas}</span></span></th>
            <th style={{padding: 0.2 +'rem'}}><span id="etiqBlanca"><NumberFormat value={auxTMetFinal} displayType={'text'}  thousandSeparator={true}/> </span></th>
            <th style={{padding: 0.2 +'rem'}}><span id="etiqBlanca"><NumberFormat value={auxTPromov} displayType={'text'}  thousandSeparator={true}/></span></th>
            <th  style={{padding: 0.2 +'rem'}}><span id="etiqBlanca">{(auxTAvaGen.toFixed(2))}%</span></th>
            <th style={{padding: 0.2+'rem'}}><span id="etiqBlanca"><NumberFormat value={auxTMetActual} displayType={'text'}  thousandSeparator={true}/></span> </th>
            <th style={{padding: 0.2+'rem'}}><span >{Color(auxTEfec)}</span> </th>      
          </tr>
        </thead>  
        <tbody>          
          {auxcoordina.map((coordi) => {
              let auxNombre="";
              auxNombre=coordi.coodinadorNombre;    
              //console.log(auxNombre);
              numRenglon++;
              let CoorNum=localStorage.getItem('CoordNum') ;
             // const renglon =  ? (CoorNum) : (tabla2);
              /*if(coordinador === coordi.coodinadorId&&coordinador!=="") {
                  console.log(coordinador);
                RenglonNum(coordi.coodinadorId,coordi.rutas) ;
              }*/
            return (              
                
                <tr   className={ coordinador === coordi.coodinadorId ? 'is-seleccionado' : '' } key={numRenglon} onClick={ () => RenglonNum(coordi.coodinadorId,coordi.rutas) }>
                    <th className="tablaTR"  style={{padding: 0.0 +'rem'}}><span className="alinear2">{numRenglon+" "}{auxNombre}</span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear"><NumberFormat value={coordi.meta_final} displayType={'text'}  thousandSeparator={true}/> </span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear"><NumberFormat value={coordi.promovidos} displayType={'text'}  thousandSeparator={true}/> </span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear">{coordi.avance_global+"%"}</span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear"><NumberFormat value= {coordi.meta_actual}displayType={'text'}  thousandSeparator={true}/></span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}>                                         
                        {Color(coordi.efectividad)}                    
                    </th>            
                </tr>              
            );
              })
          }                                
        </tbody>
      </table>
      </div>);
      }
    let auxcoordina = datos.coordinadores;
   
    
    
    //setNumRuta(idruta);
    //const mostrarTab2=  coordinador ? (<div><TabRuta idVal={idruta} Jsval={auxJSRuta}/></div>  ) : ();
    const segundaTabla = tabla2 ? (<div><TabRuta idVal={tabla2} Jsval={auxJSRuta}/></div>  ) : (<span></span>);

   
    const content = datos ? (<div id="contentTabGraf"><div id="contentGrafica">{TablaCoord()} <GraficaCoord valor={datos.coordinadores} descripcion="Avance de Promovidos" Auxtam={1}/></div><div >{segundaTabla}</div> </div>  ) : (<div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div>)
    return (
       <div className="pt-2">{content}</div>
        );
}
 
export default TablasCoordi;