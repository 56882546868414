import React, { Component, Fragment } from 'react';
import Modal from './PromoModal';
import axios from 'axios';
import qs from 'qs';
import Select, { components } from 'react-select';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class PromoLlamadaModal extends Component{
    state = {
        classNameModal:'',
        title:'Registro',
        formData:{            
            clave:'',
            nombre:'',
            apPat:'',
            apMat:'',
            calle:'',
            noExt:'',
            noInt:'',
            cp:'',
            colonia:'',
            telefono:'',
            celular:'',
            correo:' ',
            preferencia:'PAN',
            participacion:"0",
        },
        isLoading: true,    
        secciones:this.props.seccion,        
        registros:[],
        contador:0,
        auxseccion:[],
        campoVacio:'',
        seccion:'',
        respuesAPIine:false,
        nacimiento:' ',
        genero:' ',
        promID:this.props.valProm,
        contador:0,
        desBtn:false,
    }
    showModal= () => {
        //toast.clearWaitingQueue({ containerId: "ModalPrograma" });
        this.setState({
            classNameModal: 'is-active',
            campoVacio:''
        })
    }
    hideModal= () => {
        //toast.dismiss();
        this.setState({
            classNameModal: ''
        })
    }
    handleChange = (e) => {
        let { formData } = this.state
        let { target } = e;
                
        let expPalabraspermitida = new RegExp('[0-9._:$!%-({})"+/*|]');
        let expCadenaGeneral = new RegExp('[_:$!%-({})+/*"|&]');        
        let expNumero = new RegExp('[\bA-Za-z.,_:$!%-({})/"*+|]');        
        
        let expmenos=   new RegExp('-');
        if(target.name === "telefono"||target.name === "celular"|| target.name === "cp")
        {
            if (!expNumero.test(target.value)&&!expmenos.test(target.value))
            {  formData[target.name] = target.value.trim();        
            }
        }else
        {  if(target.name === "nombre"||target.name === "apPat"|| target.name === "apMat")
            {
                if (!expPalabraspermitida.test(target.value)&&!expmenos.test(target.value))
                {   let auxCampo= target.value;
                    formData[target.name] = auxCampo.toUpperCase();       
                }
            }else
            {   if(target.name === "calle"||target.name === "noExt"|| target.name === "noInt"|| target.name === "colonia")
                {
                    if (!expCadenaGeneral.test(target.value)&&!expmenos.test(target.value))
                    {  let auxCampo= target.value;
                        formData[target.name] = auxCampo.toUpperCase();                        
                    }
                }else
                {  if( target.name === "clave"  ) 
                    {  if (!expCadenaGeneral.test(target.value)&&!expmenos.test(target.value))
                        {  let auxIne= target.value.trim();
                            formData[target.name] = auxIne.toUpperCase();
                        }                         
                    } 
                    else
                    {   if( target.name === "preferencia"  ) 
                        {  if (!expPalabraspermitida.test(target.value)&&!expmenos.test(target.value))
                            {  let auxCampo= target.value.trim();
                                formData[target.name] = auxCampo.toUpperCase();
                            }                         
                        } 
                        else
                        {  
                            formData[target.name] = target.value ; 
                        }                          
                    }
                }                
            }
        }
        
        this.setState({
            formData,campoVacio:'',
            errorForm:false
        })
    }
/*
    notify = () => toast.info("Registrando...", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
          */  
    llenaSelect()
    {   let { secciones} = this.state;                
       // console.log(`Cabecera Llena Select: ${JSON.stringify(secciones)}`) 
        let arreSecc=[];   
        secciones.map((seccion) => {
            arreSecc.push({ value : seccion.SeccionId, label :seccion.SeccionNombre })
        })
        //console.log(arreSecc);
        this.setState({
            auxseccion: arreSecc,
             isLoading: false
          });
    }

    llamarApi = async (auxExt,auxInt) => {        
        let{ formData,nacimiento,genero,promID } = this.state;
        let{ clave , nombre ,apPat, apMat,calle, cp, colonia, telefono, celular,correo,preferencia,participacion}=formData;
        let idusu =localStorage.getItem('UsuNum') ;
        let idSecc =localStorage.getItem('SeccNum') ;
        let auxLimpio=formData;
        let auxfech=clave.substring(6,12);
        let auxiF =auxfech.substring(0,2);
        let fecha='';
        let partici=Number(participacion);
        console.log("numero participacion: "+partici);
        if (auxiF==='03'||auxiF==='04') {
            fecha='20'+auxiF;
        }
        else
        {   fecha='19'+auxiF;
        }               
        let mesNac = auxfech.substring(2,4);
        let diaNac = auxfech.substring(4,6);
        fecha=fecha+'-'+mesNac+'-'+diaNac;
        let auxgen=clave.substring(14,15);              
        //this.setState({ nacimiento:fecha ,genero:auxgen });               
        //let IdProm =localStorage.getItem('IdProm') ;  
        let IdProm = promID.PromotId; 
        //console.log('con el idprom '+IdProm);            
        //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-nuevo`;
        let auxU=process.env.REACT_APP_LINK +`/promovido-nuevo`;
        //  console.log("el id: " +ID);
        let token =localStorage.getItem('token20') ;
        let auxTel1=telefono?(telefono.trim()):(' ');
        let auxTel2=celular?(celular.trim()):(' ');
       // console.log('LOS DATOS QUE MANDO:'+clave);        
        let dataInfo = qs.stringify({ 'usuario': idusu,'idDispositivo': 'uhjbmsi982wed' ,
        'SeccionId':idSecc,'PromotId':IdProm,'EstPromId':'1','INE':clave.toUpperCase(), 'Curp':clave.toUpperCase(), 'Nacimiento':fecha,
        'Nombre':nombre.toUpperCase(), 'Paterno':apPat.toUpperCase() , 'Materno':apMat.toUpperCase(), 'Calle':calle.toUpperCase() , 
        'Noext':''+auxExt.toUpperCase(), 'Noint':auxInt.toUpperCase(),  'Colonia':colonia.toUpperCase() , 
        'Cp':''+cp, 'Telefono':auxTel1, 'Celular':auxTel2,'Email':correo.toLowerCase() ,  'Facebook':correo.toLowerCase()  , 'Twitter':correo.toLowerCase(), 'Instagram':correo.toLowerCase(), 
        'Genero':auxgen.toUpperCase(), 'Latitud':'0.0' ,'Longitud':'0.0', 'GPS':' ','PartId':0 ,'Preferencia':preferencia.toUpperCase(),'ParticipaRGRC':partici,});
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo
        };
        
        await axios(config)
        .then(function (response) {
            // console.log('mostrando datos');  
            console.log(JSON.stringify(response.data));                
            let aux =  response.data;      
            if ( aux.respuesta ) 
            {   //console.log("dentro del if obtuvo respuesta");          
                if ( aux.respuesta === 1 && aux.mensaje ) 
                {   const mensaje = aux.mensaje;
                    //console.log(mensaje);                                    
                    toast.error(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000, containerId:'ModalPromovido'});
                    clave='';
                    const element = <p className="help is-danger is-size-6 has-text-justified" id="promo_modal_mensaje">{mensaje}</p>
                    ReactDOM.render(element, document.getElementById('promo_modal_mensaje'));    
                    console.log("Replicado:" +dataInfo);  
                }else
                {   if ( aux.respuesta === 1 && aux.tipo ) 
                    {   //console.log("Se registro correctamente");                   
                        toast.success("Se registro con éxito INE " +clave.toUpperCase(), {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000, containerId:'ModalPromovido'});                       
                        const element = <p className="help has-text-info is-size-5 has-text-justified" id="promo_modal_mensaje">{' '}</p>
                        ReactDOM.render(element, document.getElementById('promo_modal_mensaje'));                                       
                        auxLimpio={clave:'', nombre:'', apPat:'', apMat:'', calle:'', noExt:'', noInt:'', cp:'',  colonia:'', telefono:'', celular:'', correo:' ',preferencia:'PAN',participacion:'0'};                
                        //console.log("Insertado:" +dataInfo);  
                    }   
                }            
            }
        })
        .catch(function (error) {        
            //console.log(`Error: ${error.mensaje} `);        
            //console.log(`Error: ${error.response.data.mensaje} Codigo: ${error.response.data.error.name}`);       
        });        
        this.setState({ desBtn:false, formData:auxLimpio, campoVacio:'',nacimiento:'' ,genero:'' });
    }

    componentDidMount(){     
        toast.dismiss();   
        this.llenaSelect();
    }
    
    verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
        if(campo.trim() === '' || campo.trim() === null )
        {   respuesta=true;
            //console.log("entrando al if de nulos: "+descripcion);            
            this.setState({ campoVacio:'No se lleno el campo '+descripcion, });
        }
        return(respuesta);
    }
    verificaINE=(ine)=>
    {   let respuesta =false;
        let auxnom=ine.substring(0,6);
        let auxfech=ine.substring(6,12);
        let auxnumer=ine.substring(12,14);
        let auxgen=ine.substring(14,15);
        let auxnumer2=ine.substring(15,18);
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if ( ( expCadena.test(auxnom) && !expNumero.test(auxnom) ) && ( expNumero.test(auxfech) && !expCadena.test(auxfech) ) 
             &&( expNumero.test(auxnumer) && !expCadena.test(auxnumer)) && ( expCadena.test(auxgen)&& !expNumero.test(auxgen) )
             &&(expNumero.test(auxnumer2) && !expCadena.test(auxnumer2) ) ) 
        {
            if (auxgen==='H'||auxgen==='M') 
            {   
               respuesta =true ;                    
            }   
        }
        return(respuesta);
    }

    verificaNombre=(nombre,appat,apmat)=>
    {   
        let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');

        if( expCadena.test(nombre) && !expNumero.test(nombre) )
        {
            if(  expCadena.test(appat) && !expNumero.test(appat) )
            {
                if ( expCadena.test(apmat) && !expNumero.test(apmat)  ) 
                {  
                    respuesta=true;                     
                }else
                {   this.setState({ campoVacio: 'Debe escribir un Apellido Materno  valido ' })        
                }
            }
            else
            {   this.setState({ campoVacio: 'Debe escribir un Apellido Paterno  valido ' })    
            }
        }
        else
        {  this.setState({ campoVacio: 'Debe escribir un Nombre  valido ' })    
        }
        
        return(respuesta);
    }
    verificaPreferencia=(preferencia)=>
    {   
        let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');       
        if ( expCadena.test(preferencia) && !expNumero.test(preferencia)  ) 
        {  
            respuesta=true;                     
        }else
        {   this.setState({ campoVacio: 'Debe escribir una Preferencia  valida ' })        
        }            
        return(respuesta);
    }
    verificaDireccion=(calle,cp,colonia)=>
    {   
        let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        let expmenos=   new RegExp('-');
        if ( expCadena.test(calle)   ) 
        {
            if ( !expCadena.test(cp) && expNumero.test(cp) &&cp.length===5 && !expmenos.test(cp)) 
            {   if ( expCadena.test(colonia)   ) 
                {   respuesta=true;                     
                }else
                {
                    this.setState({ campoVacio: 'Debe escribir una colonia  valida' })            
                }    
            } else 
            {
                this.setState({ campoVacio: 'Debe escribir un C.P.  valido' })        
            }
        } else
        {
            this.setState({ campoVacio: 'Debe escribir una direccion  valida' })        
        }
        
        return(respuesta);
    }
    verificaCorreo=(correo)=>
    {   
        let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        //let expCadena = new RegExp('[/\S+@\S+\.\S+/]');                
        
        if( correo.trim() === '' || correo.trim() === null )
        {   respuesta=true;                        
        }
        else{
            if ( expCadena.test(correo)  ) 
            {            
                respuesta=true;
            }
            else
            {                
                this.setState({ campoVacio: 'Debe escribir un Correo valido ' } ) 
            }
            
        }
        return(respuesta);
    }
    verificaTelefonos=(telefono,tamano,descripcion)=>
    {   
        let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        let expmenos=   new RegExp('-');
        if(telefono.trim() === '' || telefono.trim() === null )
        {   respuesta =true;                 
        }
        else{
            if ( (telefono.trim().length === tamano || descripcion==='Telefono'&& telefono.trim().length ===7) && !expmenos.test(telefono)  && !expCadena.test(telefono) && expNumero.test(telefono)) 
            {   respuesta=true;                
            }
            else
            {    respuesta=false;
                 this.setState({ campoVacio:descripcion+' no valido' });
            }
        }
        return(respuesta);
    }
    ajustarTamanio=(numero)=>
    {        
        let auxiliar ='';                
        if( numero.trim() === '' || numero.trim() === null )
        {   auxiliar=' ';                        
        }
        else{
            if (numero.length<=10  ) 
            {            
                auxiliar=numero;
            }
            else
            {   if (numero.length>10)
                {
                    auxiliar = numero.substring(0,10);
                }
                else{             
                    auxiliar = numero.substring(0,numero.length);
                }
            }            
        }
        return(auxiliar);
    }
    
    restodelIF( nombre ,apPat, apMat,calle, noExt, noInt, cp, colonia, telefono, celular,correo){
        if ( !this.verificaNulos(nombre,'Nombre') && !this.verificaNulos(apPat,'Apellido Paterno') && !this.verificaNulos(apMat,'Apellido Materno') )
        {                       
            if( this.verificaNombre( nombre.trim().toUpperCase() , apPat.trim().toUpperCase() , apMat.trim().toUpperCase() ) )
            {
                if( !this.verificaNulos(calle,'Calle') && !this.verificaNulos(cp,'C.P.') && !this.verificaNulos(colonia,'Colonia') )
                {   

                    if (this.verificaDireccion( calle.trim().toUpperCase() , cp.trim().toUpperCase() , colonia.trim().toUpperCase() )) 
                    {  
                        if (this.verificaCorreo(correo) &&this.verificaTelefonos(telefono.toUpperCase(),10,'Telefono') && this.verificaTelefonos(celular.toUpperCase(),10,'Celular')) 
                        {
                            let auxEnt = this.ajustarTamanio(noExt);
                            let auxInt = this.ajustarTamanio(noInt);
                            this.setState({ desBtn:true});
                            this.llamarApi(auxEnt,auxInt);                           
                        }
                    }
                    else
                    {
                        // this.setState({ campoVacio: 'escribir una direccion  valida ' })        
                    }
                }
            }
            else
            {
                //  this.setState({ campoVacio: 'Debe escribir un nombre  valido ' })        
            }
                
        }
    }
    handleSubmit =(e) =>{
        e.preventDefault();
       // console.log("entrando al submit");
        let{ formData,campoVacio,seccion} = this.state;        
        let{ clave , nombre ,apPat, apMat,calle, noExt, noInt, cp, colonia, telefono, celular,correo,preferencia}=formData;                                                  
        const aux = seccion ? (''+seccion.SeccionId):('')
        const element = <p className="help is-danger is-size-5 has-text-justified" id="promo_modal_mensaje">{' '}</p>
         ReactDOM.render(element, document.getElementById('promo_modal_mensaje'));       
        if(!this.verificaNulos(aux,'Seccion'))
        {        
            if ( !this.verificaNulos(clave,'INE') && clave.trim().length===18 ){                                    
                if( this.verificaINE(clave) )
                {                
                   if (!this.verificaNulos(preferencia,'Preferencia')&&this.verificaPreferencia(preferencia)) 
                   {
                        this.restodelIF(nombre ,apPat, apMat,calle, noExt, noInt, cp, colonia, telefono, celular,correo);                                        
                   }
                    
                }else
                {    this.setState({ campoVacio: 'Debe escribir un INE valido' })                
                                       
                }                                                
            }
            else
            {   if(clave.length>0 )
                {
                    this.setState({ campoVacio: 'Debe escribir un INE valido' })
                }                        
            }
        }
        else{ this.setState({ campoVacio: 'Debe seleccionar una sección' })}        
    }
    
    limpiar=()=>
    {    let{ formData } = this.state;
            let auxLimpio=formData;
            auxLimpio={clave:'',
                        nombre:'',
                        apPat:'',
                        apMat:'',
                        calle:'',
                        noExt:'',
                        noInt:'',
                        cp:'',
                        colonia:'',
                        telefono:'',
                        celular:'',
                        correo:' ',preferencia:'PAN',participacion:'0'};
         this.setState({ formData:auxLimpio, campoVacio:'',nacimiento:'' ,genero:'' });
         const element = <p className="help is-danger is-size-5 has-text-justified" id="promo_modal_mensaje">{' '}</p>
         ReactDOM.render(element, document.getElementById('promo_modal_mensaje'));
    }
    guardaSeccion(seccion) {
        const { secciones } = this.state;             
        const dato= secciones.filter(  (registro) => registro.SeccionId === seccion )          
        localStorage.setItem('SeccNum',''+dato[0].SeccionId); 
        this.setState( { seccion:dato[0] } )               
    }
    btnCancelar(){
        this.limpiar();
        this.hideModal();
    }
    render(){
        const { isLoading,classNameModal, title,formData,auxseccion ,campoVacio,respuesAPIine,desBtn} = this.state
        let nomPromot =localStorage.getItem('NomProm') ;
        const theme = theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#FFD20487",
              primary: "#FFD204",                  
            }           
          });
        const selector =auxseccion ? (<div className="column  pr-1 "><Select placeholder="Seccion" 
                                            styles={
                                                {
                                                    control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600',height:'10px' ,minHeight:'30px',  }),
                                                    option: styles => ({ ...styles, fontSize: '10px'}),
                                                    menuList: styles => ({ ...styles,width:'100px' }),
                                                    container: styles => ({ ...styles,width:'100px' })
                                                }
                                            }
                                            options={auxseccion}
                                            onChange={opt =>  this.guardaSeccion(opt.value) }
                                            
                                            noOptionsMessage={ () =>  ('Sin Resultados') }/>
                                         </div>) :(null);
        const botones = !desBtn ? (<div ><span className="pr-4"><button className="button is-aceptar " onClick={this.showModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={()=>this.btnCancelar()} >Cancelar</a></div>  ) : (<div><progress className="progress is-aceptar is-sistema" max="100">15%</progress><input className="button is-sistema" value="Verificando..."/></div>)
        const val=formData.participacion==="0" ? (<input  onChange={this.handleChange}  type="radio" name="participacion" value="0" checked="checked" /> ): (<input onChange={this.handleChange}  type="radio" name="participacion" value="0" />);
        
        const leyenda =  campoVacio?(<p className="help is-danger is-size-5 has-text-justified" > {campoVacio}</p>):(null);                  
       return (
        <React.Fragment>
            { !isLoading ?(<div>
                <button 
                onClick={this.showModal}
                className="button is-sistema">
                    Nuevo Registro 
                </button>                
                <Modal 
                    id={"ModalPromovidoAlta"}
                    onClose={this.hideModal}
                    className={classNameModal}
                    title={nomPromot}
                    ancho={480}                   
                >
                {!respuesAPIine?(    <form   onSubmit={this.handleSubmit} > 
                        {leyenda}
                        {/* <p className=" help is-link "><span className="is-size-6 has-text-weight-medium">{nomPromot+' '}</span> <span className="has-text-dark"> Promotor</span> </p> */}
                        <p className="help is-danger is-size-5 has-text-justified" id="promo_modal_mensaje"> </p>   

                        
                        <div className="field  ">                            
                            <div >                           
                            <div className="columns	">
                                { selector }
                                <div className="column is-9 ">
                                    <span className=""><input className="input is-small" onChange={this.handleChange} type="text" value={formData.clave} placeholder="INE" name="clave" maxLength="18"/></span>                                 
                                </div>
                                
                            </div>                                                                                                                 
                            </div>
                        </div>
                        <div className="pb-2 ">
                                       
                        </div>
                        
                        <div className="field is-horizontal py-0 	mr-0 pr-0">                            
                            <div className="field-body 	mr-0">
                              
                            <div className="columns mr-0">
                                <div className="column is-three-quarters ">
                                <span className="" >  <input className="input is-small " onChange={this.handleChange} type="text" placeholder="Preferencia politica" value={formData.preferencia} name="preferencia" maxLength="48"/></span>
                                    
                                </div>
                                <div className="column   pr-0">
                                    <span ><p className="help " >Sugerencia</p></span> 
                                   
                                </div>
                                <div className="column  pr-0">
                                    <div className="select is-small">
                                        <select onChange={this.handleChange} name="preferencia" >
                                            <option value="PAN">PAN</option>
                                            <option value="PRD">PRD</option>
                                            <option value="PRI">PRI</option>                                            
                                            <option value="MORENA">MORENA</option>
                                            <option value="PT">PT</option>
                                            <option value="PVEM">PVEM</option>
                                            <option value="MC">MC</option>
                                            <option value="INDECISO">INDECISO</option>                                
                                        </select>
                                    </div>
                                </div>
                            </div>                                                                                                                 
                            </div>
                        </div>

                        <div className="field is-horizontal">                            
                            <div className="field-body">
                                <div className="field">
                                <p className="control is-expanded has-icons-left">
                                <span > <p className="help ">¿Desea participar como RG/RC?</p></span>                                                                               
                                    <span className="icon is-small is-left">
                                    <i className="fa fa-user"></i>
                                    </span>
                                </p>
                                </div>
                                <div className="field">
                                <p className="control is-expanded has-icons-left has-icons-right">
                                    <label className="radio pl-0 pr-4 is-size-6">
                                        <input onChange={this.handleChange} type="radio" name="participacion" value="1"/>
                                      <span className="is-size-7"> Si</span> 
                                    </label>
                                    <label className="radio pl-2 is-size-6">
                                        {val}
                                        <span className="is-size-7"> No</span> 
                                    </label>     
                                    <span className="icon is-small is-left">
                                    <i className="fa fa-envelope"></i>
                                    </span>
                                    <span className="icon is-small is-right">
                                    <i className="fa fa-check"></i>
                                    </span>
                                </p>
                                </div>
                            </div>
                        </div>  
                       
                        
                        <div className="pb-2 ">
                            <span className="" >  <input className="input is-small " onChange={this.handleChange} type="text" placeholder="Nombre" value={formData.nombre} name="nombre" maxLength="48"/></span>           
                        </div>
                                                                                                                  
                        <div className="field is-horizontal">                            
                            <div className="field-body">
                                <div className="field">
                                <p className="control is-expanded has-icons-left">
                                <span >      <input className="input is-small"  onChange={this.handleChange} type="text" placeholder="Apellido Paterno" value={formData.apPat} name="apPat" maxLength="48"/></span>                                                                               
                                    <span className="icon is-small is-left">
                                    <i className="fa fa-user"></i>
                                    </span>
                                </p>
                                </div>
                                <div className="field">
                                <p className="control is-expanded has-icons-left has-icons-right">
                                <span > <input className="input  is-small " onChange={this.handleChange} type="text" placeholder="Apellido Materno" value={formData.apMat} name="apMat" maxLength="48"/></span> 
                                    <span className="icon is-small is-left">
                                    <i className="fa fa-envelope"></i>
                                    </span>
                                    <span className="icon is-small is-right">
                                    <i className="fa fa-check"></i>
                                    </span>
                                </p>
                                </div>
                            </div>
                        </div>                         
                        <div className="control pb-2">
                            <p className="help ">Dirección</p>
                            <input className="input is-small" onChange={this.handleChange} type="text" placeholder="Calle" value={formData.nombre} value={formData.calle} name="calle" maxLength="20"/>                                                                                                                                            
                        </div>
                        
                                                
                        <div className="field is-horizontal py-0 	mr-0 pr-0">                            
                            <div className="field-body 	mr-0">
                              
                            <div className="columns mr-0">
                                <div className="column is-one-fifths pr-0">
                                    <p className="">  <input className="input is-small " onChange={this.handleChange} type="text" placeholder="N. Exterior" value={formData.noExt} name="noExt" maxLength="10"/></p>                                 
                                    
                                </div>
                                <div className="column is-one-fifths pr-0">
                                    <span > <input className="input is-small" onChange={this.handleChange} type="text" placeholder="N. Interior" value={formData.noInt} name="noInt" maxLength="10"/></span> 
                                   
                                </div>
                                <div className="column is-two-fifths pr-0">
                                <p className=""> <input className="input is-small" onChange={this.handleChange} type="text" placeholder="C.P." value={formData.cp} name="cp" maxLength="5"/></p>
                                </div>
                            </div>                                                                                                                 
                            </div>
                        </div>                                                
                        <p className="py-1"> <input className="input  is-small" onChange={this.handleChange} type="text" placeholder="Colonia" value={formData.colonia} name="colonia" maxLength="30"/></p>                            
                                                
                                                
                        <p className="help ">Contacto</p>
                        <div className="field is-horizontal">                            
                            <div className="field-body">
                                <div className="field">
                                <p className="control is-expanded has-icons-left">
                                <span >        <input className="input is-small" onChange={this.handleChange} type="text" placeholder="Telefono" value={formData.telefono} name="telefono" maxLength="10"/></span>                                                                               
                                    <span className="icon is-small is-left">
                                    <i className="fa fa-user"></i>
                                    </span>
                                </p>
                                </div>
                                <div className="field">
                                <p className="control is-expanded has-icons-left has-icons-right">
                                <span > <input className="input  is-small" onChange={this.handleChange} type="text" placeholder="Celular" value={formData.celular} name="celular" maxLength="10"/></span> 
                                    <span className="icon is-small is-left">
                                    <i className="fa fa-envelope"></i>
                                    </span>
                                    <span className="icon is-small is-right">
                                    <i className="fa fa-check"></i>
                                    </span>
                                </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="pb-2">
                            <span className="pl-0"> <input className="input is-small" onChange={this.handleChange} type="email" placeholder="Correo" value={formData.correo} name="correo" maxLength="50"/>  </span> 
                        </div>
                                                                                                                      
                        <div className="is-flex " >
                            {botones}
                            <ToastContainer  enableMultiContainer containerId={'ModalPromovido'}/>
                        </div>
                        
                    </form>
                    ):((<p>Cargando...</p> ))}
                </Modal>
            </div>):((<p>Cargando...</p> ))}
            </React.Fragment> )
    }
}

export default PromoLlamadaModal;