import React,{useState} from 'react';
import MapaConEntrada from "./autocomplete2";
import ModalDatGen from './MapaModalDatosGen'
import PanelMapa from './MapaPanelMap';
import ReactTooltip from "react-tooltip";
const MapaTablaPromov = ({datosJS,setActualizar, actualizar,poligonos}) => {
    //let datos=datosJS;
    //let datos=datosJS;
    const [datos,setDatos]=useState(datosJS);
    const [tabla,setTabla]=  useState("");      
    const [promotor,guardarPromotor] = useState({ nombre:''});
    const [registro,setRegistro]=useState("");
    const[infoPromot,setinfoPromot]=useState("");
    const [arregloProm,setArregloProm]=  useState([]); 
    const [mensaje,setMensaje]=useState("");
    const llamdaModal=()=>{
        setMensaje("is-active")
    }    
    const RenglonNum =(valorId,regis)=>{
       //console.log(valorId);
        setTabla(valorId);
        if (!arregloProm)
        {   setArregloProm(prevItems => [...prevItems, { valor: valorId,nombre:regis.promNombre }])
        }else
        {   arregloProm.find(  (registro) => registro.valor === valorId  ) ?(
                  setArregloProm(arregloProm.filter(item => item.valor !== valorId)) )
                : ( setArregloProm(prevItems => [...prevItems, {  valor: valorId,nombre:regis.promNombre }]) )
        }
        let info=datos.filter((dat)=>dat.promId=== valorId) ;
        setRegistro(regis);
        setinfoPromot(info[0]);
    }

    function filtro(){        
        let auxCadena=nombre.trim();         
        let info=datos.filter((dat)=>dat.promNombre.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info);
    }

    function TablaPromo(datos) {
        let numRenglon=0;
        return(
            <div id="tamTablaMapa">
                <table className="table is-sobrepuesto " >
                    <thead >    
                        <tr>            
                            <th style={{padding: 0.2 +'rem'}}>{datos.length} </th>          
                            <th style={{padding: 0.2 +'rem'}}>Promovidos</th>            
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {datos.map((promovido) => {
                            let auxNombre="";
                            auxNombre=promovido.promNombre;    
                            //console.log(auxNombre);
                            numRenglon++;                          
                        return (                  
                            <tr className={ arregloProm.find(  (registro) => registro.valor === promovido.promId  )   ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promovido.promId,promovido) }  >                                
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span data-tip="Información del Promovido">
                                        <a className="" onClick={llamdaModal}> <img src={arregloProm.find(  (registro) => registro.valor === promovido.promId  ) ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                    </span>                              
                                </th>
                                <th style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>   
                                                                                 
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
    }

	//extraer el promo
	const {nombre} = promotor;        
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };

    const btnActualizar=e=>{
        e.preventDefault();        
        //console.log(actualizar);
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Promovidos"><a className="pt-3 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/> 
                        </a></span>
    const modal= mensaje? (<ModalDatGen datGen={registro} mostrar={mensaje} setMensaje={setMensaje} />):(null);      
    const mapa =datos&&poligonos?<PanelMapa poligonos={poligonos} arrProm={arregloProm} setArregloProm={setArregloProm} setActualizar={setActualizar} actualizar={actualizar} setDatos={setDatos} datos={datos}/>:null
    const contenido = promotor? (filtro()):(TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    return (
    <div className="contenido-tbPromov ">
        <div className="tile is-ancestor ">
            
            <div className="tile is-horizontal is-parent">
                
                <div className=" is-child box">
                                            
                    <div className="container">
                        <div className="field">            
                            <div className="field is-horizontal ">
                                <input
                                    className="input" 
                                    type="promo"
                                    name="nombre"                         
                                    placeholder="Buscar promovido"
                                    value={nombre}
                                    onChange={onChange}/>
                                    <ReactTooltip place="left" type="success" effect="solid" />
                                {btnRecargar}        
                            </div>                                                        
                        </div>		            
                        {llamada}
                    </div>
                </div>
                {  mapa }
            </div>

        </div>
        
        {modal}
    </div>
  );
}
 
export default MapaTablaPromov;