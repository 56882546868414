import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import sistemaHooks from '../hooks/sistema_hook';
const { forwardRef, useImperativeHandle } = React;

const TabPage = forwardRef((props, ref) => {
    //console.log(`Inicia TAB`)
    const {onValueTabChanged} = props
    let history = useHistory();
    const [currentTab,setTab] = sistemaHooks("TabSelected");
    const [currentMenu,setMenu] = sistemaHooks("MenuSelected");
    const [pestania,setPestania] = useState(null);
    let  tabs = [ { id:'Inicio', label: 'Presidencia', link:"/inicio"},{ id:'Inicio2', label: 'Coordinadores', link:"/coordinadores" },
    { id:'Inicio3', label: 'Rutas', link:"/rutas"},{ id:'Promotor', label: 'Promotores', link:"/promotores" },{ id:'Promotor-Lista', label: 'Búsqueda', link:"/busqueda" }
    ,{ id:'Ubicacion', label: 'Ubicacion', link:"/mapa" },{ id:'Incidencia', label: 'Incidencia', link:"/incidencia" }
    ,{ id:'PREP', label: 'PREP', link:"/prep" },{ id:'Movilizacion', label: 'Movilización', link:"/movilizacion" }]
    // The component instance will be extended with whatever you return from the callback passed as the second argument
    useImperativeHandle(ref, () => ({
        setAppTab(tabSelect,menuSelect) {
            console.log(`TAB Dentro setAppTab TAB[${tabSelect}] MENU[${menuSelect}] `);
            setMenu(menuSelect);
            setTab(tabSelect);
    }}));
	
    useEffect(()=>{ 
       const color='#3273DC';
		const verificaTab = async () => {
          //  console.log(`TAB Dentro UseEffect Menu ${currentMenu} `);
            if( currentMenu === "Inicio" ){ //currentMenu !== "Reportes" || currentMenu !== "LOGIN" tab-posicion
                //console.log(`TAB Dentro UseEffect Creando DOCUMENT ${currentMenu} `);
                const document = (
                    <div id='Tab-Principal'>
                        <div className="hero-foot">
                            <div className="tabs is-boxed is-very-small is-centered tab-posicion ">
                            <ul>
                            {
                                tabs.map( tabInforma => (
                                   
                                    <li 
                                        onClick={ () => clickTab(tabInforma.id, tabInforma.link)}
                                        className={currentTab === tabInforma.id ? 'is-active' : 'is-seleccionado'} 
                                        key={tabInforma.id}
                                        id={tabInforma.id}>
                                        <a  >
                                            <span style={{color: currentTab === tabInforma.id ? color : 'white' }} >
                                                {
                                                    tabInforma.label
                                                }
                                            </span>
                                        </a>
                                    </li>
                                ))
                            }
                            </ul>
                        </div>
                        </div>
                        
                </div>)
                
                setPestania(document)
            } else{
              //  console.log(`TAB Dentro UseEffect Creando DOCUMENT NULL ${currentMenu} `);
                setPestania(null)  
            }
            
	    }
	    verificaTab();
	    //console.log('dentro2 del effect');
	},[currentTab,currentMenu]);

    const clickTab = (idTab, link) => {
       // console.log(`TAB CLICK TAB[${idTab}] MENU[${currentMenu}] `);
        setTab(idTab)
        onValueTabChanged(idTab, currentMenu)
        history.push(link)
    }
   
    return( currentTab ? pestania : null );
})

export default TabPage