import React,{useState} from 'react';
import NumberFormat from 'react-number-format';
import GraficaCoord  from '../Graficas/GraficaCoord';
import sistemaHooks from '../hooks/sistema_hook';
const TablasRutas = ({idVal,Jsval}) => {
    //const auxValores = valor;
    let auxRutas = Jsval;
    //console.log(Jsval);
    const [rutaNum,setRuta] = sistemaHooks("RutaNum");
    let auxTtRutas =0;
    let auxTMetFinal =0;
    let auxTPromov =0;
    let auxTAvaGen =0;
    let auxTMetActual =0;
    let auxTEfec =0;
    const [tabla2,setTabla]=  useState("");  
    //const [auxJSRuta,setInfo]=  useState("");   

    const RenglonNum =(valor)=>{
      //console.log(valor);
      setRuta(valor);
      setTabla(valor);
  }
    function Color(elcolor)
     {let colorEfecti= "etiqueta";      
      if(elcolor<60){
          colorEfecti= "etiqRojaTab";
      }
      else{
          if(elcolor>=60 && elcolor<80)
          {
              colorEfecti= "etiqNar";               
          }
          else{
              if(elcolor>=80 )
              {
                  colorEfecti= "etiqVerd";
              }
          }
      }
      elcolor=Number(elcolor).toFixed(2);
      return (               
            <span id={colorEfecti} >
                 {elcolor+"%"}
            </span>         
          )      
    };

    function Totales(valorTotal)
    {   auxTtRutas =0;
        auxTMetFinal =0;
        auxTPromov =0;
        auxTAvaGen =0;
        auxTMetActual =0;
        auxTEfec =0;
        
        valorTotal.map((rutas) => { 
            //key=auxTtRutas};
            auxTtRutas++;       
            auxTMetFinal=auxTMetFinal+rutas.meta_final;
            auxTPromov= auxTPromov+rutas.promovidos;          
            auxTMetActual=auxTMetActual+rutas.meta_actual;          
        })
        
        auxTAvaGen= ((auxTPromov/auxTMetFinal)*100)
        auxTAvaGen= auxTAvaGen ? (auxTAvaGen):(0);
        
        auxTEfec=(auxTPromov/auxTMetActual)*100;  
        auxTEfec= auxTEfec ? (auxTEfec):(0);     
    };


    function TablaRuta() {
        let numRenglon=0;
        return(
        <table className="table is-sobrepuesto" >
        <thead >    
          <tr>
            <th style={{padding: 0.2 +'rem '}}>Total </th>
            <th style={{padding: 0.2 +'rem'}}>Meta Final</th>
            <th style={{padding: 0.2 +'rem'}}>Promovidos</th>
            <th style={{padding: 0.2 +'rem'}}>Avance Global</th>
            <th style={{padding: 0.2 +'rem'}}>Meta Actual</th>
            <th style={{padding: 0.2 +'rem'}}>Efectividad</th>      
          </tr>
          {Totales(auxRutas)}
          <tr >
            <th style={{padding: 0.2 +'rem'}}><span className="alinear" ><span id="etiqBlanca">{auxTtRutas}</span></span></th>
            <th style={{padding: 0.2 +'rem'}}><span id="etiqBlanca"><NumberFormat value={auxTMetFinal} displayType={'text'}  thousandSeparator={true}/> </span></th>
            <th style={{padding: 0.2 +'rem'}}><span id="etiqBlanca"><NumberFormat value={auxTPromov} displayType={'text'}  thousandSeparator={true}/></span></th>
            <th  style={{padding: 0.2 +'rem'}}><span id="etiqBlanca">{(auxTAvaGen.toFixed(2))}%</span></th>
            <th style={{padding: 0.2+'rem'}}><span id="etiqBlanca"><NumberFormat value={auxTMetActual} displayType={'text'}  thousandSeparator={true}/></span> </th>
            <th style={{padding: 0.2+'rem'}}><span >{Color(auxTEfec)}</span> </th>      
          </tr>
        </thead>  
        <tbody>          
          {auxRutas.map((rutas) => {
              let auxNombre="";
              auxNombre=rutas.rutaNom;    
              //console.log(auxNombre);
              numRenglon++; 
              /*let idruta =localStorage.getItem('RutaNum') ;
              if (idruta>=0) {
                console.log(idruta);
                setRuta(idruta);
              }     */                    
            return (                  
                <tr className={ rutaNum === rutas.rutaId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(rutas.rutaId) }  >
                    <th className="tablaTR"  style={{padding: 0.2 +'rem'}}><span className="alinear2">{numRenglon+" "}{auxNombre}</span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear"><NumberFormat value={rutas.meta_final} displayType={'text'}  thousandSeparator={true}/> </span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear"><NumberFormat value={rutas.promovidos} displayType={'text'}  thousandSeparator={true}/> </span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear">{rutas.avance_global+"%"}</span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}><span className="alinear"><NumberFormat value= {rutas.meta_actual}displayType={'text'}  thousandSeparator={true}/></span></th>
                    <th className="tablaTR" style={{padding: 0.2 +'rem'}}>                                         
                        {Color(rutas.efectividad)}                    
                    </th>            
                </tr>              
            );
              })
          }                                
        </tbody>
      </table>);
      }
    return (
        <div id="contentGrafica" className="pt-2">
            <div  id="mitabla2">{TablaRuta()}</div>
            <div  ><GraficaCoord valor={auxRutas} descripcion="Avance de Rutas"/></div>
            
            
        </div>
      );
}
 
export default TablasRutas;