import React,{useState,useEffect} from 'react';
import Modal from './PModalPersonalizado';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import Select, { components } from 'react-select';
const ModalAsignarVotacion = ({mostrar,setMensaje}) => {
    let idusu =localStorage.getItem('UsuNum') ;
    //const [listaSecciones,setListaSecciones]=  useState([]);  
    const [listaSeccion,setListaSeccion]=  useState([]);
    const [listaPartidos,setListaPartidos]=  useState([]);    
    const [listaCasillas,setListaCasillas]=  useState([]);    
    const [classNameModal,setclassNameModal]=  useState(mostrar);         
    const [nuevaIncidencia,setNuevaIncidencia] = useState({ Tipo:0,Usuario:0,Casilla:0,Descripcion:"",Nota:""});
    const [nuevaVotacion,setNuevaVotacion] = useState({
                                     partido1:"",partido2:"",partido3:"",partido4:"",partido5:"",partido6:"",partido7:"",partido8:"",partido9:"",partido10:""
                                     ,partido11:"",partido12:"",partido13:"",partido14:"",partido15:"",partido16:"",partido17:"",partido18:"",partido19:"",partido20:""});
    const [listaSecc,setListaSecc]=  useState([]);    
    const[espera,setEspera]=useState(true);
    const[espera2,setEspera2]=useState(true);
    const[espera3,setEspera3]=useState(true);
    const [seccion,setSeccion]=  useState("");
    const[registros,setRegistros]=useState(true);
    const[arreComun,setArreComun]=useState([]);
    //const[casillaSelecc,setCasillaSelecc]=useState("");
    let auxSecciones=[];
    let auxTipos=[];
    let auxcasillas=[];
    let miArrelo=[]        
    const { partido1,partido2 ,partido3 ,partido4 ,partido5 ,partido6 ,partido7 ,partido8 ,partido9 ,partido10 
    ,partido11 ,partido12 ,partido13 ,partido14 , partido15 , partido16 , partido17 ,partido18 ,partido19 ,partido20 } = nuevaVotacion;        
    useEffect(()=>{                         
        
        
        const source = axios.CancelToken.source();     
        async function conectarJSon()  {         

            setEspera(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/votacion-web-municipio-secccasi-lista`;
            let auxU=process.env.REACT_APP_LINK +`/votacion-web-municipio-secccasi-lista`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                           
                'municipio':101,
                'idDispositivo':'7y8dyguwjhds'          
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                  
                if (aux)
                {    //console.log(aux);                
                    auxSecciones=aux;     
                    let arreSecc=[];                
                    let variable=0;
                   // console.log("despues del llenado secciones");
                    auxSecciones.map((seccion) => {
                        if (variable!==seccion.seccId)
                        {
                            variable=seccion.seccId;                        
                            arreSecc.push({ value : variable, label :variable })                                               
                        }                                        
                    })
                    setListaSecc(aux);
                    setListaSeccion(arreSecc);
                }                                                          
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log("se un error");
                console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            setEspera(true);            
        };
        
        

        
        
        conectarJSon();
                
    },[]);
            
    const { Tipo ,Usuario , Casilla , Descripcion , Nota } = nuevaIncidencia;        
    
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    {         
        setMensaje("");
        setclassNameModal("");
    }

    async function obtenerVotosCasilla(auxCasill)  {         
        setEspera2(false);            
        const source = axios.CancelToken.source();     
        //let auxU=`http://`+process.env.REACT_APP_LINK +`/votacion-web-secccasi-lista`; 
        let auxU=process.env.REACT_APP_LINK +`/votacion-web-secccasi-lista`;               
        let token =localStorage.getItem('token20') ;                
        let dataInfo = qs.stringify({                           
            'periodo':3,
            'casilla':auxCasill,
            'idDispositivo':'7y8dyguwjhds'              
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};
        await axios(config)
        .then(function (response) {
            aux=  response.data;                                                                                                  
            if (aux)
            {                                
                setListaPartidos(aux); 
                /*let miarreglo=[];
                aux.map((casilla,index) =>
                { let auxinom ="partido"+(index+1);
                    //let auxi=nuevaVotacion[index]                    
                    //miarreglo.push( ["partido"+(index+1)]:casilla.Voto   )                    
                    //console.log(miarreglo);
                    console.log(nuevaVotacion[index]);
                    setNuevaVotacion({
                        ...nuevaVotacion, 
                        [nuevaVotacion[index]] : casilla.Voto
                    })  
                })*/                                       
            }              
                        
        })
        .catch(function (error) {                            
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
            /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
            {                  
            }
            if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
            {
                console.log('sin datos' );
             
            }*/
        });     
        setNuevaVotacion({
            partido1:"",partido2:"",partido3:"",partido4:"",partido5:"",partido6:"",partido7:"",partido8:"",partido9:"",partido10:""
            ,partido11:"",partido12:"",partido13:"",partido14:"",partido15:"",partido16:"",partido17:"",partido18:"",partido19:"",partido20:""
        })       
        setEspera2(true);            
    };
    const llamarApi=(numCasilla,cadena)=>{
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {                    ; 
            setEspera3(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/votacion-web-actualiza`;     
            let auxU=process.env.REACT_APP_LINK +`/votacion-web-actualiza`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                'usuario':idusu,
                'periodo':3,
                'casilla':numCasilla,
                'valores':cadena,
                'idDispositivo':'7y8dyguwjhds'    
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            setRegistros(true)
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux) 
                   {      //console.log(aux);             
                        const mensaje="Nuevos votos registrados"//aux.mensaje ;            
                       // toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaVotacion'} );                                                   
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });   
            setNuevaVotacion({
                partido1:"",partido2:"",partido3:"",partido4:"",partido5:"",partido6:"",partido7:"",partido8:"",partido9:"",partido10:""
                ,partido11:"",partido12:"",partido13:"",partido14:"",partido15:"",partido16:"",partido17:"",partido18:"",partido19:"",partido20:""
            })       
            setNuevaIncidencia({ Tipo:0,Usuario:0,Casilla:0,Descripcion:"",Nota:""})
            setListaPartidos([])
            setListaCasillas([]);
            setSeccion("")   
            esconderModal()     
            setEspera3(true);            
        };
        conectarJSon();
    }
    function verificaDAtos() {
        let valor=true;
        let cadena=""        
        let auxNuevaVotacion=nuevaVotacion        
        for (let index = 0; index < miArrelo.length; index++) {
            let partido="partido"+(index+1)
            let numValido=auxNuevaVotacion[partido] !=="" ? auxNuevaVotacion[partido]:0
            if (auxNuevaVotacion[partido] !=="" ) {
                cadena=cadena+miArrelo[index].numSecc+","+numValido+"|";
                //console.log(cadena);    
            }
                     
        }
        //console.log(cadena);   
        return cadena
    }
    const onSubmit = e =>
	{      
		e.preventDefault();
        if (seccion!=="")
        {
            if (Casilla!==0) 
            {   let auxDato=listaCasillas.filter(  (registro) => registro.value === Casilla  )
                if (auxDato.length===1) 
                {
                       
                    //llamarApi();             
                  let auxverifica=  verificaDAtos()
                    if (auxverifica!=="") {
                        llamarApi(nuevaIncidencia.Casilla,auxverifica);      
                    }
                    else{
                        toast.warning("No se lleno ningun Partido", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaVotacion'} );      
                    }
                }else 
                {
                    toast.warning("Debe seleccionar la nueva casilla", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaVotacion'} );      
                }
                
                
            }else
            {
                toast.warning("Debe seleccionar la casilla", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaVotacion'} );   
            }    
        }else 
        {
            toast.warning("Debe seleccionar la sección", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaVotacion'} );   
        }
        
        		
	}
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
       
    }
    const guardaCasilla=(casillas) =>
    {   const resultados= listaCasillas.filter(  (registro) => registro.value === casillas  )             
        //setCasillaSelecc( resultados);                  
        setNuevaIncidencia({
            ...nuevaIncidencia, //copiamos el usuario
            //[e.target.name] : e.target.value  
            Casilla:resultados[0].value
        })
        obtenerVotosCasilla(resultados[0].value);
    }


    const guardaSeccion=(auxseccion)=>
    {   //console.log(auxseccion);                                          
        //setSeccion(false)
        const dato= listaSecc.filter(  (registro) => registro.seccId === auxseccion )  
        //console.log(listaSecc);
        //console.log(dato);                        
        let arreCasilla=[];                      
        dato.map((seccion) => 
        {            
            arreCasilla.push({ value : seccion.casillaId, label : seccion.casillaNom })      
            //console.log(seccion.CasillaId+" "+seccion.TipoNom);                                                                                             
        })
        setListaCasillas(arreCasilla);
        setSeccion(auxseccion)                       
    }

    
    const selectorCasilla =seccion?
                    (<Select placeholder={"Casilla"} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600',height:'12px' ,minHeight:'30px',  }),
                                option: styles => ({ ...styles, fontSize: '12px'}),
                                menuList: styles => ({ ...styles,width:'100px' }),
                                container: styles => ({ ...styles,width:'100px' })
                            }
                        }
                        options={listaCasillas}
                        onChange={opt =>  guardaCasilla(opt.value) }
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    ):null;
    const selectorSeccion=(<Select placeholder={"Sección"} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600',height:'12px' ,minHeight:'30px',  }),
                                option: styles => ({ ...styles, fontSize: '12px'}),
                                menuList: styles => ({ ...styles,width:'100px' }),
                                container: styles => ({ ...styles,width:'100px' })
                            }
                        }
                        options={listaSeccion}
                        onChange={opt =>  guardaSeccion(opt.value)}
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    );                    
    const onChange = (e) => {        
        let { target } = e;                        
        let expNopermitida = new RegExp('[A-Za-z._:$!%-({})?/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[0-9]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            setNuevaVotacion({
                ...nuevaVotacion, //copiamos la incidencia
                [e.target.name] : e.target.value  
            })   
        }     
        
    }    
    
    const llenadoCards=(arrNumero,partidoUrl,Votos) =>{
        let dato=<div></div>
        arrNumero++
        console.log(arrNumero);
            if (arrNumero===0) {
                console.log(arrNumero+partidoUrl+Votos);
                setNuevaVotacion({
                    ...nuevaVotacion, //copiamos la incidencia
                    [partido1] : Votos  
                })    
                dato= ( <div className="card PrepVotosCards">
                            <div className="card-image">
                                <figure className="image is-64x64">
                                <img src={partidoUrl} alt="Placeholder image"/>
                                </figure>
                            </div>
                            <div className="card-content">                                
                                <div className="content">    
                                <input className="input"onChange={onChange} type="text" placeholder={Votos} value={partido1} name="partido1" maxLength="8"/>
                                </div>
                            </div>
                        </div>
                        )
            }                                                                                                                 
          return (dato)
    }
    
    const CrearBotones=(auxArr)=> {
       let micontador=0;      
       
       let miColummna="control card PrepVotosCards"       
       //console.log(auxArr); 
       return(
            <div className="pt-2">     
            
                { auxArr.map((casilla,index) =>
                { let auxinom ="partido"+(index+1)
                let miaux=<div></div>
                miColummna="control card PrepVotosCards"
                    if ((index+1)%2 ==0) {
                        miColummna="card PrepVotosCards pl-2"   
                        //miaux=<br/>                                                                        
                    }
                    let auxi={ numSecc :casilla.Id,numPart :index }
                    miArrelo.push(auxi);
                    if (casilla.ParImg==="MORPTNA") {
                        miaux=
                        <figure className="image pt-3 pl-1 is-48x48 ">
                            <img src={casilla.ParImgWeb} alt="Placeholder image" style={ {position: "relative"}}/>     
                            <img src="partido_pan.svg" alt="Placeholder image"style={ {position: "absolute"}}/>                     
                            </figure>                                                    
                    }else{
                        <figure className="image pt-3 pl-1 is-48x48 ">
                            <img src={casilla.ParImgWeb} alt="Placeholder image" />     
                            
                            </figure>                                                    
                    }
                    return(
                    
                        <span className="field is-grouped  PrepVotosCards micard ">
                        <span className="control">
                            <figure className="image pt-3 pl-1 is-48x48 ">
                            <img src={casilla.ParImgWeb} alt="Placeholder image"/>     
                                           
                            </figure>                                                    
                        </span>
                        <span className="control pt-4  ">
                            <input className="input mi-input is-small "onChange={onChange} type="tel"   placeholder={casilla.Voto} value={nuevaVotacion[index]} name={auxinom} maxLength="5"/>
                            </span>
                    </span>
                    
                    
                )     
                }
                )
                }
                
            </div>
       )
    }
    const botones = espera ? (<div className="pt-2 pb-0"><span className="pr-4"><button className="button is-aceptar " onClick={mostrarModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>);
    const auxpartido =listaPartidos.length>0&&espera2&&espera3?CrearBotones(listaPartidos):null
    const masespacios=!espera2?<div> <br/> <br/> <br/> <br/> <br/><br/><br/> <br/><br/><br/><br/><br/><br/></div>:null
    const espvacios= nuevaIncidencia.Casilla===0?<div> <br/> <br/> <br/> <br/> <br/><br/><br/> <br/><br/><br/><br/><br/><br/></div>:masespacios
    function llenarTodosSelects()
    {        
        
        return(
            <div> 
                { registros?(<div> <div className="field  ">   
                    <div className="columns	">                                                                                                                    
                        <div className="pb-3 pl-3">
                            {selectorSeccion}                        
                        </div>
                        <div className="pb-3 pl-2">
                            { selectorCasilla}                        
                        </div>                               
                    </div>              
                </div>                
                     </div>):null                                                              }

                  {espvacios}
                  {auxpartido}
                
                    
            </div>
        )

    };
    
    const auxiliarFormulario = listaSeccion&&espera3  ? ( llenarTodosSelects()  ): ( (<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>) ) 
    const auxPrincipal= espera2 ?(auxiliarFormulario ):null;
    return (
        <div>
            <a 
                onClick={btnMostrar}
                className="pl-1 pr-0 button is-sistema">
                    Asignar 
            </a> 
        <Modal 
            id={"ModalDatosGenerales"}
            onClose={()=>esconderModal()}
            className={classNameModal}
            title={"Asignar Votación"} 
            ancho={350}
            buttonsFooter={botones}
            onSubmit={onSubmit}
            >
            <form className="" onSubmit={onSubmit}>  
                {auxiliarFormulario}
                
{/*                 <div className="tile pt-2">
                    <div className="tile is-vertical">
                                
                    </div>      
                </div>     */}
                <div className="">
                    
                </div>
                
                <ToastContainer enableMultiContainer containerId={'ModalNvaVotacion'}/>    
            </form>     
    </Modal>
    </div>
);
}
 
export default ModalAsignarVotacion;