import React,{useState,useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import Cargando from '../layout/Cargando2';
//import TresPuntos from '../layout/TresPuntos';
import AltaProgramas from './PromoAltaModal';
import ReactTooltip from "react-tooltip";
const PromoTablaProgramas = ({idValor}) => {
    const [datos,setDatos] = useState("");
    let idusu =localStorage.getItem('UsuNum') ;
    const [tabla,setTabla]=  useState("");
    const[espera,setEspera]=useState(false);
    const [contador,setContador]=  useState(0);
    let numRenglon=0;    
    //console.log(idValor);
    useEffect(()=>{ 
      //  console.log("dentro del effect de la tabla");
        const source = axios.CancelToken.source();
        async function conectarJSon()  {           
            setEspera(false);
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-programa`;               
            let auxU=process.env.REACT_APP_LINK +`/promovido-programa`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'promovido': idValor ,                
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
           // console.log("entrando api");
            await axios(config)
            .then(function (response) {
                aux=  response.data;
             
             //console.log(`programas API: ${JSON.stringify(aux.total)}`)                  
             //   console.log(aux[0]);
                if (aux.total)
                {  // console.log("arreglo: "+aux[0]);                                
                    setDatos(aux.total);
                   // console.log(`programas API: ${JSON.stringify(aux)}`) 
                }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
               // console.log("se tuvo un error");
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });
            setEspera(true);                
        };
        conectarJSon();
    },[idValor,contador]);
    const RenglonNum =(valorId,regis)=>{
       // console.log(valorId);
         setTabla(valorId);
         //setRegistro(regis);
     }
    
    const filtraRegistros=()=>{
        const probando= datos.filter(item => item.programaStatus !== 0)
        //console.log(probando);
        if (probando.length>=1) 
        {
            return(
                <div id="tamTablaProgramas">
                     <table className="table is-hoverable" id="">
                    <thead >    
                        <tr>
                        <th style={{padding: 0.2 +'rem '}}></th>
                        <th style={{padding: 0.2 +'rem'}}>Nombre</th>                        
                        <th style={{padding: 0.2 +'rem'}}>Fecha</th>            
                        </tr>                    
                    </thead>  
                    <tbody>      
                        {datos.map((programa) => {
                            let auxNombre="";
                            auxNombre=programa.programaNombre;    
                            //console.log(auxNombre)
                            const auxval =  programa.programaStatus === 1 ?(true):(false);
                            
                            numRenglon++;                          
                            return (  (programa.programaStatus === 1) ?(                 
                                <tr className={ tabla === programa.programaId ? 'is-selected' : '' }  key={numRenglon} onClick={ () => RenglonNum(programa.programaId,programa) }  >                                
                                    <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear " style={{fontSize:.6+'rem',fontWeight: "bold"} }>  </span></th>
                                    <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear " style={{fontSize:.6+'rem',fontWeight: "bold"} }>{auxNombre}  </span></th>                                        
                                    <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear ">{auxval?(programa.programaAlta.substring(0,10)):('')}  </span></th>                                                    
                                </tr>):(null)              
                            );})
                        }                                                                      
                    </tbody>
                </table>
                </div>
        )
        }
        else{
            return(
            <div className="pl-6" id="tamTablaProgramas">
                <table className="table is-hoverable" id="">
                <thead >    
                    <tr>
                    <th style={{padding: 0.2 +'rem '}}></th>
                    <th style={{padding: 0.2 +'rem'}}></th>                        
                          
                    </tr>                    
                </thead>  
                <tbody>                              
                    <tr className= ''    >                                
                        <th className="tablaTR " style={{padding: 0.2 +'rem'}}></th>
                        <th className="tablaTR " style={{padding: 0.2 +'rem'}}>Debe registrar aportaciones.</th>
                    
                    </tr>                                                                                                            
                </tbody>
                 </table>
            </div>
        )    
        }
    }
    const btnActualizar=e=>{
        e.preventDefault();
        numRenglon++;
        //console.log("entrado albtn "+contador);  
        setContador(contador+1);
    }
    
    const llamada= datos?( <div className="">{filtraRegistros()}</div>):(<Cargando />)
    const actualizar = <span className="pt-2" data-tip="Recuperar Aportaciones"><a  onClick={btnActualizar}>
                             <img src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/> 
                    </a></span>
    const altas =espera?( <div className="pt-2 pl-4"><AltaProgramas prog={datos} totales={numRenglon} promovId={idValor}/></div>):(<progress className="progress is-small is-link pt-2" max="100">15%</progress>)
    return (
        
           /*  <div className="card-img-izq">
                
                
                
            </div> */
            <div className="pl-5">
            <div className="tile is-parent box ">
                <div className="   ">
                    <div className="is-8">                        
                        <div className="is-child ">
                            <div className="field">            
                                <div className="control columns">
                                    <p className=" px-5 pt-1 has-text-weight-medium is-size-4">Aportaciones</p>
                                    <ReactTooltip place="left" type="success" effect="solid" />
                                    {actualizar}
                                    {altas}                     
                                </div>
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
           
        
      );
}
 
export default PromoTablaProgramas;