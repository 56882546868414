import React,{Component } from 'react';
//import ReactDOM from 'react-dom';
import Select, { components } from 'react-select';

//import qs from 'qs';
//import TablaPromotores from './RPTablaPromot';
import RPllenaPromo from './RPLlenaPromot';
import Cards from './RPCardSeccion';
import Graficas from './RPGraficas';
class ReportPromotorCabecera extends Component {
    constructor(props){
        super(props)
        this.state={
            posts: [],
            isLoading: true,
            errors: null,
            registros: this.props.valores,
            Seccion: 0,
            infoPromotor: [],
            boton3:[]
        }
        this.contador=0
    }
    
    llenaPromo() {
        //console.log(`Cabecera Llena Select`);
        const { registros} = this.state;
        let arreSeccion=[];   
        registros.map((secc) => {
            arreSeccion.push({ value : secc.SeccionId, label :secc.SeccionNombre })
        })
        this.setState({
            boton3: arreSeccion,
            isLoading: false
          });
    }
    guardaPromotor(seccion) {
        const { registros } = this.state;
        localStorage.setItem('SeccNum',seccion);
        //console.log(`Cabecera Asigna Promovido ${seccion}`);
        const resultados= registros.filter(  (registro) => registro.SeccionId === seccion  )
       // console.log(`Cabecera Filtro ${JSON.stringify(resultados)}`);
        this.setState({
            Seccion: seccion,
            infoPromotor: resultados,
            isLoading: false
          });
          /*this.contador ++
          console.log(`Cabecera Contador ${this.contador}`);
          if(this.contador >= 2){
            ReactDOM.render(<CentroPromo valProm={resultados[0]} idProm={promotor} />, document.getElementById('promotorInfo'));
          }*/
    }
    componentDidMount() {
        this.llenaPromo();
    }
    
    render() {
        const { isLoading, posts, boton3, infoPromotor, Seccion } = this.state;
        /*const theme = theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#FFD20487",
              primary: "#FFD204",
              //primary75: '#FFD204',                    
            },
                   
          });*/
        return (
          <React.Fragment>
            
              {!isLoading ? (
                <div>
                   <div className= "select-promotor">
                    <div className= "selTam-promo2">
                        <Select placeholder="Sección" 
                            styles={
                                {
                                    control: styles => ({ ...styles, fontSize: '12px', fontWeight: '600' ,width:'110px'}),
                                    option: styles => ({ ...styles, fontSize: '12px',width:'80px' }),
                                    menuList: styles => ({ ...styles,width:'80px' }),
                                    container: styles => ({ ...styles,width:'80px' })
                                }
                            }
                            options={boton3}
                            onChange={opt =>  this.guardaPromotor(opt.value) }
                            
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>   
                         
                    </div>
                    { Seccion  ? (<Graficas valor={infoPromotor[0]}/>) : null }                 
                    </div>
                    { Seccion  ? (<div id="promotorInfo">
                                 
                                <Cards Seccion={infoPromotor[0]}/> <RPllenaPromo  valProm={infoPromotor[0]} idProm={Seccion} /> 
                        </div>) : null
                        
                    }
                    
                </div>) : 
                (<p>Cargando...</p> )
              }
            
          </React.Fragment>
        );
      }
}


export default ReportPromotorCabecera;
