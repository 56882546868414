import React,{useState} from 'react';
import PanelPromovido from './BusqPanelPromovido';
const BusqTablaPromot = ({datosJS,seccion,validoPromotor}) => {
    //let datos=datosJS;
    let datos=datosJS;
    const [tabla,setTabla]=  useState("");      
    const [promotor,guardarPromotor] = useState({ nombre:''});
    const [registro,setRegistro]=useState("");
    const[infoPromot,setinfoPromot]=useState("");
    const RenglonNum =(valorId,regis)=>{
       //console.log(valorId);
        setTabla(valorId);
        let info=datos.filter((dat)=>dat.PromotId=== valorId) ;
        //console.log(info[0].PromotNombre);
        localStorage.setItem('NomProm',''+info[0].PromotNombre);
        setRegistro(regis);
        setinfoPromot(info[0]);
    }

    function filtro(){        
        let auxCadena=nombre.trim();         
        let info=datos.filter((dat)=>dat.PromotNombre.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info);
    }

    function TablaPromo(datos) {
        let numRenglon=0;
        return(
            <div id="tamTablaPromotorBusqueda">
                <table className="table is-sobrepuesto " >
                    <thead >    
                        <tr>            
                            <th style={{padding: 0.2 +'rem'}}>{datos.length} Promotores</th>            
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {datos.map((promotor) => {
                            let auxNombre="";
                            auxNombre=promotor.PromotNombre;    
                            //console.log(auxNombre);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === promotor.PromotId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promotor.PromotId,promotor) }  >                                
                                <th className={ tabla === promotor.PromotId ? 'is-seleccionado' : '' } style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
  /*      function TablaPrueb() {
            let numRenglon=0;
            return(
                <div id="tamTabla">
                    <table className="table is-hoverable " style={{overflowY: +'auto' }} >
                        <thead >    
                            <tr>            
                                <th style={{padding: 0.2 +'rem'}}>Total</th>  
                                <th style={{padding: 0.2 +'rem'}}>Promotores</th>            
                            </tr>                        
                        </thead>  
                        <tbody>                                                  
                            <tr className={ tabla === 1 ? 'is-selected' : '' }  key={numRenglon} onClick={ () => RenglonNum(1,null) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{1}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">Promotor Apellido Paterno apellidoMat   </span></th>                                                    
                            </tr>              
                            <tr className={ tabla === 2 ? 'is-selected' : '' }  key={numRenglon} onClick={ () => RenglonNum(2,null) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{2}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">Promotor Apellido Paterno apellidoMat  </span></th>                                                    
                            </tr>              
                            <tr className={ tabla === 3 ? 'is-selected' : '' }  key={numRenglon} onClick={ () => RenglonNum(3,null) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{3}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">Promotor Apellido Paterno apellidoMat  </span></th>                                                    
                            </tr>                                     
                        </tbody>
                    </table>
                </div>
            );
            }
    
*/
	//extraer el promo
	const {nombre} = promotor;        
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };

    const datGeneral = datosJS&&registro? (<PanelPromovido promId={tabla} secciones={seccion} infoPromot={infoPromot} listaPromot={datos} validoPromotor={validoPromotor}/>):(null)
    const contenido = promotor? (filtro()):(TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    return (
    <div className="contenido-tbPromov ">
        <div className="tile is-ancestor is-3">
            <div className="tile is-horizontal is-parent">
                <div className=" is-child box">                        
                    <div className="container">
                        <div className="field">            
                            <div className="control">
                                <input
                                    className="input" 
                                    type="promo"
                                    name="nombre"                         
                                    placeholder="Buscar promotor"
                                    value={nombre}
                                    onChange={onChange}/>
                            </div>
                        </div>		            
                        {llamada}
                    </div>
                </div>
                {datGeneral}
            </div>
            
            <div  className="">
            
            
                {/*  */}
        </div>
        </div>
       
    </div>
  );
}
 
export default BusqTablaPromot;