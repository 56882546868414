import React,{useState} from 'react';
//import LlenaPromovidos from './RPLlenaPromovidos';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import qs from 'qs';
import NumberFormat from 'react-number-format';
const IncTablaTipo = ({datosJS,setListaXTipo,setActualizar,actualizar,setPromvSeccion,setEspera}) => {
    let datos=datosJS;    
    //console.log(datos);
    const [tabla2,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [nombrePromot,setNombrePromot]=useState("")
    let idusu =localStorage.getItem('UsuNum') ;
    const llamarApi=(numTipo)=>{
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {   
            setEspera(false); 
            //let today = new Date();                    
           // let fec = moment(today);
          //  console.log("llamando a la api por seccion " +fec.format("HHmmss.SSS"));                                         
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-web-promocion-listsecc-promovido`;     
            let auxU=process.env.REACT_APP_LINK +`/promovido-web-promocion-listsecc-promovido`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                'seccion':numTipo,
                'idDispositivo':'89wuuidsddsfe'      
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                        
                //let today2 = new Date();                    
                //let fec2 = moment(today2);
                
              //  console.log("obteniendo respuesta de la api "+fec2.format("HHmmss.SSS"));                                                                                             
                   if (aux) 
                   {//console.log(aux);                       
                    setListaXTipo(aux);
                    
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });                                  
            setEspera(true);
        };
        conectarJSon();
    }
    let auxArreglo=[];    
    const RenglonNum =(valorId,regis,PromNomb)=>{
        //console.log(regis);
        setTabla(valorId);
        //setNombrePromot(PromNomb);
        setPromvSeccion(valorId)
        llamarApi(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+"Lista de Municipios " +".xlsx")
    };
    function filtro(){        
        let auxCadena=nombre.trim();   
        auxArreglo=[]                  
        let info=datos.filter((dat)=>dat.SeccNum.search(auxCadena ) != -1)         
        return TablaPromo(info);
    }
    function Color(elcolor,auxvotos)
    {let colorEfecti= "etiqueta";      
     if(elcolor<30){
         colorEfecti= "etiqRojaTab";
     }
     else{
         if(elcolor>=30 && elcolor<60)
         {
             colorEfecti= "etiqNaraTab";               
         }
         else{
             if(elcolor>=60 )
             {
                 colorEfecti= "etiqVerdTab";
             }
         }
     }
     elcolor=Number(elcolor).toFixed(2);
     return (               
           <span id={colorEfecti} >
                
                <NumberFormat value= {auxvotos}displayType={'text'}  thousandSeparator={true}/>
           </span>         
         )      
   };
   function Totales(valorTotal)
    {   let auxMov=0
        let auxPromv=0
        let arrTotal=[]
        valorTotal.map((promov) => 
        {             
            auxMov=auxMov+promov.Movilizado       
            auxPromv=auxPromv+promov.Promovido
        })
        arrTotal={totMov:auxMov,totProm:auxPromv}
        return (arrTotal)
    };
    function TablaPromo(auxdatos) {
        let numRenglon=0;
        let auxi2=["#","Movilizado","Promovido","Sección"];
        auxArreglo.push(auxi2);
        let arrTot=[];
        arrTot=Totales(auxdatos)
        let tot= Object.keys(auxdatos).length;
        if(tot===0){
            let auxi3=["","Sin","Secciones"]
            auxArreglo.push(auxi3);            
        }           
        return(
            <div id="TablaMoviliSecciones">
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>            
                            <th style={{padding: 0.2 +'rem'}}>Movilizado</th>  
                            <th style={{padding: 0.2 +'rem'}}>Promov</th>            
                            <th style={{padding: 0.2 +'rem'}}>Secc</th>            
                        </tr>      
                        
                        <tr >                            
                            <th style={{padding: 0.2 +'rem'}}>{Color((arrTot.totMov/arrTot.totProm)*100 , arrTot.totMov)}</th>
                            <th style={{padding: 0.2 +'rem'}}><span id="etiqBlanca"><NumberFormat value={arrTot.totProm} displayType={'text'}  thousandSeparator={true}/></span></th>
                            <th  style={{padding: 0.2 +'rem'}}><span id="etiqBlanca">{tot}</span></th>                                                        
                        </tr>                  
                    </thead>  
                    <tbody>          
                        {auxdatos.map((munici,index) => {
                            let auxSeccion="";
                            auxSeccion= munici.SeccId;    
                            //console.log(auxNombre);
                            let auxi=[index+1,munici.Movilizado,munici.Promovido,auxSeccion]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla2 === munici.SeccId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(munici.SeccId,munici,auxSeccion) }  >                                
                                {/* <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{munici.Movilizado}  </span></th> */}
                                <th className="tablaTR" style={{padding: 0.2 +'rem'}}>                                         
                                    {Color((munici.Movilizado/munici.Promovido)*100 , munici.Movilizado)}                    
                                </th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{munici.Promovido}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear5 is-size-7">{auxSeccion}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
    

	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[A-Za-z._:$!%-({})?/*|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
        guardarPromovido({
            ...promovido, //copiamos el nombre
            [e.target.name] : e.target.value  
        })
    }        
    };
    const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Secciones"><a className="pt-2 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  className="pt-2 " alt="" border="0" height="30" width="30"/> 
                        </a></span>
    //const datGeneral =registro? ( <LlenaPromovidos promid={tabla2} nombrePromot={nombrePromot}/> ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datosJS));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    const btnExportar = <span data-tip="Exportar Secciones"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>
    return (

        <div className=" ">
            <div className="tile is-ancestor ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className="tile is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal">            
                                <div className="field-body">
                                    <input
                                        className="input" 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar Sección"
                                        value={nombre}
                                        onChange={onChange}/>
                                </div>
                                {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                {btnRecargar}
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        
      );
}
  
export default IncTablaTipo;