import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import sistemaHooks from '../hooks/sistema_hook';
const { forwardRef, useImperativeHandle } = React;

const SideBar2 = forwardRef((props, ref, ) => {
    let history = useHistory();
    const {onValueTabChanged} = props
    const [currentTab,setTab] = sistemaHooks("TabSelected");
    const [currentMenu,setMenu] = sistemaHooks("MenuSelected");
    const [menuEstructura,setmenuEstructura] = useState(null);                                                  ///reporte-promovido
    let  menuOpcion = [ { id:'Inicio', label: 'Promoción', link:"/inicio"},{ id:'Reportes', label: 'Reportes', link:"/reporte-promotor" },{ id:'Apoyos', label: 'Apoyos', link:"/reporte-promovido-apoyo" },{ id:'ApoyosLista', label: 'Lista apoyos', link:"/reporte-apoyo" }]
    // The component instance will be extended with whatever you return from the callback passed as the second argument
    useImperativeHandle(ref, () => ({
        setAppMenu(tabSelect,menuSelect) {
            setMenu(menuSelect);
            setTab(tabSelect);
    }}));

    useEffect(()=>{ 
        const verificaNombre = async () => {
         //   console.log(`Menu Dentro UseEffect  ${currentMenu} `);
            if( currentMenu) {
                if(currentMenu !== 'LOGIN'){
                    const document = (
                        <aside  className='aside' id='assideLateral'>
                            <nav className="menu active-menu--promocion">
                                <p className="menu-label">Menu</p>
                                <ul className="menu-list ">
                                    {
                                        menuOpcion.map( menuInforma => (
                                            <li key={menuInforma.id} id={menuInforma.id}>
                                                <a className={currentMenu === menuInforma.id ? 'is-active' : ' '}  onClick={ () => clickMenu(menuInforma.id, menuInforma.link)}>
                                                    <span className="menu-text ">
                                                        {
                                                            menuInforma.label
                                                        }
                                                    </span>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </nav>
                        <div id="sidebar-toggler">
                            <span className="icon is-small">
                                <i className="fa fa-angle-double-left">
                                </i>
                            </span>
                        </div>
                        </aside>)
                    setmenuEstructura(document)
                }
                else {
                    setmenuEstructura(null)
                }
            } else {
                setmenuEstructura(null)
            }
            
        }
        verificaNombre();
        //console.log('dentro2 del effect');
    },[currentMenu,currentTab]);

    const clickMenu = (idMenu, link) => {
      //  console.log(`MENU CLICK TAB[${currentTab}] MENU[${idMenu}] `);
        setMenu(idMenu)
        let auxTab=""
        if(idMenu === "Inicio")
            auxTab = idMenu
        onValueTabChanged(auxTab,idMenu)
        history.push(link)
    }
   
    

    return( menuEstructura ? menuEstructura : null );

    
})
 
export default SideBar2;


{/*return (
        <aside  className='aside' id='assideLateral'>
        <nav className="menu active-menu--promocion">
            <p className="menu-label">Menu</p>
            <ul className="menu-list ">
                <li>
                    <a className="" id="menu-is-active" href="/">
                        <span className="menu-text">Promoción</span>
                    </a>
                </li>
            </ul>
            <p className="menu-label">Administrador</p>
            <ul className="menu-list">
                <li>
                <a className="" href="/forms/">
                    <span className="icon is-small"><i className="fa fa-pencil-square-o"></i></span>
                    <span className="menu-text">Promotores</span>
                </a>
                </li>
                <li>
                    <a className="" href="/ui-elements/">
                        <span className="icon is-small"><i className="fa fa-desktop"></i></span> 
                        <span className="menu-text">Graficas</span>
                    </a>
                </li>
                <li>
                    <a className="" href="/tables/">
                        <span className="icon is-small"><i class="fa fa-table"></i></span>
                        <span class="menu-text">Tablas</span>
                    </a>
                </li>
                {/*<li>
                    <a class="" href="/presentations/">
                        <span class="icon is-small"><i class="fa fa-bar-chart"></i></span> 
                        <span class="menu-text">Presentations</span>
                    </a>
                </li>
                 <li class="has-children">
                    <a class="">
                        <span class="icon is-small"><i class="fa fa-cog"></i></span> 
                        <span class="menu-text">Layouts</span>
                    </a>
                    <ul>
                        <li><a>Members</a></li>
                        {/* <li><a>Plugins</a></li>
                        <li><a>Add a member</a></li> }
                    </ul>
                </li> 
                        </ul>
                <p class="menu-label">Grafica General</p>
            <ul class="menu-list">
                <li>
                    <a class="is-active" href="/additional/">
                        <span class="icon is-small">
                        <i class="fa fa-bug"></i></span> 
                        <span class="menu-text">Promotor</span>
                    </a>
                </li>
                <li>
                    <a class="" href="/extras/">
                        <span class="icon is-small"><i class="fa fa-windows"></i></span>
                        <span class="menu-text">Extras</span>
                    </a>
                </li>
                </ul>
        </nav>
        <div id="sidebar-toggler">
            <span className="icon is-small">
                <i className="fa fa-angle-double-left">
                </i>
            </span>
        </div>
        </aside>
            );*/}