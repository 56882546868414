import React,{useState,useEffect} from 'react';
import MTablaMun from './MTablaMunicipios';
import MGrafica from './MGraficaTotal';
import MTablaPromov from './MTablaPromovidos';

const PanelIncidencias = ({datosJS,setActualizar,actualizar}) => {
    const[espera,setEspera]=useState(true);
    const [listaPromov,setListaPromov]=  useState([]);    
    //const [promvSeccion,setPromvSeccion]=  useState("");   
    const [promvSeccion,setPromvSeccion]=  useState(0);         
    //    let idusu =localStorage.getItem('UsuNum') ;
    //const [actualizarLista,setActualizarLista] = useState(0);     
      //console.log(datosJS);
      const contenido2 = listaPromov.length>0 &&promvSeccion ? <MTablaPromov valores={listaPromov} numSeccion={promvSeccion} espera={espera}/>:null
      const contenido =espera ? (contenido2 ):<div className="pt-2"><progress className="progress is-small is-sistema pt-2" max="100">15%</progress>Cargando...</div> 
    return (
        <div className=" is-flex is-flex-direction-column">
            
        
        <div className=" pl-2 is-flex is-flex-direction-row">            
        <MGrafica valor={datosJS} descripcion="Movilización" Auxtam={1} seccion={promvSeccion}/> 
             <MTablaMun datosJS={datosJS}  setListaXTipo={setListaPromov} setActualizar={setActualizar} actualizar={actualizar} setPromvSeccion={setPromvSeccion} setEspera={setEspera}/> 
             
             {contenido}
             
           {/*  <MGrafica valor={datosJS} descripcion="Grafica Incidencias" Auxtam={1}/> */}
            
        </div>
            
        </div>
    );
}
 
export default PanelIncidencias;