import React,{useState,useEffect} from 'react';
import Seccion from './Seccion';
import axios from 'axios';
import qs from 'qs';
import TabRuta  from './TablaSeccion';
import Cargando from '../layout/Cargando';
import { useHistory } from "react-router-dom";
//import sistemaHooks from '../hooks/sistema_hook';
const Rutas = () => {
    const [datos,setDatos] = useState("");
    let history = useHistory();
    const [error,setError] = useState("");
    let idruta =localStorage.getItem('RutaNum') ;
    let idcoord =localStorage.getItem('CoordNum') ;
    let idusu =localStorage.getItem('UsuNum') ;
    let mensaje="";
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
    
    useEffect(()=>{ 
       const source = axios.CancelToken.source(); 
       const conectarJSon = async () => {
       
       //let auxU=`http://`+process.env.REACT_APP_LINK +`/ruta`; 
       let auxU=process.env.REACT_APP_LINK +`/ruta`;     
       let token =localStorage.getItem('token20') ;
       idruta =localStorage.getItem('RutaNum') ;              
       let dataInfo = qs.stringify({
           'usuario': idruta,
           'idDispositivo': 'csdq21weddewde' 
           });
       let config = {
           url:auxU ,
           method: 'POST',
           headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
           data: dataInfo,
           cancelToken: source.token
       };
   
       let aux ={};
       await axios(config)
       .then(function (response) {
           //console.log('mostrando datos');  
         //  console.log(JSON.stringify(response.data));     
           aux=  response.data;
           if (aux.ruta)
            {            
                setDatos(aux.ruta);        
            }
       })
       .catch(function (error) {
           //console.log(`Error: ${JSON.stringify(error.response.data)} Codigo: ${error.status}`);
           if (error.response.data.error.name==="JsonWebTokenError" || error.response.data.error.name==="TokenExpiredError" ) 
            {   //console.log(error.response.data.error.name);
                console.log('loguearse de nuevo');
                localStorage.clear();
                //window.location.replace('');
                history.push("/");                
                window.location.reload(true);
                //console.log("seguir imprimiendo");                
            }
       });
       
       
           
   };
   if (nivelUsuario==='3'||nivelUsuario==='5'||nivelUsuario===''||nivelUsuario===' '||nivelUsuario===null) 
    {                  
        localStorage.clear();            
        history.push("/");                
        window.location.reload(true);      
    }
    else
    {
        if ( idcoord === null || idcoord === 0 ) 
        {   mensaje="el coordinador";
            if( idruta === "" || idruta === 0 || idruta === null )
            {
                mensaje=mensaje+" y la ruta";            
            }
            if(idusu === "" || idusu === 0||idusu === null)
            {
                console.log('loguearse de nuevo');
                localStorage.clear();
                //window.location.replace('');
                history.push("/");                
                window.location.reload(true);
            }
            else{
                setError(mensaje);
            }                    
        }
        else
        {   if( idruta === "" || idruta === 0  )
            {  // console.log("aiuda");
                mensaje=" la ruta";
                setError(mensaje);
            }
            else
            {               
                conectarJSon();                        
            }
        }        
    }
    return ()=> {
        source.cancel();
    }

   },[]);

       
   const cargando = <Cargando />;
   // const cargando = <div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div>;
    const auxmensaje = error ? (<h1 className="title is-l">Falta seleccionar {error}</h1>) : (cargando);
    const content = datos ? (<div><Seccion valor={datos[0]}/><br/> <TabRuta idVal={0} Jsval={datos[0]}/></div>) : (auxmensaje);
//const content = datos ? () : ()
    return (
        <div className="contenido-coordi">
       
            {content}
             
        </div>
       
        );
}
 
export default Rutas;