import React from 'react'
import { GoogleMap, LoadScript,Polyline, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "900px",
  width: "1200px"
};

const center = {
  lat: 19.591911,
  lng: -98.9458206
};
const marcador = [
  { lat:19.5918776, lng: -98.9458509}, 
  { lat:19.5919335, lng: -98.9461079}, 
  { lat:19.5920984, lng: -98.9459063}, 
  { lat:19.5920167, lng: -98.9460195}, 
  { lat:19.5920691, lng: -98.9460953}, 
  { lat:19.5919833, lng: -98.9462753}, 
  { lat:19.5919122, lng: -98.9460067}, 
  { lat:19.5919633, lng: -98.9462833}, 
  { lat:19.5921235, lng: -98.9460379}, 
  { lat:19.5920298, lng: -98.9459322}, 
  { lat:19.591911, lng: -98.9458206 }, 
  { lat:19.5920739, lng: -98.9459838 }, 
  { lat:19.5915548, lng: -98.9460917 }, 
  { lat:19.5921164, lng: -98.9459728 }, 
  { lat:19.591688, lng: -98.9458855 }, 
  { lat:19.591955, lng: -98.9448117 }, 
  { lat:19.5918784, lng: -98.9460083 }, 
];
const onLoad = polyline => {
  console.log('polyline: ', polyline)
};
const onLoadMarcador = marker => {
  console.log('marker: ', marker)
}
const path = [
  { lat:19.5918776, lng: -98.9458509}, 
  { lat:19.5919335, lng: -98.9461079}, 
  { lat:19.5920984, lng: -98.9459063}, 
  { lat:19.5920167, lng: -98.9460195}, 
  { lat:19.5920691, lng: -98.9460953}, 
  { lat:19.5919833, lng: -98.9462753}, 
  { lat:19.5919122, lng: -98.9460067}, 
  { lat:19.5919633, lng: -98.9462833}, 
  { lat:19.5921235, lng: -98.9460379}, 
  { lat:19.5920298, lng: -98.9459322}, 
  { lat:19.591911, lng: -98.9458206 }, 
  { lat:19.5920739, lng: -98.9459838 }, 
  { lat:19.5915548, lng: -98.9460917 }, 
  { lat:19.5921164, lng: -98.9459728 }, 
  { lat:19.591688, lng: -98.9458855 }, 
  { lat:19.591955, lng: -98.9448117 }, 
  { lat:19.5918784, lng: -98.9460083 }, 
];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  
};
function MyComponent() {
    return (
        <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" >
        <GoogleMap
            id="marker-example"
            mapContainerStyle={mapContainerStyle}
            zoom={14}
            center={center}>
             <div>
            {
                marcador.map( (registro, index) => {
                  return <Marker
                    onLoad={onLoadMarcador}
                    position={registro}
                    title={`Promotor ${index}`}
                    label={"Capturando"}/>
                })
              
            /*
                <Marker
                onLoad={onLoadMarcador}
                position={marcador[0]}
                title={`Promotor ${1}`}
                label={"Capturando"}/>
                
                */
            
                
                
            }
             </div>
            <Polyline
            onLoad={onLoad}
            path={path}
            options={options}
            />
        </GoogleMap>
        </LoadScript>
)
}
export default React.memo(MyComponent)