import React,{Component } from 'react';
//import ReactDOM from 'react-dom';
//import CardProm from './CardPromo';
import Select, { components } from 'react-select';
import CentroPromo from './CentroPromoClase';
//import qs from 'qs';
//import sistemaHooks from '../hooks/sistema_hook';
import ModalP from "./PromoLlamadaModal";
class CabeceraPromoClase extends Component {
    constructor(props){
        super(props)
        this.state={
            posts: [],
            isLoading: true,
            errors: null,
            registros: this.props.valores,
            Promotor: 0,
            infoPromotor: [],
            boton3:[],
            seccion:this.props.seccion,
        }
        this.contador=0
    }
    
    llenaPromo() {
        //console.log(`Cabecera Llena Select`);
        const { registros,seccion} = this.state;
        //console.log(seccion);
        let arrePromot=[];   
        registros.map((prom) => {
            arrePromot.push({ value : prom.PromotId, label :prom.SeccionNombre+" "+prom.PromotNombre })
        })
        this.setState({
            boton3: arrePromot,
            isLoading: false
          });
    }
    guardaPromotor(promotor) {
        const { registros } = this.state;
        
       // console.log(`Cabecera Asigna Promovido ${promotor}`);
        const resultados= registros.filter(  (registro) => registro.PromotId === promotor  )        
        localStorage.setItem('NomProm',''+resultados[0].PromotNombre);
        localStorage.setItem('IdProm',''+resultados[0].PromotId);
       // console.log(`Cabecera Filtro ${JSON.stringify(resultados)}`);
        this.setState({
            Promotor: promotor,
            infoPromotor: resultados,
            isLoading: false
          });
          /*this.contador ++
          console.log(`Cabecera Contador ${this.contador}`);
          if(this.contador >= 2){
            ReactDOM.render(<CentroPromo valProm={resultados[0]} idProm={promotor} />, document.getElementById('promotorInfo'));
          }*/
    }
    componentDidMount() {
        this.llenaPromo();
    }

    render() {
        const { isLoading, posts, boton3, infoPromotor, Promotor,seccion } = this.state;
        return (
          <React.Fragment>
            
              {!isLoading ? (
                <div>
                   <div className= "select-promotor">
                    <div className= "selTam-promo">
                        <Select placeholder="Selecciona un Promotor" 
                            styles={
                                {
                                    control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600' }),
                                    option: styles => ({ ...styles, fontSize: '10px' })
                                }
                            }
                            options={boton3}
                            onChange={opt =>  this.guardaPromotor(opt.value) }
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>                    
                    </div>
                    { Promotor  ? (<div className="pl-5" > <ModalP valProm={infoPromotor[0]} seccion={seccion}/> </div>) : (null)  }
                    
                    </div>
                    { Promotor  ? (<div id="promotorInfo">
                            { 
                                (<CentroPromo  valProm={infoPromotor[0]} idProm={Promotor} />)}
                        </div>) : null
                        
                    }
                    
                </div>) : 
                (<p>Cargando...</p> )
              }
            
          </React.Fragment>
        );
      }
}

export default CabeceraPromoClase;