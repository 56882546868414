import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';
//import BuscaColor from "../Color";
//import Chart from 'chart.js';

const IncGrafica = ({valor,descripcion,Auxtam}) => {  
  
    let colorTabla =[];
    const auxCoordi = valor;
    let contador=0; 
    let miLabel=[];
    let midata=[];
    let auxDis ="espacioGraf2";
        
    function ColorTab (elcolor) {
      let colorEfecti= '';/*
      if(elcolor<60){
          colorEfecti= '#FF0505E8';
      }
      else{
          if(elcolor>=60 && elcolor<80)
          {
              colorEfecti= '#F3A80B';               
          }
          else{
              if(elcolor>=80 )
              {
                  colorEfecti= '#1CC63D';
              }
          }
      }*/
      colorEfecti= '#F58A05';               
      return (  
            colorEfecti   
          );
    } 
    auxCoordi.forEach( coordi => {
      midata[contador]=coordi.Total;
      miLabel[contador]=coordi.TipoNom.length>19  ?coordi.TipoNom.substring(0,17)+"...":coordi.TipoNom;
      let auxiliar =coordi.Total;                        
      colorTabla[contador] =ColorTab(auxiliar);                    
      contador++; 
       
   });/*
   const imprime= auxCoordi.map((coordi) => {      
      
        midata[contador]=coordi.promovidos;
        miLabel[contador]=contador+1;
        let auxiliar =coordi.efectividad;                        
        colorTabla[contador] =ColorTab(auxiliar);                    
        contador++; 
         
    })*/
    contador = 0;
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
      },
      
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 5
        }
        
      },
      title:{
        fontStyle: 'bold',
      },
     scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },Labels :{              
              enabled:false
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            scaleLabel :{
              fontSize:6,
              
            },
          },
        ],
      },
    }  
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Incidencias',//
          backgroundColor: colorTabla,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: 'rgba(255,0,54,0.4)',
          hoverBorderColor: 'rgb(0,88,101)',
          data: midata 
        }
      ]
    };    
    if (Auxtam===1) {
      auxDis="espacioGraf";
    }

  return ( 
    <div className="card  "id="Incgrafica" >
      <div className=" marcoGraficaIncid" >
        <div className="content">
          {<h2 className="marcoTexto" id="ajustarGrafica">{descripcion}</h2>}
          <HorizontalBar
              id="graficaHorBarIncidencia"
              data={horizontalRuta}
              width={850}
              height={500}
              options={options}
            />                   
        </div>
      </div>
  </div>
   );
}

 
export default IncGrafica;