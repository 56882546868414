import React,{useState,useEffect} from 'react';
import Modal from './PromoModal';
import { ToastContainer, toast } from 'react-toastify';
//import paloma from '../ic_tezo_circulo.png';
import axios from 'axios';
import qs from 'qs';
const PromoAltaModal = ({prog,totales,promovId}) => {
    const [datos,setDatos] = useState("");
    const [classNameModal,setclassNameModal]=  useState(""); 
    const [desBtn,setdesBtn]=  useState(false);    
    const [valores,setValores]=  useState("");    
    const [tabla,setTabla]=  useState("");
    const [arregloProg,setArregloProg]=  useState([]);     
    let idusu =localStorage.getItem('UsuNum') ;
    //let arregloProg=[totales];
    useEffect(() => { 
        //console.log("entrando "+promovId);
        //console.log(prog);
        prog.map((programa,index) => {
            if (programa.programaStatus !== 0) {
                setArregloProg(prevItems => [...prevItems, {
                    //id: prevItems.length,
                    row:index,
                    valor: programa.programaId,        
                  }])        
            }
        })
        
     },[promovId]);

    const llamarApi=(cadProgram)=>{
        const source = axios.CancelToken.source();            
        async function conectarJSon()  {           
            //console.log("antes de llamar api");
            setdesBtn(true);
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-programa-set`;  
            let auxU=process.env.REACT_APP_LINK +`/promovido-programa-set`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'promovido': promovId , 
                'programa':cadProgram,               
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;
                //console.log("dentro de la llamada api");
             //console.log(`programas API: ${JSON.stringify(aux.total)}`)                  
                //console.log(aux);
                //console.log(aux.respuesta);
                if (aux.respuesta)
                {                                                                         
                   if (aux.respuesta>1) {
                    //console.log("entrando a la respuesta"); 
                    const mensaje="Se registro "+aux.respuesta +" programas";
                    toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalPrograma'} );   
                   }else{
                    if (aux.respuesta===1) {
                      //  console.log("entrando a la respuesta"); 
                        const mensaje="Se registro "+aux.respuesta +" programa";
                        toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalPrograma'});   
                       }         
                   }
                    // console.log(`programas API: ${JSON.stringify(aux)}`) 
                }
                else{
                    if (aux.respuesta==0) {
                        //console.log("entrando a 0"); 
                        const mensaje="Se quitaron todos los programas";
                        toast.info(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalPrograma'});   
                       }   
                   }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log("se un error");
                //console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            }); 
            
            setdesBtn(false);
        };
        conectarJSon();
    }
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    { 
        //toast.clearWaitingQueue({ containerId: "ModalPromovido" });
        //toast.dismiss();
        setclassNameModal("");
    }

    const onSubmit = e =>
	{
		e.preventDefault();
      //  console.log(`submit del registro: ${JSON.stringify(arregloProg)}`) 
        if (arregloProg[0]) {
            //console.log("no esta vacio");
            let aux='';
            let cadProgramas = arregloProg.map((programa) => {
                aux=aux+''+programa.valor+','
        //        console.log(programa.valor);            
            })            
            //console.log(aux);
            llamarApi(aux);
        }
        else{
          //  console.log("esta vacio");
            llamarApi(0);
        }		
	}
    const onChange = e =>
	{
		/*guardarUsuario({
			...usuario, //copiamos el usuario
			[e.target.name] : e.target.value  
		})*/
	};
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
        //setArregloProg([]);
    }
    const RenglonNum =(valorId,regis,posicion)=>{        
        
      //  console.log(`Antes del registro: ${JSON.stringify(arregloProg)}`)                 
        setTabla(valorId);        
        arregloProg.find(  (registro) => registro.valor === valorId  ) ?(
            setArregloProg(arregloProg.filter(item => item.valor !== valorId))        
          )
           : (
            setArregloProg(prevItems => [...prevItems, {
                //id: prevItems.length,
                row:posicion,
                valor: valorId,        
              }])        
            )         
        //console.log(`Despues del registro: ${JSON.stringify(arregloProg)}`)                  
     }
    function barrido(){
        let numRenglon=0;
        //prog.filter(item => item.programaStatus !== 0)
        //console.log(`Despues del registro: ${JSON.stringify(arregloProg)}`)         
        return(
        <div id="tamTablaProgramas">
        <table className="table is-sobrepuesto" id="">
            <thead >    
                <tr>
                    <th style={{padding: 0.2 +'rem '}}></th>
                    
                    <th style={{padding: 0.2 +'rem'}}></th>                              
                </tr>                    
            </thead>  
            <tbody>      
            { prog.map((programa,index) => {
                let auxNombre="";
                auxNombre=programa.programaNombre;    
                
                const auxval =  programa.programaStatus === 1 ?(true):(false);
                numRenglon++;
                const resultados= arregloProg.filter(  (registro) => registro.valor === programa.programaId  )                                  
                //console.log(arregloProg[numRenglon])
                return (                  
                    <tr className={ tabla === programa.programaId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(programa.programaId,programa,index) }  >                                
                        {/* <th className="tablaTR " style={{padding: 0.2 +'rem'}}>
                            <input class="is-checkradio" id="exampleCheckbox" type="checkbox" name={"check"+numRenglon} checked={  arregloProg.find(  (registro) => registro.valor === programa.programaId  )   ? "checked" : '' }/>
                        </th> */}
                        <th className="tablaTR " style={{padding: 0.2 +'rem', paddingTop: '0.45rem'}}>
                            <img src={  arregloProg.find(  (registro) => registro.valor === programa.programaId  )   ? ( tabla === programa.programaId ? "ic_palomaBlanco.svg" :"ic_paloma.svg" ) :( tabla === programa.programaId ? "ic_circuloBlanco.svg" :"ic_circulo.svg" )   }  alt="" border="0" height="20" width="20"/> 
                        </th>
                        <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear " style={{fontSize:.6+'rem',fontWeight: "bold"} }>{auxNombre}  </span></th>                                                                                                            
                    </tr>              
                );})                                  
            }                    
            </tbody>
        </table>
        </div>
        );

    }
    const llamada= promovId?( <div className="">{barrido()}</div>):(null)
    const botones = !desBtn ? (<div className="pt-2 pb-0"><span className="pr-4"><button className="button is-link " onClick={mostrarModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (<div><progress className="progress is-small is-link" max="100">15%</progress><input className="button is-info" value="Verificando..."/></div>)
    return (
        <div>
            <a 
                onClick={btnMostrar}
                className=" letraColorSecc has-text-weight-medium is-size-5">
                    Editar
            </a>
            <Modal 
                    id={"ModalPromovidoAltaPrograma"}
                    onClose={()=>esconderModal()}
                    className={classNameModal}
                    title={"Aportaciones del Promovido"} 
                    ancho={395}
            >
                <form className="" onSubmit={onSubmit}>                                                
                    <p className="help is-danger is-size-5 has-text-justified" id="promo_modal_mensaje"> </p>                                                                     
                    {/* <img src=".\ic_tezo_circulo.png" alt="" border="3" height="20" width="20"/>  */}
                           
                    {llamada}                                                                                                                                                                                                 
                    <div className="is-flex " >
                        {botones /**/}
                        <ToastContainer enableMultiContainer containerId={'ModalPrograma'}/>
                    </div>
                        
                </form>

            </Modal>

        </div>
      );
}
 
export default PromoAltaModal;