import React,{useState,useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Select, { components } from 'react-select';
import TablaPromovidos from './RepApoTablaPromovidos';
import Cargando from '../layout/Cargando2';
const ReporteApoyo = () => {
    const [apoyo,setApoyo]= useState(""); 
    const [datos,setDatos] = useState("");
    const [espera,setEspera] = useState(false);
    const [vacio,setVacio] = useState(false);
    let idusu =localStorage.getItem('UsuNum') ;
    const listaApoyo=[
        { value:1, label:'RG'}, 
        { value:2, label:'RC'}, 
        { value:3, label:'BARDA'},
        { value:4, label:'LONA'},
        { value:5, label:'REDES SOCIALES'}, 
        { value:6, label:'BRIGADAS VOLUNTARIAS'}, 
    ]
    
    const llamarApi=(apoyo)=>{
        const source = axios.CancelToken.source();     
        
        async function conectarJSon()  {         
           // setdesBtn(true); 
            setEspera(false);
            //console.log("antes de llamar api "+ promoto);
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-listaportacion`;      
            let auxU=process.env.REACT_APP_LINK +`/promovido-listaportacion`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                
                'programa':apoyo.value,  
                'usuario': idusu,              
                'idDispositivo': 'csdq21weddewde'                
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;
                if (aux.resultado) 
                {   if (aux.resultado[0].promovidoId===-1) {
                    setDatos(null);
                    setVacio(true);  
                }else
                {
                    setDatos(aux.resultado);
                    setVacio(false);  
                    console.log(apoyo); 
                    console.log(aux.resultado); 
                }
                }                
                
            })
            .catch(function (error) {                                                  
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            //setdesBtn(false);  
            setEspera(true);            
        };
        conectarJSon();
    }
    const guardaApoyo=(auxApoyo)=> {
        setDatos(null);
        const dato= listaApoyo.filter(  (registro) => registro.value === auxApoyo)                 
        setApoyo(dato[0]);
        llamarApi(dato[0]);        
    }
    const selectorApoyos=
    (<Select placeholder={"Apoyos"} 
        styles={
            {
                control: styles => ({ ...styles, fontSize: '12px', fontWeight: '600' ,width:'200px'}),
                option: styles => ({ ...styles, fontSize: '12px',width:'200px' }),
                menuList: styles => ({ ...styles,width:'200px' }),
                container: styles => ({ ...styles,width:'200px' })
            }
        }
        options={listaApoyo}
        onChange={opt =>  guardaApoyo(opt.value)}
        
        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
    );
    
    const sinDatos = !vacio? (<div className="pt-2 pl-2"><progress className="progress is-small is-sistema" max="100">15%</progress><h1 className="title is-l">Cargando...</h1></div> ):(<div className="pt-2 pl-2 is-size-4">Sin Apoyos</div>)
    const tabla= datos ? <TablaPromovidos apoyo={apoyo} datos={datos}/>:sinDatos 
    const contenido = apoyo ? tabla: null
    return (
        <div>            
            <div className="pl-2">{selectorApoyos}</div>
            {contenido}
        </div>  
        );
}
 
export default ReporteApoyo;