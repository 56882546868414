import React from 'react';
import {Doughnut, HorizontalBar} from 'react-chartjs-2';

const GraficaProm = ({valor}) => {
    let color1= '#3273DC';    
    let color2= '#FF0505E8';
    let color3= '#3273DC';
    let metfinalt2="";
    //let metfinalt="";
    //console.log(valor);
    let AvanProm ="";
    let AvanMeta ="";
    let EfecProm ="";
    let EfecMeta ="";
    
  if (valor !==0) {
     color3= valor.efectividad >=100 ?('#1CC63D'):('#3273DC');
     color2= '#FF0505E8';
     
     metfinalt2=valor.efectividad >=100? 0:(100-valor.efectividad).toFixed(2);
    const metfinalt=(100-valor.avance_global).toFixed(2);    
    //console.log(valor);
     AvanProm =valor.avance_global+"% "+ "Promovidos";
     AvanMeta =(metfinalt)+"% "+ " Meta final";
     EfecProm =valor.efectividad+"% "+ "Promovidos";
     EfecMeta =(metfinalt2)+"% "+ "Meta Actual";
    
  }
    
    const data = {
        labels: [
          AvanProm,
          AvanMeta
        ],
        datasets: [{
          data: [valor.avance_global, 100-valor.avance_global],
          backgroundColor: [
          color1,
          color2
          
          ],
          hoverBackgroundColor: [
            color1,
            color2
          ]
        }]
      }; 
      const grafica2 = {
        labels: [
          EfecProm,
          EfecMeta
        ],
        datasets: [{
          data: [valor.efectividad, metfinalt2],
          backgroundColor: [
            color3,
            color2
          
          ],
          hoverBackgroundColor: [
            color1,
            color2
          ]
        }]
      };

      function  llenadoTabla(datos,nombre)
      {
        return(
            <div className="card  "id="grafica" >
              <div className=" marcoGrafica" >
                <div className="content">
                {<h2 className="marcoTexto" id="ajustarGrafica">{nombre}</h2>}
                  <Doughnut
                  data={datos}
                  width={250}
                  height={250}  
                  />
                  </div>
              </div>
            
        </div>
        );
      }
    return (
        <div className="">      
            <div className="card-content ">
                <div className="content">
                  <div  className="columns ">      
                    <div className="column">
                        {llenadoTabla(data,'Avance')}
                    </div>
                    <div className="column">
                        {llenadoTabla(grafica2,'Efectividad')}
                    </div>
                </div>
                </div>
        </div>
        </div>
      );
}
 
export default GraficaProm;