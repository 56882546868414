import React,{useState,useEffect} from 'react';
const MapaConsultar = () => {
    
    useEffect(()=>{         
        console.log("llamada a rastrear promotores");                           
    },[]);
    return (
        <div>
            Rastreo de promotores
            consulta de promovidos
        </div>
    );
}
 
export default MapaConsultar;