import React,{useState} from 'react';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
const RPTablaPromovidos = ({valores,nombrePromot}) => {
   // console.log({valores});
    let datos=valores;    
    let auxArreglo=[]//["#","INE","Promovido","Dirección","Nacimiento"]
    const [tabla2,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [arreExcel,setArreExcel]=useState(["#","INE","Promovido","Dirección","Nacimiento"])
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Promovidos de "+nombrePromot +".xlsx")
    };
    function filtro(){        
        let auxCadena=nombre.trim();  
        auxArreglo=[]                  
        let info=datos.filter((dat)=>dat.promovNombreFull.search(auxCadena.toUpperCase() ) != -1)         
        return TablaPromo(info);
    }
    function obtenerDireccion(promovido)
    {   let direccion="";
        direccion= promovido.promovCalle ? (promovido.promovCalle) : (direccion);
        direccion= promovido.promovExt ? (direccion+" "+promovido.promovExt) : (direccion);
        direccion= promovido.promovInt ? (direccion+" "+promovido.promovInt) : (direccion);
        direccion= promovido.promovColonia ? (direccion+", "+promovido.promovColonia) : (direccion);
        return direccion;
    }
    function TablaPromo(datos) {
        let numRenglon=0;
        let auxi2=["#","INE","Promovido","Dirección","Teléfono","Nacimiento"];
        auxArreglo.push(auxi2);
        let tot= Object.keys(datos).length;
        if(tot===0){
            let auxi=["","Sin","Promovidos"]
            auxArreglo.push(auxi);
        }   
        return(
            <div id="tamTablaPromovidosReportes">
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>
                            <th style={{padding: 0.2 +'rem'}}>#</th>               
                            <th style={{padding: 0.2 +'rem'}}>INE</th>               
                            <th style={{padding: 0.2 +'rem'}}>Promovido</th>
                            <th style={{padding: 0.2 +'rem'}}>Dirección</th>
                            <th style={{padding: 0.2 +'rem'}}>Nacimiento</th>
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {datos.map((promotor,index) => {
                            let auxNombre="";
                            //auxNombre = promotor.promovPaterno+" "+promotor.promovMaterno+" "+promotor.promovNombre ;
                            auxNombre = promotor.promovNombreFull;
                            let auxDireccion = obtenerDireccion(promotor);
                            //console.log(auxNombre);
                            let auxi=[index+1,promotor.promovCve,auxNombre,auxDireccion,promotor.promovTelefono,promotor.promovNacimiento.substring(0,10)]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla2 === promotor.promovId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promotor.promovId,promotor) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{numRenglon}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promotor.promovCve}  </span></th>                                                    
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxDireccion}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promotor.promovNacimiento.substring(0,10)}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
    

	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {let expNopermitida = new RegExp('[0-9._:$!%-({})/*?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
        guardarPromovido({
            ...promovido, //copiamos el nombre
            [e.target.name] : e.target.value  
        })
    }
        /*guardarPromovido({
            ...promovido, //copiamos el nombre
            [e.target.name] : e.target.value  
        })*/
    };

    const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= valores?(contenido):(<div><h2>No contiene datos</h2></div>)
    const btnExportar = <span data-tip="Exportar Promovidos"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>
    return (
     
        <div className="contenido-tbPromov pl-2">
            <div className="tile is-ancestor ">
                <div className="tile is-4 is-horizontal is-parent">
                    <div className="tile is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal">            
                                <div className="field-body">
                                    <input
                                        className="input" 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar promovido"
                                        value={nombre}
                                        onChange={onChange}/>
                                </div>
                                {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        );
}
 
export default RPTablaPromovidos;