import React,{useState} from 'react';
import Modal from '../Promos/PromoModal';
const MapaModalDatosGen = ({datGen,mostrar,setMensaje}) => {
    
    const [classNameModal,setclassNameModal]=  useState(mostrar);     
   
    let foto =datGen.promFoto;
    let nombre=datGen.promNombre;
    let calle=datGen.promCalle;
    let colonia=datGen.promColonia;
    let NoExt=datGen.promNoExt;
    let NoInt= datGen.promNoInt;
    //let auxNum="NoExt: "+NoExt+ "     NoInt: " +NoInt;
    let CodPost=datGen.promCP;
    let telefono=datGen.promTel ;
    let celular=datGen.promCel;
    let email=datGen.promEmail;
    let seccion =datGen.promSeccion;
    let ine =datGen.promINE;
    let prefe = datGen.promPreferencia;
    let participacion= datGen.promParticipaRGRC;
    let idPromovido=datGen.promId ;
    //console.log(idPromovido);
    //console.log(participacion);
    function auxFoto(){
        return (<div id="card-img-izq ">        
        <article className="tile is-child ">                    
            <figure className="image is-4by3 ">
                <img src={foto}/>                        
            </figure>
        </article>
        <div id="card-img-der">                                
                       
        </div>                                                                                                                                                            
    </div>)
    }
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    { 
        //toast.clearWaitingQueue({ containerId: "ModalPromovido" });
        //toast.dismiss();
        setMensaje("");
        setclassNameModal("");
    }


    
    let cambiaPartici=participacion===0 ?('No'):('Si');
    let auxParti =(participacion ===0 ||participacion===1 )? (cambiaPartici):(null);
    let auxNumInt =(NoInt !==''&&NoInt!==null&&NoInt!==" " )? ("   NoInt: "+NoInt):("");
    let numeros = (NoExt !==''&&NoExt!==null&&NoExt!==" " )? ("NoExt: "+NoExt +auxNumInt):(null);


     
    const fotografia = (foto) ? (auxFoto()): (<div className="box is-1"><h1 className="has-text-centered">Sin fotografia</h1></div>);    
  
    
    return (
        <div>
      
        <Modal 
                    id={"ModalDatosGenerales"}
                    onClose={()=>esconderModal()}
                    className={mostrar}
                    title={"Datos Generales"} 
                    ancho={350}
            >
            <div className="tile pt-2">
                <div className="tile is-vertical">
                    {fotografia}
                    <article className="tile is-child box  ">
                    <p className="tamLetra2" style={{ fontSize:1.2+'rem', fontWeight:500}}> <span className="letraColorSecc" >{seccion}</span> <span >{ine}</span></p>                                                                                
                            <p className="tamLetra2"  style={{ fontWeight:700}}>{nombre}</p>                            
                            {prefe ? (<p className="tamLetra2">Preferencia política <span className="letraColorSecc" >{prefe}</span> </p>):(null)} 
                            {auxParti ? (<p className="tamLetra2">Participacion RG / RC <span className="letraColorSecc" >{cambiaPartici}</span> </p>):(null)} 
                            <p className="tamLetra2">Colonia: {colonia}</p>
                            <p className="tamLetra2">Calle: {calle}</p>                           
                            {numeros ? (<p className="tamLetra2">{numeros} </p>):(null)} 
                            <p className="tamLetra2">Codigo Postal: {CodPost}</p>                            
                            {(telefono!==''&&telefono!==null&&telefono!==" " )? (<p className="tamLetra2">Telefono: {telefono} </p>):(null)} 
                            {(celular !==''&&celular!==null&&celular!==" " )? (<p className="tamLetra2">Celular: {celular} </p>):(null)} 
                            {(email !==''&&email!==null&&email!==" ") ? (<p className="tamLetra2">Correo: {email} </p>):(null)}                           
                                            
                    </article>
                </div>      
            </div>    
  
    </Modal>
    </div>
);
}
 
export default MapaModalDatosGen;