import React,{useState,useEffect} from 'react';
import Modal from '../Promos/PromoModal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import Select, { components } from 'react-select';
const NuevaIncidenciaModal = ({mostrar,setMensaje}) => {
    let idusu =localStorage.getItem('UsuNum') ;
    //const [listaSecciones,setListaSecciones]=  useState([]);  
    const [listaSeccion,setListaSeccion]=  useState([]);
    const [listaTipo,setListaTipo]=  useState([]);    
    const [listaCasillas,setListaCasillas]=  useState([]);    
    const [classNameModal,setclassNameModal]=  useState(mostrar);         
    const [nuevaIncidencia,setNuevaIncidencia] = useState({ Tipo:0,Usuario:0,Casilla:0,Descripcion:"",Nota:""});
    const [listaSecc,setListaSecc]=  useState([]);    
    const[espera,setEspera]=useState(true);
    const[espera2,setEspera2]=useState(true);
    const [seccion,setSeccion]=  useState("");
    const[registros,setRegistros]=useState(true);
    //const[casillaSelecc,setCasillaSelecc]=useState("");
    let auxSecciones=[];
    let auxTipos=[];
    let auxcasillas=[];
    useEffect(()=>{                         
        
        
        const source = axios.CancelToken.source();     
        async function conectarJSon()  {         

            setEspera(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/incidencia-web-list-secc`;     
            let auxU=process.env.REACT_APP_LINK +`/incidencia-web-list-secc`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                           
                'idDispositivo': 'I9UC8SIDNJKS'                 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                  
                if (aux)
                {                    
                    auxSecciones=aux;     
                    let arreSecc=[];                
                    let variable=0;
                   // console.log("despues del llenado secciones");
                    auxSecciones.map((seccion) => {
                        if (variable!==seccion.SeccionId)
                        {
                            variable=seccion.SeccionId;                        
                            arreSecc.push({ value : variable, label :variable })                                               
                        }                                        
                    })
                    setListaSecc(aux);
                    setListaSeccion(arreSecc);
                }                                                          
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log("se un error");
                console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            setEspera(true);            
        };
        
        

        async function llamadaTipo()  {         
            setEspera2(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/incidencia-movil-tipo`;     
            let auxU=process.env.REACT_APP_LINK +`/incidencia-movil-tipo`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                           
                'idDispositivo': 'I9UC8SIDNJKS'                 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                  
                if (aux)
                {
                    auxTipos=aux;                        
                    let auxArreTipos=[];                        
                    //console.log("despues del llenado tipo");
                    auxTipos.map((tipo) =>
                    {                                    
                        auxArreTipos.push({ value : tipo.Id, label : tipo.AreaNom+" - "+tipo.Nombre })                                                                                                   
                    })
                    setListaTipo(auxArreTipos);                             
                }              
                            
            })
            .catch(function (error) {                            
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });            
            setEspera2(true);            
        };
        llamadaTipo();
        conectarJSon();
                
    },[]);
    
    
    
    const { Tipo ,Usuario , Casilla , Descripcion , Nota } = nuevaIncidencia;        

    
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    {         
        setMensaje("");
        setclassNameModal("");
    }

    
    const llamarApi=()=>{
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {                    ; 
            setEspera(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/incidencia-web-nuevo`;    
            let auxU=process.env.REACT_APP_LINK +`/incidencia-web-nuevo`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                "Tipo":Tipo,
                "Usuario":idusu,
                "Casilla":Casilla,
                "Descripcion":Descripcion,
                "Nota":Nota===""? " ":Nota,
                "Otracausa":(Tipo===5 || Tipo===7 ?(Descripcion) :(" ")), 
                "idDispositivo":"d9s8chujcdsadda",          
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            setRegistros(true)
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux.respuesta===1) 
                   {                   
                        const mensaje="Incidencia registrada"//aux.mensaje ;            
                        toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaIncidencia'} );   
                        setNuevaIncidencia({
                            //...nuevaIncidencia,              
                            //Tipo:dato[0].value
                            Tipo:0,Usuario:0,Casilla:0,Descripcion:"",Nota:""
                        })
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });              
            setEspera(true);            
        };
        conectarJSon();
    }
    const onSubmit = e =>
	{      
		e.preventDefault();
        if (seccion!=="")
        {
            if (Casilla!==0) 
            {   let auxDato=listaCasillas.filter(  (registro) => registro.value === Casilla  )
                if (auxDato.length===1) 
                {
                    if (Tipo!==0 ) 
                    {   setRegistros(false);
                        if(Descripcion.trim().length>=10)
                        {   
                            llamarApi();             
                        }
                        else
                        {   setRegistros(true);         
                            toast.warning("Descripción debe estar llenado", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaIncidencia'} );   
                        }    
                    }else 
                    {
                        toast.warning("Debe seleccionar el tipo", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaIncidencia'} );      
                    }    
                }else 
                {
                    toast.warning("Debe seleccionar la nueva casilla", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaIncidencia'} );      
                }
                
                
            }else
            {
                toast.warning("Debe seleccionar la casilla", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaIncidencia'} );   
            }    
        }else 
        {
            toast.warning("Debe seleccionar la sección", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaIncidencia'} );   
        }
        
        		
	}
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
       
    }
    const guardaCasilla=(casillas) =>
    {   const resultados= listaCasillas.filter(  (registro) => registro.value === casillas  )             
        //setCasillaSelecc( resultados);                  
        setNuevaIncidencia({
            ...nuevaIncidencia, //copiamos el usuario
            //[e.target.name] : e.target.value  
            Casilla:resultados[0].value
        })
    }
    
    
    const guardaTipos=(auxTipos)=>
    {        
        const dato= listaTipo.filter(  (registro) => registro.value === auxTipos )                          
        setNuevaIncidencia({
            ...nuevaIncidencia,              
            Tipo:dato[0].value
        })
    }

    const guardaSeccion=(auxseccion)=>
    {   console.log(auxseccion);                                          
        //setSeccion(false)
        const dato= listaSecc.filter(  (registro) => registro.SeccionId === auxseccion )                          
        let arreCasilla=[];                      
        dato.map((seccion) => 
        {            
            arreCasilla.push({ value : seccion.CasillaId, label : seccion.TipoNom })      
            //console.log(seccion.CasillaId+" "+seccion.TipoNom);                                                                                             
        })
        setListaCasillas(arreCasilla);
        setSeccion(auxseccion)                       
    }

    const selectorTipo=
                    (<Select placeholder={"Tipo de Incidencia"} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '12px', fontWeight: '600' ,width:'500px'}),
                                option: styles => ({ ...styles, fontSize: '12px',width:'500px',height:'25px' }),
                                menuList: styles => ({ ...styles,width:'500px' }),
                                container: styles => ({ ...styles,width:'500px',height:'20px' })
                            }
                        }
                        options={listaTipo}
                        onChange={opt =>  guardaTipos(opt.value) }
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    );
    const selectorCasilla =seccion?
                    (<Select placeholder={"Casilla"} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600',height:'12px' ,minHeight:'30px',  }),
                                option: styles => ({ ...styles, fontSize: '12px'}),
                                menuList: styles => ({ ...styles,width:'100px' }),
                                container: styles => ({ ...styles,width:'100px' })
                            }
                        }
                        options={listaCasillas}
                        onChange={opt =>  guardaCasilla(opt.value) }
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    ):null;
    const selectorSeccion=(<Select placeholder={seccion?seccion:"Sección"} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600',height:'12px' ,minHeight:'30px',  }),
                                option: styles => ({ ...styles, fontSize: '12px'}),
                                menuList: styles => ({ ...styles,width:'100px' }),
                                container: styles => ({ ...styles,width:'100px' })
                            }
                        }
                        options={listaSeccion}
                        onChange={opt =>  guardaSeccion(opt.value)}
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    );                    
    const onChange = (e) => {        
        let { target } = e;                
        //let expPalabraspermitida = new RegExp('[0-9._:$!%-({})"+/*|]');
        let expCadenaGeneral = new RegExp('[_:$!%-({})+/*"|&]');        
        //let expNumero = new RegExp('[\bA-Za-z.,_:$!%-({})/"*+|]');                
        let expmenos=   new RegExp('-');
        if(target.name === "Descripcion"||target.name === "Nota")
        {
            if (!expCadenaGeneral.test(target.value)&&!expmenos.test(target.value))
            {   
                setNuevaIncidencia({
                    ...nuevaIncidencia, //copiamos la incidencia
                    [e.target.name] : e.target.value  
                })    
            }
        }
        
    }    
    
    
    
    const botones = espera&&espera2 ? (<div className="pt-2 pb-0"><span className="pr-4"><button className="button is-aceptar " onClick={mostrarModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>);

    function llenarTodosSelects()
    {        
        
        return(
            <div> 
                { registros?(<div> <div className="field  ">   
                    <div className="columns	">                                                                                                                    
                        <div className="pb-3 pl-3">
                            {selectorSeccion}                        
                        </div>
                        <div className="pb-3 pl-2">
                            { selectorCasilla}                        
                        </div>                               
                    </div>              
                </div>
                
                <div className="pb-4 pt-2" >
                    { selectorTipo}                        
                </div>     </div>):null                                                              }
                <article className="pt-5 pb-2 ">  
                    
                    <span  >Descripción</span>
                    <textarea className="textarea"onChange={onChange} type="text" placeholder="Escriba una descripción" value={Descripcion} name="Descripcion" maxLength="500"></textarea>                                     
                    
                    <span  className="is-size-7">*Maximo 500 caracteres</span>
                </article>                                    
                <article className="pb-1 pt-4  ">                    
                    <span  >Nota</span>
                    <textarea className="textarea"onChange={onChange} type="text" placeholder="" value={Nota} name="Nota" maxLength="500"></textarea>                               
                    <span  className="is-size-7">*Maximo 500 caracteres</span>
                </article> 
               {/*  <div className="pb-2 ">
                    Descripcion
                    <input className="input is-small"  onChange={onChange} type="text" placeholder="Descripcion" value={Descripcion} name="Descripcion" maxLength="500"/>                    
                </div>
                <div className="pb-2 ">
                    Nota
                    <input className="input  is-small " onChange={onChange} type="text" placeholder="Nota" value={Nota} name="Nota" maxLength="500"/>              
                </div>           */}                     
                  
                
                
                    
            </div>
        )

    };
    const auxiliarFormulario = listaSeccion&&listaTipo  ? ( llenarTodosSelects()  ): ( null ) 
    const auxPrincipal= espera2 ?(auxiliarFormulario ):null;
    return (
        <div>
             <a 
                onClick={btnMostrar}
                className=" button is-sistema">
                    Nueva Incidencia
            </a>
        <Modal 
            id={"ModalDatosGenerales"}
            onClose={()=>esconderModal()}
            className={classNameModal}
            title={"Nueva Incidencia"} 
            ancho={550}
            >
            <form className="" onSubmit={onSubmit}>  
                {auxiliarFormulario}
                
{/*                 <div className="tile pt-2">
                    <div className="tile is-vertical">
                                
                    </div>      
                </div>     */}
                <div className="">
                    {botones }
                </div>
                
                <ToastContainer enableMultiContainer containerId={'ModalNvaIncidencia'}/>    
            </form>     
    </Modal>
    </div>
);
}
 
export default NuevaIncidenciaModal;