import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';

const RPAGraficas = ({valor}) => {
    let color1= '#3273DC';
    let color2= '#FF0505E8';
    let apoyo1 =0;
    
   
    apoyo1=valor.promoOpcion1; 
    let auxTotal=valor.promovidos;
 
  if (valor !== 0) {
     color1= '#3273DC';
     color2= '#FF0505E8';   
  }
  /*const dataGrafica1 = {
        labels: ['Apoyos'],
        datasets: [
          {
            label: 'RG',
            data: [apoyo1],
            backgroundColor: color1,
          },          
          {
            label: 'Promovidos',
            data: [auxTotal],
            backgroundColor: color2,
          },
        ],
      }*/
 
  const creaGrafica =(apoyo,nombre)=>{

    let auxApoyo=apoyo!==0 ? (apoyo*100/auxTotal).toFixed(2):0;
    let auxPromov= apoyo!==0 ? (100-auxApoyo).toFixed(2):100;
    let dataGrafica =[];
    //console.log(auxApoyo);
    //console.log(auxPromov);
    return(  dataGrafica ={
        labels: [nombre],
        datasets: [
          {
            label: nombre,
            data: [auxApoyo],
            backgroundColor: color1,
          },          
          {
            label: 'Promovidos',
            data: [auxPromov],
            backgroundColor: color2,
          },
        ],
      })
  }


      function  llenadoTabla(datos,nombre)
      { const grafica= creaGrafica(datos,nombre);
        return(
            <div className="px-0 mx-0 "id="RPApoGrafica" >
              <div className="" >
                <div className="content">
                {<span id="ajustarGrafica" style={{fontSize: '.8rem' }} >{ nombre+' '+datos+' / '+'Promovidos '+auxTotal}</span>}
                  <HorizontalBar
                  data={grafica}
                  width={250}
                  height={80}  
                  options={options}
                  />
                  </div>
              </div>
            
        </div>
        );
      }
      const options = {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 5
          }
        },
        title:{
          fontStyle: 'bold',
        },
       scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              scaleLabel :{
                fontSize:6,
              },
            },
          ],
        },
      }
     //const contenido=llenadoTabla(' /  '+valor.+' /  '+valor.+' /  '+valor.)
    return (
        <div className="pb-4">      
            
            <div  className="columns ">      
                    <div className="column pr-0 mx-0">
                    {llenadoTabla(apoyo1,'RG')}
                    </div>
                    <div className="column pl-0 pr-0 mx-0">
                    {llenadoTabla(valor.promoOpcion2,'RC')}
                    </div>
                    <div className="column pl-0 pr-0 mx-0">
                    {llenadoTabla(valor.promoOpcion3,'BARDA')}
                    </div>
                    <div className="column  pl-0 pr-0 mx-0">
                    {llenadoTabla(valor.promoOpcion4,'LONA')}
                    </div>
                    <div className="column  pl-0 pr-0 mx-0">
                    {llenadoTabla(valor.promoOpcion5,'REDES')}
                    </div>
                    <div className="column pl-0 mx-0">
                    {llenadoTabla(valor.promoOpcion6,'BRIGADA')}
                    </div>
                </div>
          
        </div>
      );
}
 
export default RPAGraficas;