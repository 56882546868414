import React,{useState} from 'react';
import DatosGenerales from './PromoDatosGeneral';
import {LimpiarCadena} from '../Libreria/LibreriaClases';
const PromovidoTabla = ({datosJS}) => {
    let datos=datosJS;
    const [tabla2,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const RenglonNum =(valorId,regis)=>{
       // console.log(valorId);
        setTabla(valorId);
        setRegistro(regis);
    }

    function filtro(){        
        let auxCadena=nombre.trim();         
        let info=datos.filter((dat)=>dat.promNombre.search(auxCadena.toUpperCase() ) != -1)         
        return TablaPromo(info);
    }

    function TablaPromo(datos) {
        let numRenglon=0;
        return(
            <div id="tamTablaPromomtores">
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} id="mitabla2">
                    <thead >    
                        <tr>            
                            <th style={{padding: 0.2 +'rem'}}>Promovidos</th>            
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {datos.map((promovid) => {
                            let auxNombre="";
                            auxNombre=promovid.promNombre;    
                            //console.log(auxNombre);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla2 === promovid.promId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promovid.promId,promovid) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }

    

	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   
       /* let cadena= e.target.value;
        console.log(cadena);
        let resultado= LimpiarCadena(cadena);
        console.log(resultado);
        guardarPromovido({
            ...promovido, //copiamos el nombre
            [e.target.name] :resultado  
        })*/
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };

    const datGeneral = datosJS&&registro? (<DatosGenerales datGen={registro}/>):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    return (
        <div className="contenido-tbPromov py-1">
            <div className="tile is-ancestor is-4">
                <div className="tile is-horizontal is-parent">
                    <div className="tile is-child box">                        
                        <div className="container">
                            <div className="field">            
                                <div className="control">
                                    <input
                                        className="input" 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Nombre del promovido"
                                        value={nombre}
                                        onChange={onChange}/>
                                </div>
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>
            <div  className="">
                    {datGeneral}
            </div>
        </div>
        
      );
}
 
export default PromovidoTabla;