import React,{useState,useEffect,Fragment} from 'react';
import Modal from '../Promos/PromoModal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
const MapaModalRegistro = ({arrProm,setArregloProm,promDir,promGPS,setPromDir,setDatos,datos,setEsperando}) => {
    const [classNameModal,setclassNameModal]=  useState(""); 
    //const [datos,setDatos] = useState("");
    const [auxCambio,setAuxCambio]=  useState(false);    
    //const [arregloProg,setArregloProg]=  useState([]);      
    const [autocomplete, setAutocomplete] = useState([]);
    const[espera,setEspera]=useState(true);
    let idusu =localStorage.getItem('UsuNum') ;    
    useEffect(()=>{    
        let auxdatos=[];
//        DOLORES VIRIDIANA DE JESUS FLORES
/*AARON ARTURO CASTAÑEDA QUIROZ
 ABEL CRUZ QUINTERO
 HONORINA ESTEFES PELCASTRE
JONATHAN GEOVANNY ALBERTO CRUZ
*/

    },[]);
      const llamarApi=(promov,latitud,longitud)=>{
        const source = axios.CancelToken.source();            
        async function conectarJSon()  {                     
            setEspera(false);
            console.log("antes de llamar api");
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-listxubicacion-set`;     
            let auxU=process.env.REACT_APP_LINK +`/promovido-listxubicacion-set`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'promovidos':promov, 
                'latitud':latitud,   
                'longitud':longitud,   
                'ubicacion':promDir,             
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;           
                console.log(aux);
                if (aux)
                { 
                   if (aux.resultado>1) {
                    console.log("entrando a la respuesta"); 
                    const mensaje="Se registro "+aux.mensaje ;
                  //  console.log("se actualizo");
                    toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalDireccion'} );   
                   }else{
                    if (aux.resultado===1) {
                        console.log("entrando a la respuesta"); 
                        const mensaje="Se registro "+aux.mensaje ;
                        toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalDireccion'});   
                       }         
                   }
                    // console.log(`programas API: ${JSON.stringify(aux)}`) 
                }
                else{
                    if (aux.resultado===0) {
                        console.log(aux.respuesta);
                        //const mensaje="Se quitaron todos los programas";
                       // toast.info(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalDireccion'});   
                       }   
                   }                                
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            
                    
            setEspera(true);
            setAuxCambio(true);    
            setEsperando(true);        
        };
        conectarJSon();
    }
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    function limpiar() {
                
        let auxarreglo=datos;
        arrProm.map((promovido) => 
        {
            
            if ( auxarreglo.find(  (registro) => registro.promId === promovido.valor  ) ) 
            {
                auxarreglo= auxarreglo.filter(item => item.promId !== promovido.valor)    
            //    console.log(promovido.valor );
            }
            
        })
        //console.log(auxarreglo.length);
        //console.log(auxarreglo);
        setDatos(auxarreglo);
        setArregloProm([]);
        setPromDir("");
        //setAutocomplete([])
    }
    const esconderModal =()=>
    { 
        //toast.clearWaitingQueue({ containerId: "ModalPromovido" });
        //toast.dismiss();
        setclassNameModal("");
        if (auxCambio) 
        {
            limpiar();
        }
       
    }
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
        //setArregloProg([]);
    }
    const onSubmit = e =>
	{
		e.preventDefault();
        if (arrProm[0]) {
            //console.log("no esta vacio");
            let aux='';
            let cadProgramas = arrProm.map((promovido) => {
                aux=aux+''+promovido.valor+','
           
            })            
            console.log("id promovidos: "+aux);          
            let latitud=promGPS.lat();
            console.log(latitud);
            let longitud=promGPS.lng();
            console.log(longitud);
            setEsperando(false);            
            llamarApi(aux,latitud,longitud);
        }
        else{
           console.log("esta vacio");
             //llamarApi(0);
        }		
	}
    function TablaPromo(datos) {
        let numRenglon=0;
        return(
            <div >
                <table >
                    <thead >    
                        <tr>                                               
                            <h3 className="has-text-weight-semibold" style={{padding: 0.2 +'rem'}}>Promovidos {datos.length}</h3>            
                        </tr>                        
                    </thead>  
                    <tbody >          
                        {datos.map((promovido) => {
                            let auxNombre="";
                            auxNombre=promovido.nombre;    
                            numRenglon++;                          
                        return (                  
                            <tr   key={numRenglon}   >                                                                
                                <span  style={{padding: 0.2 +'rem'}} className="alinear is-size-7 has-text-weight-medium">{auxNombre}  </span>
                                                                                 
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
    }
    
    
    const tabla= arrProm?( <div className="">{TablaPromo(arrProm)}</div>):(null)
    //const llamada= espera&&idValor?( <div className="">{TablaPromo()}</div>):(<div><progress className="progress is-small is-sistema" max="100">15%</progress>Cargando...</div>)
    const botones = espera ? (<div className="pt-2 pb-0"><span className="pr-4"><button className="button is-aceptar " onClick={mostrarModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>);
    
    return (
        <Fragment>
        <button 
            onClick={()=>mostrarModal()}
            className="mi-button is-sistema ">
                Guardar Asignación
        </button> 
        <Modal 
            id={"ModalPromovidoAltaDireccion"}
            onClose={()=>esconderModal()}
            className={classNameModal}
            title={"Asignación de dirección"} 
            ancho={395}
        >            
          
           <form className="" onSubmit={onSubmit}>
           
                
               <h2 className="has-text-weight-bold has-text-centered">¿Desea asignar los promovidos seleccionados a la ubicacion?</h2>
               <div className="card">
               <div className="card-content pb-3">
                    <div className="pb-0">                    
                    <p className="has-text-centered">{promDir}</p>                                                                                                                                                                                     
                    </div>
                </div>
                </div>
                    {tabla}                                                                                                                                                                                                 
                    <div className="is-flex " >
                        {botones /**/}
                        <ToastContainer enableMultiContainer containerId={'ModalDireccion'}/>
                    </div>
                        
                </form>
       
                        
    </Modal>
    </Fragment>
      );
}
 
export default MapaModalRegistro;