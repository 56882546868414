import React,{useState,useEffect} from 'react';
import TablaPromotor from './RPATablaPromotor';
import Graficas from './RPAGraficas';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
const ReportePromovidoApoyo = () => {
    const [datos,setDatos] = useState("");
    const [datosGrafica,setDatosGrafica] = useState("");
    let history = useHistory();
  
    const [vacio,setVacio] = useState(true);    
    let idusu =localStorage.getItem('UsuNum') ;
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
    //let idPromot =localStorage.getItem('idPromot') ;
    let mensaje="";
    
    //const [secciones,setSecciones] = useState("");  
    useEffect(()=>{ 
        const source = axios.CancelToken.source();
        async function conectarJSon()  {

       
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/reporte-promovido-apoyo`;   
            let auxU=process.env.REACT_APP_LINK +`/reporte-promovido-apoyo`;   
            let token =localStorage.getItem('token20') ;          
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'id':0,
                'tipo':'Promotor',
                'idDispositivo': 'csdq21weddewde'             
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux =[];           
            await axios(config)
            .then(function (response) {                                                                
                aux=  response.data;
               //console.log(aux);
                if (aux) {                                                            
                    setDatos(aux);
                    
                }else{
                    setDatos("");
                    setVacio(false)
                }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`) 
                //console.log("contador es : "+contador+" "+error);                    
                //contador++;
                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    //console.log("seguir imprimiendo");                
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                    setVacio(false)  
                }
            });                            
        };
        async function TotalApoyos()  {

       
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/reporte-promovido-apoyo`; 
            let auxU=process.env.REACT_APP_LINK +`/reporte-promovido-apoyo`;              
            let token =localStorage.getItem('token20') ;         
            let dataInfo = qs.stringify({
                'usuario':idusu,
                'id': 0,
                'tipo':'Total',
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {                
                aux=  response.data;
                //console.log(aux);                           
                if (aux) {                                                              
                    setDatosGrafica(aux);
                    //console.log(aux);
                }else{
                    setDatosGrafica("")
                    setVacio(false)
                }
                                            
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)                 
                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    //console.log("seguir imprimiendo");                
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                    setVacio(false)  
                }
            });                            
        };
       
        
        if (nivelUsuario!=='3'&&nivelUsuario!=='5'&&nivelUsuario!==''&&nivelUsuario!==' ') 
        {                  
            TotalApoyos();
            conectarJSon();      
        }
        else
        {   
            localStorage.clear();            
            history.push("/");                
            window.location.reload(true);
        } 
        return ()=> {
            source.cancel();
        }
            
        

},[]);

   
    const carga =datos&&datosGrafica ? (<div><Graficas valor={datosGrafica[0]}/><TablaPromotor datosJS={datos} totalPromov={datosGrafica[0].promovidos}  /> </div>):null;
   
    const content =datos&&datosGrafica ? (<div >{ carga}     </div> ) : ( <Cargando />);

    return (<div className="is-flex-direction-row ">
            
                {content}                      
        </div> 
         );
}
 
export default ReportePromovidoApoyo;