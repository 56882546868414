import React,{useState} from 'react';
import PanelPromovido from './BusqPanelPromovido';
const BusqTablaUnPromotor = ({datosJS,seccion,lista,validoPromotor}) => {
    //let datos=datosJS;
    let datos=datosJS;
    const [tabla,setTabla]=  useState("");          
    const [registro,setRegistro]=useState("");
    const[infoPromot,setinfoPromot]=useState("");
    const RenglonNum =(valorId,regis)=>{
     //  console.log(valorId);
        setTabla(valorId);
        let info=datos.filter((dat)=>dat.PromotId=== valorId) ;
        //console.log(info[0].PromotNombre);
        localStorage.setItem('NomProm',''+info[0].PromotNombre);
        setRegistro(regis);
        setinfoPromot(info[0]);
    }

        function TablaPromo(datos) {
            let numRenglon=0;
            
            return(
                <div id="tamTablaUnPromotorBusqueda">
                    <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                        <thead >    
                            <tr>                                            
                                <th style={{padding: 0.2 +'rem'}}>Promotor</th>            
                            </tr>                        
                        </thead>  
                        <tbody>
                            {datos.map((promotor) => {
                                let auxNombre="";
                                auxNombre=promotor.PromotNombre;    
                                //console.log(auxNombre);
                                numRenglon++;                          
                            return (                  
                                <tr className={ tabla === promotor.PromotId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promotor.PromotId,promotor) }  >                                
                                    <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>                                                    
                                </tr>              
                                );
                            })
                            }                                                                                                                        
                        </tbody>
                    </table>
                </div>
            );
            }
    

	
    

    const datGeneral = datosJS&&registro? (<PanelPromovido promId={tabla} secciones={seccion} infoPromot={infoPromot} listaPromot={lista} validoPromotor={validoPromotor}/>):(null)    
    const llamada= datosJS?(TablaPromo(datos)):(<div><h2>No contiene datos</h2></div>)
    return (
    <div className="contenido-tbPromov pl-3">
        <div className="tile is-ancestor is-3">
            <div className="tile is-horizontal is-parent">
                <div className=" is-child box">                        
                    <div className="container">                        		            
                        {llamada}
                    </div>
                </div>
                {datGeneral}
            </div>            
        </div>
       
    </div>
  );
}
 
 
export default BusqTablaUnPromotor;