import React,{useState} from 'react';
import ModalP from "../Promos/PromoLlamadaModal";
import ModalDatGen from './BusqDatosGeneralModal';
import ModalPrograma from './BusqProgramasModal';
import ReactTooltip from "react-tooltip";
import XLSX from 'xlsx';
import moment from 'moment';
const BusqTablaPromovidos = ({valores,seccion,listaPromot,infoPromot,setActualizar,actualizar,validoPromotor}) => {
    //let datos=valores;    
    let datos=valores    
    let auxArreglo=[]//["#","INE","Promovido","Dirección","Nacimiento"]
    const [tabla,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [mensaje,setMensaje]=useState("");
    const [mensaje2,setMensaje2]=useState("");
    const [arreExcel,setArreExcel]=useState(["#","INE","Promovido","Dirección","Nacimiento"])
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Promovidos de "+infoPromot.PromotNombre +".xlsx")
    };
    const llamdaModal=()=>{
        setMensaje("is-active")
    }
    const llamdaModal2=()=>{
        setMensaje2("is-active")
    }
    function filtro(){        
        let auxCadena=nombre.trim();    
        auxArreglo=[]               
        let info=datos.filter((dat)=>dat.promNombre.search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info);
    }
    function obtenerDireccion(promovido)
    {   let direccion="";
        direccion= promovido.promCalle ? (promovido.promCalle) : (direccion);
        direccion= promovido.promNoExt ? (direccion+" "+promovido.promNoExt) : (direccion);
        direccion= promovido.promNoInt ? (direccion+" "+promovido.promNoInt) : (direccion);
        direccion= promovido.promColonia ? (direccion+", "+promovido.promColonia) : (direccion);
        return direccion;
    }
    function TablaPromo(datos) {
        let numRenglon=0;
        let tot= Object.keys(datos).length;
        let auxi2=["#","Sección","INE","Nombre","Preferencia","Tel/Cel","Dirección"];
        auxArreglo.push(auxi2);
       // console.log(tot);
       if(tot===0){
            let auxi3=["","Sin","Promovidos"]
            auxArreglo.push(auxi3);            
        }   
        return(
            <div id="tamTablaPromovidosBusqueda">
                <ReactTooltip place="left" type="success" effect="solid" />
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>
                            <th style={{padding: 0.2 +'rem'}}>{tot}</th>
                            <th style={{padding: 0.2 +'rem'}}>Prog</th>
                            <th style={{padding: 0.2 +'rem'}}>Det</th>               
                            <th style={{padding: 0.2 +'rem'}}>Sección</th> 
                            <th style={{padding: 0.2 +'rem'}}>INE</th>      
                            <th style={{padding: 0.2 +'rem'}}>Nombre</th>               
                            <th style={{padding: 0.2 +'rem'}}>Preferencia</th>                                                        
                            <th style={{padding: 0.2 +'rem'}}>Tel/Cel</th>
                            <th style={{padding: 0.2 +'rem'}}>Dirección</th>   
                        </tr>
                                                
                    </thead>  
                    <tbody>          
                        {datos.map((promo,index) => {
                            let auxNombre="";
                            //auxNombre = promotor.promovPaterno+" "+promotor.promovMaterno+" "+promotor.promovNombre ;
                           // console.log(promo);
                            auxNombre = promo.promNombre+" "+promo.promPaterno+" "+promo.promMaterno;
                            let auxDireccion = obtenerDireccion(promo);
                            //console.log(auxNombre);
                            let auxTelefono="";
                            if (promo.promTel !==""||promo.promTel !==null) {
                                auxTelefono=promo.promTel;

                            }else{
                                if (promo.promCel !==""||promo.promCel !==null) {
                                    auxTelefono=promo.promCel;
                                }
                            }
                            let auxi=[index+1,promo.promSeccion,promo.promINE ,auxNombre,promo.promPreferencia,auxTelefono,auxDireccion]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === promo.promId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promo.promId,promo) }  >
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{numRenglon}  </span></th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>     
                                    <span data-tip="Aportaciones del Promovido">
                                        <a className="" onClick={llamdaModal}> <img src={tabla === promo.promId ? "archivoBlanco.svg" : "archivo.svg"}   alt="" border="0" height="20" width="20"/> </a> 
                                    </span>
                                </th>
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span data-tip="Información del Promovido">
                                        <a className="" onClick={llamdaModal2}> <img src={tabla === promo.promId ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                    </span>                              
                                </th>                                                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promSeccion}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promINE}  </span></th>                                                    
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promPreferencia}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxTelefono}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxDireccion}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }

    
	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[0-9._:$!%-({})/*?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
        
    };
    //
    const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Promovidos"><a className="pt-2 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  className="pt-2 " alt="" border="0" height="30" width="30"/> 
                        </a></span>
    const btnExportar = <span data-tip="Exportar Promovidos"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>                        
    const btnNuevoRegistro=<div className="pl-5"><ModalP valProm={infoPromot} seccion={seccion}/></div>         
   const modal2= mensaje2? (<ModalDatGen datGen={registro} secciones={seccion} mostrar={mensaje2} setMensaje={setMensaje2} listaPromot={listaPromot} infoPromot={infoPromot} validoPromotor={validoPromotor}/>):(null);      
   const modal1= mensaje? (<ModalPrograma idValor={tabla} mostrar={mensaje} setMensaje={setMensaje}/>):(null);        
   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= valores?(contenido):(<div><h2>No contiene datos</h2></div>)        
    return (       
        <div className="contenido-tbPromov pl-2 pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal ">            
                                <div className=" field-body  ">
                                    <input
                                        className="input " 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar promovido"
                                        value={nombre}
                                        onChange={onChange}/>
                                      
                                
                                </div>
                                {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                {btnRecargar}
                                {btnNuevoRegistro}
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>    
            {modal2}
            {modal1}
        </div>
           
     
    );
}
 
export default BusqTablaPromovidos;