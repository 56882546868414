import React,{useState,useEffect} from 'react';
import Cabecera from './RPCabecera'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
import {Facebook} from "react-content-loader"
const ReportePromotr = () => {
    const [datos,setDatos] = useState("");
    let history = useHistory();
    const [error,setError] = useState("");
    const [vacio,setVacio] = useState(true);
    let idruta =localStorage.getItem('RutaNum') ;
    let idcoord =localStorage.getItem('CoordNum') ;
    let idusu =localStorage.getItem('UsuNum') ;
    let mensaje="";
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
    useEffect(()=>{ 
        
        const conectarJSon = async () => {
                                
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/reporte-seccion`;   
            let auxU=process.env.REACT_APP_LINK +`/reporte-seccion`;                  
            let token =localStorage.getItem('token20') ;                 
            let dataInfo = qs.stringify({
                'usuario': idusu,                  
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                   
                // console.log("unallamada");
                if (aux.secciones) 
                {   
                    setDatos(aux.secciones);
                }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`) 
                //console.log("mando error");
                //console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {
                    //console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    //console.log("seguir imprimiendo");                
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    //console.log('sin datos' );
                    setVacio(false)  
                }
            });                                 
        };
       
        if (nivelUsuario!=='3'&&nivelUsuario!=='5'&&nivelUsuario!==''&&nivelUsuario!==' ') 
        {                  
            conectarJSon();      
        }
        else
        {   
            localStorage.clear();            
            history.push("/");                
            window.location.reload(true);
        } 
        
},[]);
    //const cargando = vacio ? (<div> <Cargando /> <Facebook/></div>):(<h1 className="title is-l has-text-centered">Ruta sin datos</h1>);
    //const auxmensaje = error ? (<h1 className="title is-l has-text-centered">Falta seleccionar {error}</h1>) : (cargando);
    const auxmensaje=<div> <Cargando /> <Facebook/></div>;
    const content = datos ? (<div ><Cabecera valores= {datos}/>   </div> ) : (auxmensaje);

    return (
        <div className= "cabeza-promotor">
             {content}
        </div>
    
      );
}
 
export default ReportePromotr;