import React,{useState,useEffect} from 'react';
import Modal from '../Promos/PromoModal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
const BusqProgramasModal = ({idValor,mostrar,setMensaje}) => {
    const [classNameModal,setclassNameModal]=  useState(""); 
    const [datos,setDatos] = useState("");
    const [desBtn,setdesBtn]=  useState(false);
    const [tabla,setTabla]=  useState("");
    const [arregloProg,setArregloProg]=  useState([]);      
    const[espera,setEspera]=useState(false);
    let idusu =localStorage.getItem('UsuNum') ;
    //console.log("cargando valor: "+idValor);
    useEffect(()=>{ 
        //  console.log("dentro del effect de la tabla");
        let auxdatos=[];
        //console.log("cargando");
        const guardaRegistrados=()=>{
              
            auxdatos.map((programa,index) => {
                if (programa.programaStatus !== 0) {
                    //console.log(programa.programaStatus);
                    setArregloProg(prevItems => [...prevItems, {
                        //id: prevItems.length,
                        row:index,
                        valor: programa.programaId,        
                      }])        
                }
            })
          }
          const source = axios.CancelToken.source();
          async function conectarJSon()  {           
              setEspera(false);
              //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-programa`;      
              let auxU=process.env.REACT_APP_LINK +`/promovido-programa`;               
              let token =localStorage.getItem('token20') ;                
              let dataInfo = qs.stringify({
                  'usuario': idusu,
                  'promovido': idValor ,                
                  'idDispositivo': 'csdq21weddewde' 
                  });
              let config = {
                  url:auxU ,
                  method: 'POST',
                  headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                  data: dataInfo,
                  cancelToken: source.token
              };
              let aux ={};
             // console.log("entrando api");
              await axios(config)
              .then(function (response) {
                  aux=  response.data;
               
               //console.log(`programas API: ${JSON.stringify(aux.total)}`)                  
               //   console.log(aux[0]);
                  if (aux.total)
                  {  // console.log("arreglo: "+aux[0]);                                
                      setDatos(aux.total);
                      auxdatos=aux.total;
                      //console.log(auxdatos);
                     // console.log(`programas API: ${JSON.stringify(aux)}`) 
                  }else{

                  }
                              
                  //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
              })
              .catch(function (error) {
                //  console.log("se tuvo un error");
                  //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
  //                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                  /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                  {                  
                  }
                  if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                  {
                      console.log('sin datos' );
                   
                  }*/
              });
              
              guardaRegistrados();            
              setEspera(true);    
          };
          conectarJSon();
          
         
      },[idValor,desBtn]);
      const llamarApi=(cadProgram)=>{
        const source = axios.CancelToken.source();            
        async function conectarJSon()  {         
            setdesBtn(true); 
            setEspera(false);
            console.log("antes de llamar api");
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-programa-set`;          
            let auxU=process.env.REACT_APP_LINK +`/promovido-programa-set`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'promovido': idValor , 
                'programa':cadProgram,               
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;
               // console.log("dentro de la llamada api");
             //console.log(`programas API: ${JSON.stringify(aux.total)}`)                  
                //console.log(aux);
                
                if (aux.respuesta)
                { //console.log(aux.respuesta);                                                                        
                   if (aux.respuesta>1) {
                    //console.log("entrando a la respuesta"); 
                    const mensaje="Se registro "+aux.respuesta +" programas";
                  //  console.log("se actualizo");
                    toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalPrograma2'} );   
                   }else{
                    if (aux.respuesta===1) {
                      //  console.log("entrando a la respuesta"); 
                        const mensaje="Se registro "+aux.respuesta +" programa";
                        toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalPrograma2'});   
                       }         
                   }
                    // console.log(`programas API: ${JSON.stringify(aux)}`) 
                }
                else{
                    if (aux.respuesta===0) {
                        //console.log("entrando a 0"); 
                        const mensaje="Se quitaron todos los programas";
                        toast.info(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalPrograma2'});   
                       }   
                   }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log("se un error");
                //console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            setdesBtn(false);  
            setEspera(true);            
        };
        conectarJSon();
    }
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    { 
        //toast.clearWaitingQueue({ containerId: "ModalPromovido" });
        //toast.dismiss();
        setclassNameModal("");
        setMensaje("");
    }
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
        //setArregloProg([]);
    }
    const onSubmit = e =>
	{
		e.preventDefault();
      //  console.log(`submit del registro: ${JSON.stringify(arregloProg)}`) 
        if (arregloProg[0]) {
            //console.log("no esta vacio");
            let aux='';
            let cadProgramas = arregloProg.map((programa) => {
                aux=aux+''+programa.valor+','
        //        console.log(programa.valor);            
            })            
            //console.log(aux);
            llamarApi(aux);
        }
        else{
          //  console.log("esta vacio");
             llamarApi(0);
        }		
	}
    const RenglonNum =(valorId,regis,posicion)=>{        
        
          //console.log(`Antes del registro: ${JSON.stringify(arregloProg)}`)                 
          setTabla(valorId);        
          arregloProg.find(  (registro) => registro.valor === valorId  ) ?(
              setArregloProg(arregloProg.filter(item => item.valor !== valorId))        
            )
             : (
              setArregloProg(prevItems => [...prevItems, {
                  //id: prevItems.length,
                  row:posicion,
                  valor: valorId,        
                }])        
              )         
          //console.log(`Despues del registro: ${JSON.stringify(arregloProg)}`)                  
       }
    function barrido(){
        let numRenglon=0;
        //prog.filter(item => item.programaStatus !== 0)
        //console.log(`Despues del registro: ${JSON.stringify(arregloProg)}`)         
        return(
        <div id="tamTablaProgramas">
        <table className="table is-sobrepuesto" id="">
            <thead >    
                <tr>
                    <th style={{padding: 0.2 +'rem '}}></th>
                    
                    <th style={{padding: 0.2 +'rem'}}></th>                              
                </tr>                    
            </thead>  
            <tbody>      
            { datos.map((programa,index) => {
                let auxNombre="";
                auxNombre=programa.programaNombre;    
                
                const auxval =  programa.programaStatus === 1 ?(true):(false);
                numRenglon++;
                const resultados= arregloProg.filter(  (registro) => registro.valor === programa.programaId  )                                  
                //console.log(arregloProg[numRenglon])
                return (                  
                    <tr className={ tabla === programa.programaId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(programa.programaId,programa,index) }  >                                
                        
                        <th className="tablaTR " style={{padding: 0.2 +'rem', paddingTop: '0.45rem'}}>
                            <img src={  arregloProg.find(  (registro) => registro.valor === programa.programaId  )   ?( tabla === programa.programaId ? "ic_palomaBlanco.svg" :"ic_paloma.svg" ) : ( tabla === programa.programaId ? "ic_circuloBlanco.svg" :"ic_circulo.svg" ) }  alt="" border="0" height="20" width="20"/> 
                        </th>
                        <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear " style={{fontSize:.6+'rem',fontWeight: "bold"} }>{auxNombre}  </span></th>                                                                                                            
                    </tr>              
                );})                                  
            }                    
            </tbody>
        </table>
        </div>
        );

    }

    const llamada= espera&&idValor?( <div className="">{barrido()}</div>):(<div><progress className="progress is-small is-sistema" max="100">15%</progress>Cargando...</div>)
    const botones = !desBtn&&espera ? (<div className="pt-2 pb-0"><span className="pr-4"><button className="button is-aceptar " onClick={mostrarModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (null);
    
    return (
        <div>
        
        <Modal 
            id={"ModalPromovidoAltaPrograma2"}
            onClose={()=>esconderModal()}
            className={mostrar}
            title={"Aportaciones del Promovido"} 
            ancho={395}
        >            
           <h1>
           <form className="" onSubmit={onSubmit}>                                                                                                                                                                                     
                    {llamada}                                                                                                                                                                                                 
                    <div className="is-flex " >
                        {botones /**/}
                        <ToastContainer enableMultiContainer containerId={'ModalPrograma2'}/>
                    </div>
                        
                </form>
           </h1>
                        
    </Modal>
    </div>
      );
}
 
export default BusqProgramasModal;