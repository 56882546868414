import React, { useState }  from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import Login from './Componentes/auth/Login';
//import Coordinador from './Componentes/coordinadores/coordPrincipal';
import Presidencia from './Componentes/coordinadores/Presidencia';
import CoorDetalle from'./Componentes/coordinadores/CoordinadorDetalle';
import Layout from './Componentes/layout/Layout';
//import ValidaT from './Componentes/auth/ValidaT';
import RutaCoord from './Componentes/coordinadores/Rutas';
//import ReportePromovido from './Componentes/coordinadores/ReportePromovido';
import 'bulma/css/bulma.min.css';
import Promotores from "./Componentes/Promos/Promotores";
import ReportePromotor from "./Componentes/reporte-promotor/ReportPromotor"
import GMaps from "./Componentes/Promos/maps";
import ExampleData from "./Componentes/Promos/maps-example";
import GmapPoly from "./Componentes/Promos/maps-polyline";
//import ProbaTabla from './Componentes/Promos/PromoTablaInsert';
import Sinretorno from './Componentes/page/nada';
import BCargando from './Componentes/Pedientes/BarraCargando';
import Busqueda from './Componentes/Busqueda/BusquedaInicio'
import BusquedaUsuario from './Componentes/Busqueda/Busqueda'
import ReportePromovidoApoyo from './Componentes/Reporte-promovido-apoyo/ReportePromovidoApoyo';
import MapaConEntrada from "./Componentes/Mapas/autocomplete";
//import MapaInicio from './Componentes/Mapas/MapaInicio2'
import MapaInicio from './Componentes/Mapas/Mapa'
import ReporteApoyo from './Componentes/Reporte-apoyos/ReporteApoyo';
import Incidencias from './Componentes/Incidencias/IncidenciasInicio';
import Prep from './Componentes/Prep/PrepInicio';
import Movilizacion from './Componentes/Movilizacion/MovilizacionInicio';
function App() {
  const [valAppTab, setAppTab] = useState('Login');
  const [valAppMenu, setAppMenu] = useState('Menu');
  const { useRef } = React;
  const childRef = useRef();
  const onValueAppTabChanged = (tabSelect,menuSelect) => {
    setAppTab(tabSelect);
    console.log(`Asigna valor App[onValueAppTabChanged] TAB[${tabSelect}] MENU[${menuSelect}] `)
    childRef.current.onTabChanged(tabSelect,menuSelect)
  }
  const onMenuAppTabChanged = (tabSelect,menuSelect) => {
    setAppMenu(menuSelect);
    console.log(`Asigna valor App[onMenuAppTabChanged] TAB[${tabSelect}] MENU[${menuSelect}] `)
    childRef.current.onTabChanged(tabSelect,menuSelect)
  }
  return (
    <Router>
      <Layout onValueAppTabChanged={onValueAppTabChanged} onMenuAppTabChanged={onMenuAppTabChanged} ref={childRef}>
        <Switch>
          <Route exact path="/" component={ (props) => <Login onValueAppTabChanged={onValueAppTabChanged} {...props} />} />
          <Route exact path="/inicio" component={ (props) => <Presidencia onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/coordinadores" component={ (props) => <CoorDetalle onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/rutas" component={ (props) => <RutaCoord onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/promotores" component={ (props) => <Promotores onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/reporte-promotor" component={ (props) => <ReportePromotor onValueAppTabChanged={onValueAppTabChanged} onMenuAppTabChanged={onMenuAppTabChanged} {...props} />}/>          
         {/*  <Route exact path="/mapa" component={ (props) => <GMaps onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/mapa-example" component={ (props) => <ExampleData onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/mapa-poli" component={ (props) => <GmapPoly onValueAppTabChanged={onValueAppTabChanged} {...props} />}/> */}
          <Route exact path="/BCargando" component={ (props) => <BCargando onValueAppTabChanged={onValueAppTabChanged} {...props} />}/> 
          <Route exact path="/busqueda" component={ (props) => <Busqueda onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>   
          <Route exact path="/promotor-busqueda" component={ (props) => <BusquedaUsuario onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>   
          <Route exact path="/reporte-promovido-apoyo" component={ (props) => <ReportePromovidoApoyo onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>   
        {/*   <Route exact path="/mapa-findplace" component={ (props) => <MapFindPlace onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
           */}
           <Route exact path="/mapa-seleccion" component={ (props) => <MapaConEntrada onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/mapa" component={ (props) => <MapaInicio onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/reporte-apoyo" component={ (props) => <ReporteApoyo onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/incidencia" component={ (props) => <Incidencias onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/prep" component={ (props) => <Prep onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route exact path="/movilizacion" component={ (props) => <Movilizacion onValueAppTabChanged={onValueAppTabChanged} {...props} />}/>
          <Route path="*" component={ (props) => <Sinretorno />} />
        </Switch>
      </Layout>        
    </Router>
  );
}
export default App;
