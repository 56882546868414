import React,{useState,useEffect, Component, useLocation} from 'react';
import { GoogleMap, LoadScript,Polyline, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
    height: "900px",
    width: "1200px"
  };
  

const Sinretorno = () => {
    const [result, setResult] = useState({});
    const [gps, setGps] = useState({});
    let center = {
        lat: 0.0,
        lng: -0.0
      };
      let marcador = { lat:0.0, lng: 0.0};
      const onLoadMarcador = marker => {
       // console.log('ubicacion: ', marker.position)
      }
    const GEOIP_REQUEST_URL = 'https://extreme-ip-lookup.com/json/';
    function useGeoIp () {
        useEffect(() => {
          localStorage.clear();
          fetch(GEOIP_REQUEST_URL)
            .then( res => res.json())
            .then(response => {
                setResult(response)
                setGps({lat:response.lat, lng:response.lon})
                marcador.lat=response.lat
                marcador.lng= response.lon
                //console.log(` GPS[${JSON.stringify(gps)}]`)
            })
            .catch((e) => {
              setResult({ error: e })
            })
        }, []);
        return result;
      }
      useGeoIp ()
      /*result ? (<LoadScript googleMapsApiKey="" >
                                    <GoogleMap id="ubicacion" mapContainerStyle={mapContainerStyle} zoom={14} center={gps}>
                                        <div>{ <Marker onLoad={onLoadMarcador} position={gps} title={`Hijo de la chingada`}/> } </div>
                                    </GoogleMap>
                                    </LoadScript>) : */
      const contenido = (<div><progress className="progress is-small is-link" max="100">15%</progress> </div>)
      const contenidoTitulo = result.query ? (result.query+" GP S["+result.lat+","+result.lon+"]") : ("")
      //const extra = window.open('https://es.memedroid.com/memes/tag/sistema');
                  
      //console.log(` antes return GPS[${JSON.stringify(gps)}]`)
      //let location = useLocation();
        return(
            
            <div id={"HIJODETUPUTAMADRE"} className="modal is-active">
              <div className="modal-background" />
              <div className="modal-card " style={{width:800}}  >
                <header className="modal-card-head">
                  <p className="modal-card-title help is-link" style={{fontSize:1.10+'rem'}, {fontWeight: 'bold'}}>{contenidoTitulo}</p>
                  <button className="delete" />
                </header>
                <section className="modal-card-body  " style={{width:800}}>
                  {
                      contenido
                }
                  <div>
                     <img src="memelas.jpeg" alt=""></img>
                    </div>
                 
                </section>
              </div>
            </div>
          );
}




export default Sinretorno;