import React,{Component} from 'react';
import CardProm from './CardPromo';
import PromovidoLista from './Promovido-lista';
//import DatGenPromov from './DatGenPromov';
import axios from 'axios';
import qs from 'qs';

class CentroPromoClase extends Component {
    constructor(props){
        super(props)
        this.state={
            isLoading: true,
            errors: false,
            mostrarCard: false
        }
        this.promovidos= []
        this.registros=[]
        this.auxiliarPromot=0
        this.PromotID=0
       // console.log(`Centro INICIA Valores`)
    }
    llamarApi = async (ID, registroInfo) => {
        this.registros= registroInfo
       // console.log(`Centro API INICIA: ${ID}`)
        //let auxU=`http://`+process.env.REACT_APP_LINK +`/reporte-promovido`;
        let auxU=process.env.REACT_APP_LINK +`/reporte-promovido`;
       // console.log("el id: " +ID);
        let token =localStorage.getItem('token20') ;
        let dataInfo = qs.stringify({ 'usuario': ID, 'idDispositivo': 'csdq21weddewde' });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo
        };
    
        const response= await axios(config)
        if (!response.data.mensaje ) {
            //console.log(`Centro API[${ID}] Respuesta[${response.data.length}]`)
            this.promovidos= response.data
            this.setState({
                isLoading: false,
                mostrarCard:true
              });
        } else {
            this.setState({
                errors: true,
                isLoading: true,
                mostrarCard:true
              });
          //  console.log(`Centro API ERROR Respuesta[${ID}]: ${JSON.stringify(response.data)}`)
        }
        this.auxiliarPromot= ID
    }
    componentWillMount(){
        this.PromotID=this.props.idProm
        this.llamarApi(this.props.idProm, this.props.valProm);
    }
    componentWillReceiveProps(props){
        this.PromotID=props.idProm
       // console.log(`componentWillReceiveProps`)
        this.setState({ isLoading: false, mostrarCard:false });
        this.llamarApi(props.idProm, props.valProm);
      }
 
    render() {
        let { isLoading, errors, mostrarCard } = this.state;
       // console.log(`RENDER`)
        return (
          <React.Fragment>
            <div>{ mostrarCard && this.registros ? (<div ><CardProm Promotor={this.registros}/></div>) : null }
              {!isLoading ? ( <div className="cent-promot"> { (<PromovidoLista Promotor={this.promovidos}/>) } </div>) : 
                            ( errors ? (<div><h1 className="title is-l">Sin Registros.</h1></div> ) : 
                                (<div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div> ))
              }
            </div>
          </React.Fragment>
        );
      }
}
 
export default CentroPromoClase;