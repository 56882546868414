import React,{useState,useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import TablaPromovidos from './TablaPromovido';
import {Facebook} from "react-content-loader";
const MapaInicio2  = () => {
  const [datos,setDatos] = useState("");
  const [lista,setLista] = useState("");
  const [actualizar,setActualizar] = useState(0);
  
    let history = useHistory();
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
    let id =localStorage.getItem('UsuNum') ;    
    let token =localStorage.getItem('token20') ;
  useEffect(()=>{ 
   
    const conectarJSon = async () => {
   // console.log("entrando a la llamada");
    //let auxU=`http://`+process.env.REACT_APP_LINK +`/seccion-poligono`;            
    let auxU=process.env.REACT_APP_LINK +`/seccion-poligono`;            
    let dataInfo = qs.stringify({
        'usuario': id,
        'idDispositivo': 'csdq21weddewde' 
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo
    };

    let aux ={};
    await axios(config)
    .then(function (response) {
        //console.log('mostrando datos');  
        //console.log(JSON.stringify(response.data));     
        aux =  response.data;
        if (aux.resultado) 
        {   //console.log(aux.resultado);
            //const datos =  await aux;      
             setDatos(aux.resultado);    
        }
    })
    .catch(function (error) {
        console.log(`Error: ${error.response.data.mensaje} Codigo: ${error.response.data.error.name}`);
        if (error.response.data.error.name==="JsonWebTokenError" ||error.response.data.error.name==="TokenExpiredError") 
        {  // console.log(error.response.data.error.name);
            //console.log('loguearse de nuevo');
            localStorage.clear();
            //window.location.replace('');
            history.push("/");                
            window.location.reload(true);
            //console.log("seguir imprimiendo");                
        }
        else{
            if (error.response.data.mensaje==="No se agrego el Token en los Headers de la petición.") {
                console.log('loguearse de nuevo');
              //  localStorage.clear();
                //window.location.replace('');
                history.push("/");                
                window.location.reload(true);
            }
        }
    });    
    }
    const listaPromovi = async () => {
      // console.log("entrando a la llamada");
       //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-listxubicacion`;      
       let auxU=process.env.REACT_APP_LINK +`/promovido-listxubicacion`;                  
       let dataInfo = qs.stringify({
           'usuario': id,
           'idDispositivo': 'csdq21weddewde' 
           });
       let config = {
           url:auxU ,
           method: 'POST',
           headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
           data: dataInfo
       };
   
       let aux ={};
       await axios(config)
       .then(function (response) {
           //console.log('mostrando datos');  
           //console.log(JSON.stringify(response.data));     
           aux =  response.data;
           if (aux.resultado) 
           {   //console.log(aux.resultado);
               //const datos =  await aux;      
               setLista(aux.resultado);    
           }
       })
       .catch(function (error) {
           console.log(`Error: ${error.response.data.mensaje} Codigo: ${error.response.data.error.name}`);
           if (error.response.data.error.name==="JsonWebTokenError" ||error.response.data.error.name==="TokenExpiredError") 
           {  // console.log(error.response.data.error.name);
               //console.log('loguearse de nuevo');
               localStorage.clear();
               //window.location.replace('');
               history.push("/");                
               window.location.reload(true);
               //console.log("seguir imprimiendo");                
           }
           else
           {
              if (error.response.data.mensaje==="No se agrego el Token en los Headers de la petición.") {
                console.log('loguearse de nuevo');
                localStorage.clear();                 
                history.push("/");                
                window.location.reload(true);
              }
           }
       });    
       }
    if (nivelUsuario!=='3'&&nivelUsuario!=='5'&&nivelUsuario!==''&&nivelUsuario!==' ') 
    {  setLista(null)
        conectarJSon();   
        
      listaPromovi();   
      //console.log(actualizar);
    }
    else
    {   localStorage.clear();            
        history.push("/");                
        window.location.reload(true);
    }
    console.log("llamada al inicio editar");
},[actualizar]);
  const contenido = datos&&lista?(<div ><TablaPromovidos poligonos={datos} datosJS={lista} setActualizar={setActualizar} actualizar={actualizar}/></div> ):(<Facebook/>);
  return ( 
    <div className="    ">
       
        { contenido
        }
    </div>  
   
  );
}
 


export default MapaInicio2;