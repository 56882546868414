import React,{useState,useEffect} from 'react';
import TablaVotosGeneral from './PTablaVotosGeneral';
import TablaXSecciones from './PTablaXSeccion';
import PGraficaMunicipio from './PGraficaMunicipio'
import PGraficaSeccion from './PGraficaSeccion';
import MiCard from './PCardTotal';
//import IncGrafica from './IncGrafica';
//import IncTablaDetalle from './IncTablaDetalles';
import axios from 'axios';
import qs from 'qs';
const PanelIncidencias = ({datosJS,setActualizar,actualizar}) => {
    //const[espera,setEspera]=useState(true);
    const [listaXMunicipio,setListaXMunicipio]=  useState([]);      
    const [listaXSeccion,setListaXSeccion]=  useState([]);    
    const [listaXSecCasilla,setListaXSecCasilla]=  useState([]);     
    const [tituloSeccion,setTituloSeccion]=  useState("");       
    const [tituloMunicipio,setTituloMunicipio]=  useState("");       
    //    let idusu =localStorage.getItem('UsuNum') ;
    //const [actualizarLista,setActualizarLista] = useState(0);     
    useEffect(()=>
    {  
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {                                             
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/votacion-movil-municipio-total`;      
            let auxU=process.env.REACT_APP_LINK +`/votacion-movil-municipio-total`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                                      
                'periodo':3,
                'municipio':101,
                'idDispositivo':'7y8dyguwjhds'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux) 
                   {//console.log(aux);                       
                    setListaXMunicipio(aux);
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });                                  
        };
        conectarJSon();
        async function llamadaSecciones()  {                                             
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/votacion-web-municipio-seccion`;      
            let auxU=process.env.REACT_APP_LINK +`/votacion-web-municipio-seccion`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                                      
                'periodo':3,
                'municipio':101,
                'idDispositivo':'7y8dyguwjhds'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux) 
                   {//console.log(aux);                       
                    setListaXSeccion(aux);
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });                                  
        };
        llamadaSecciones();
    },[datosJS]);  
    return (
        <div className=" is-flex is-flex-direction-column">
            
        
        <div className=" pl-1 is-flex is-flex-direction-row">            
           {/*<MiCard valores={datosJS}/>*/}
           <MiCard valores={datosJS}/>
           {/*  <TablaVotosGeneral datosJS={datosJS}  setListaXMunicipio={setListaXMunicipio} setActualizar={setActualizar} actualizar={actualizar}  setListaXSeccion={setListaXSeccion} setTituloMunicipio={setTituloMunicipio}/> */}
           { listaXMunicipio.length>0 ? <PGraficaMunicipio valor={listaXMunicipio} descripcion="Votación" Auxtam={1}/> :null}
            
        </div>
        <div className=" pl-1 pt-2 pb-2 is-flex is-flex-direction-row">            
          {/*    */ listaXSeccion.length>0  ? <TablaXSecciones datosJS={listaXSeccion} setListaXSecCasilla={setListaXSecCasilla} setTituloSeccion={setTituloSeccion} tituloMunicipio={tituloMunicipio} setActualizar={setActualizar} actualizar={actualizar} />:null}
          {/*  */ listaXSecCasilla.length>0 ? <PGraficaSeccion valor={listaXSecCasilla} descripcion={tituloSeccion?("Votos Secc. " +tituloSeccion):("Votos")} tituloSeccion={tituloSeccion} Auxtam={1}/> :null}  
        </div>
          
        </div>
    );
}
 
export default PanelIncidencias;