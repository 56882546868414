import React,{useState,useEffect} from 'react';
import Modal from '../Promos/PromoModal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import Select, { components } from 'react-select';
const BusqDatosGeneralModal = ({datGen,infoPromot,secciones,validoPromotor,listaPromot,mostrar,setMensaje}) => {
    const [listaSeccion,setListaSeccion]=  useState("");    
    let idusu =localStorage.getItem('UsuNum') ;
    useEffect(()=>{         
        
        if (validoPromotor) {
            //console.log("soy el promotor");
        }else{
           // console.log("no soy el promotor"+infoPromot.PromotId);
        }
        function llenaSelect()
        {                          
            let arreSecc=[];   
            secciones.map((seccion) => {
                arreSecc.push({ value : seccion.SeccionId, label :seccion.SeccionNombre })
            })
            //console.log(arreSecc);
            setListaSeccion(arreSecc);

        }
        llenaSelect();

       
    },[]);
    
    const [classNameModal,setclassNameModal]=  useState(mostrar);     
    const [nombre,setNombre]=  useState(datGen.promNombre);     
    
    const [apPat,setApPat]=  useState(datGen.promPaterno);     
    const [apMat,setApMat]=  useState(datGen.promMaterno);     
    const[espera,setEspera]=useState(true);
    const [seccion,setSeccion]=  useState(datGen.promSeccion);
    const[registros,setRegistros]=useState("");
    const[infoPromotor,setinfoPromotor]=useState("");
    
    let foto =datGen.promFoto;
    let nombreCompleto=datGen.promNombre+" "+datGen.promPaterno+" "+datGen.promMaterno;
    let calle=datGen.promCalle;
    let colonia=datGen.promColonia;
    let NoExt=datGen.promNoExt;
    let NoInt= datGen.promNoInt;
    //let auxNum="NoExt: "+NoExt+ "     NoInt: " +NoInt;
    let CodPost=datGen.promCP;
    let telefono=datGen.promTel ;
    let celular=datGen.promCel;
    let email=datGen.promEmail;
    let seccionUsu =datGen.promSeccion;
    let ine =datGen.promINE;
    let prefe = datGen.promPreferencia;
    let participacion= datGen.promParticipaRGRC;
    let idPromovido=datGen.promId ;
    //console.log(idPromovido);
    //console.log(participacion);
    function auxFoto(){
        return (<div id="card-img-izq ">        
        <article className="tile is-child ">                    
            <figure className="image is-4by3 ">
                <img src={foto}/>                        
            </figure>
        </article>
        <div id="card-img-der">                                
                       
        </div>                                                                                                                                                            
    </div>)
    }
    const mostrarModal =()=>
    { 
        setclassNameModal("is-active");
    }
    const btnMostrar = e=>{
        e.preventDefault();
        mostrarModal();
    }
    const esconderModal =()=>
    { 
        //toast.clearWaitingQueue({ containerId: "ModalPromovido" });
        //toast.dismiss();
        setMensaje("");
        setclassNameModal("");
    }


    const llamarApi=(promoto)=>{
        const source = axios.CancelToken.source();     
        console.log(seccion);
        async function conectarJSon()  {         
           // setdesBtn(true); 
            setEspera(false);
            //console.log("antes de llamar api "+ promoto);
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-cambio-promotor`;      
            let auxU=process.env.REACT_APP_LINK +`/promovido-cambio-promotor`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'promotor': promoto , 
                'promovido':idPromovido, 
                'seccion':seccion,
                'nombre':nombre,
                'paterno':apPat,
                'materno':apMat ,           
                'idDispositivo': 'csdq21weddewde'                 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                
                //console.log(aux);
                                                                                  
                   if (aux) {
                    //console.log("entrando a la respuesta"); 
                   const mensaje=aux.mensaje ;
                  //  console.log("se actualizo");
                    toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalActulizacionPromotor'} );   
                   }
              
                
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log("se un error");
                console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            //setdesBtn(false);  
            setEspera(true);            
        };
        conectarJSon();
    }
    const onSubmit = e =>
	{
      //  console.log(infoPromotor);
		e.preventDefault();
        if(nombre.trim().length>=3)
        {   if (apPat.trim().length>=4)
            {   if (apMat.trim().length>=4)
                {   if (infoPromotor)
                    {         
                        llamarApi(infoPromotor[0].value);
                    }
                    else{                             
                        llamarApi(infoPromot.PromotId);
                    }
                }
                else{
                    toast.warning("Apellido Materno incompleto", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalActulizacionPromotor'} );      
                }
            }else{
                toast.warning("Apellido Paterno incompleto", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalActulizacionPromotor'} );   
            }
        }
        else{            
            toast.warning("Nombre incompleto", {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalActulizacionPromotor'} );   
        }
        		
	}
    const btnCancelar=(e)=>
    {   e.preventDefault();
        esconderModal();
       
    }
    const guardaPromotor=(promo) =>{                
        const resultados= listaPromot.filter(  (registro) => registro.value === promo  )     
        //console.log(resultados);          
        setinfoPromotor( resultados);                  
    }
    let cambiaPartici=participacion===0 ?('No'):('Si');
    let auxParti =(participacion ===0 ||participacion===1 )? (cambiaPartici):(null);
    let auxNumInt =(NoInt !==''&&NoInt!==null&&NoInt!==" " )? ("   NoInt: "+NoInt):("");
    let numeros = (NoExt !==''&&NoExt!==null&&NoExt!==" " )? ("NoExt: "+NoExt +auxNumInt):(null);
    const guardaSeccion=(auxseccion)=> {
        
        const dato= secciones.filter(  (registro) => registro.SeccionId === auxseccion )          
        //localStorage.setItem('SeccNum',''+dato[0].SeccionId); 
        setSeccion(dato[0].SeccionId);               
    }

    const selector=(<Select placeholder={infoPromot.PromotNombre} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '12px', fontWeight: '600' ,width:'200px'}),
                                option: styles => ({ ...styles, fontSize: '12px',width:'200px' }),
                                menuList: styles => ({ ...styles,width:'200px' }),
                                container: styles => ({ ...styles,width:'200px' })
                            }
                        }
                        options={listaPromot}
                        onChange={opt =>  guardaPromotor(opt.value) }
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    );
    const selectorSeccion=(<Select placeholder={seccionUsu} 
                        styles={
                            {
                                control: styles => ({ ...styles, fontSize: '10px', fontWeight: '600',height:'10px' ,minHeight:'30px',  }),
                                option: styles => ({ ...styles, fontSize: '10px'}),
                                menuList: styles => ({ ...styles,width:'100px' }),
                                container: styles => ({ ...styles,width:'100px' })
                            }
                        }
                        options={listaSeccion}
                        onChange={opt =>  guardaSeccion(opt.value)}
                        
                        noOptionsMessage={ () =>  ('Sin Resultados') }/>     
                    );                    
    const onChange = (e) => {        
        let { target } = e;
                
        let expPalabraspermitida = new RegExp('[0-9._:$!%-({})"+/*|]');
        let expCadenaGeneral = new RegExp('[_:$!%-({})+/*"|&]');        
        let expNumero = new RegExp('[\bA-Za-z.,_:$!%-({})/"*+|]');        
        
        let expmenos=   new RegExp('-');
        if(target.name === "nombre")
        {
            if (!expPalabraspermitida.test(target.value)&&!expmenos.test(target.value))
            {   let auxCampo= target.value;
                setNombre(auxCampo.toUpperCase());       
            }
        }
        else
        {
            if(target.name === "apPat")
            {
                if (!expPalabraspermitida.test(target.value)&&!expmenos.test(target.value))
                {   let auxCampo= target.value;
                    setApPat(auxCampo.toUpperCase());       
                }
            }
            else
            {
                if(target.name === "apMat")
                {
                    if (!expPalabraspermitida.test(target.value)&&!expmenos.test(target.value))
                    {   let auxCampo= target.value;
                        setApMat( auxCampo.toUpperCase() );
                    }
                }   
            }
        }
    }    
    
    const fotografia = (foto) ? (auxFoto()): (<div className="box is-1"><h1 className="has-text-centered">Sin fotografia</h1></div>);    
    
    const botones = espera ? (<div className="pt-2 pb-0"><span className="pr-4"><button className="button is-aceptar " onClick={mostrarModal} type="submit">Guardar</button></span>
        <a className="button is-danger pl-2" onClick={btnCancelar} >Cerrar</a></div>  ) : (<div><progress className="progress is-small is-sistema " max="100">15%</progress>Cargando...</div>);
    const formulario =validoPromotor?(
         <div> 
            <div className="field  ">   
                <div className="columns	">                                                                            
                    <div className="column pr-0 ">
                        <span className="help">Promotor</span>       
                    </div>
                    <div className="column pr-0 pl-2 pt-1">
                        { selector }
                    </div>
                    <div className="column  pt-1">
                        {selectorSeccion}
                    </div>                    
                </div>              
            </div>              
            <div className="pb-2 ">
                <span className="" >  <input className="input is-small " onChange={onChange} type="text" placeholder="Nombre" value={nombre} name="nombre" maxLength="48"/></span>           
            </div>                                                                                                            
            <div className="field is-horizontal">                            
                <div className="field-body">
                    <div className="field">
                        <input className="input is-small"  onChange={onChange} type="text" placeholder="Apellido Paterno" value={apPat} name="apPat" maxLength="48"/>                    
                    </div>
                    <div className="field">
                        <input className="input  is-small " onChange={onChange} type="text" placeholder="Apellido Materno" value={apMat} name="apMat" maxLength="48"/>                     
                    </div>
                </div>
            </div>
        </div>
        ):(null)


    return (
        <div>
      
        <Modal 
            id={"ModalDatosGenerales"}
            onClose={()=>esconderModal()}
            className={mostrar}
            title={"Datos Generales"} 
            ancho={400}
            >
            <form className="" onSubmit={onSubmit}>  
            {formulario}
            <div className="tile pt-2">
                <div className="tile is-vertical">
                    {fotografia}
                    <article className="tile is-child box  ">
                    <p className="tamLetra2" style={{ fontSize:1.2+'rem', fontWeight:500}}> <span className="letraColorSecc" >{seccionUsu}</span> <span >{ine}</span></p>                                                                                
                            <p className="tamLetra2"  style={{ fontWeight:700}}>{nombreCompleto}</p>                            
                            {prefe ? (<p className="tamLetra2">Preferencia política <span className="letraColorSecc" >{prefe}</span> </p>):(null)} 
                            {auxParti ? (<p className="tamLetra2">Participacion RG / RC <span className="letraColorSecc" >{cambiaPartici}</span> </p>):(null)} 
                            <p className="tamLetra2">Colonia: {colonia}</p>
                            <p className="tamLetra2">Calle: {calle}</p>                           
                            {numeros ? (<p className="tamLetra2">{numeros} </p>):(null)} 
                            <p className="tamLetra2">Codigo Postal: {CodPost}</p>                            
                            {(telefono!==''&&telefono!==null&&telefono!==" " )? (<p className="tamLetra2">Telefono: {telefono} </p>):(null)} 
                            {(celular !==''&&celular!==null&&celular!==" " )? (<p className="tamLetra2">Celular: {celular} </p>):(null)} 
                            {(email !==''&&email!==null&&email!==" ") ? (<p className="tamLetra2">Correo: {email} </p>):(null)}                           
                                            
                    </article>
                </div>      
            </div>    
            <div className="pt-3">
            {validoPromotor?botones :null}
            </div>
            
            <ToastContainer enableMultiContainer containerId={'ModalActulizacionPromotor'}/>    
            </form>     
    </Modal>
    </div>
);
}
 
export default BusqDatosGeneralModal;