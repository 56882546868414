import React,{useState,useEffect} from 'react';
import {Doughnut,HorizontalBar} from 'react-chartjs-2';
//import BuscaColor from "../Color";
//import Chart from 'chart.js';

const MGraficaTotal = ({valor,descripcion,Auxtam,seccion}) => {  
  
//    let colorTabla =[];
    const auxCoordi = valor;
    let contador=0; 
    //let miLabel=[];
    //let midata=[];
    let auxDis ="espacioGraf2";
    const [miLabel,setMiLabel]=  useState([]);       
    const [midata,setMidata]=  useState([]);   
    const [colorTabla,setColorTabla]=  useState([]);       
    
    useEffect(()=>{                         
        let auxMovil=0;
        let auxProm=0;
        if (seccion!==0) {
          let info=valor.filter((dat)=>dat.SeccId === seccion)    
          info.forEach( movili => {
            auxProm = auxProm + movili.Promovido;
            auxMovil = auxMovil + movili.Movilizado;
        });
        }
        else{
            auxCoordi.forEach( movili => {
                auxProm = auxProm + movili.Promovido;
                auxMovil = auxMovil + movili.Movilizado;
            });
         
                                 
            contador++; 
        }
        setMidata([auxProm,auxMovil])
        setMiLabel(["Promovidos","Movilizados"])   
        setColorTabla(["#FF0505E8","#3273DC"])                 
    },[seccion]);
    
   
    
    contador = 0;
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
      },
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 5
        }
      },
      title:{
        fontStyle: 'bold',
      },
     scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            scaleLabel :{
              fontSize:6,
            },
          },
        ],
      },
    }  
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Incidencias',//
          backgroundColor: colorTabla,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: '#0BA2EC',
          hoverBorderColor: 'rgb(0,88,101)',
          data: midata 
        }
      ]
    };    
    if (Auxtam===1) {
      auxDis="espacioGraf";
    }
    
  return ( 
    <div className="  "id="Movgrafica" >
      <div className=" marcoGraficaIncid" >
        <div className="content">
          {<h2 className="marcoTexto" id="ajustarGrafica">{descripcion}</h2>}
          {/* <HorizontalBar
              id="graficaHorBarIncidencia"
              data={horizontalRuta}
              width={850}
              height={500}
              options={options}
            />               */}     
            <Doughnut
                data={horizontalRuta}
                width={350}
              height={250}  
                
            />
        </div>
      </div>
  </div>
   );
}

 
export default MGraficaTotal;