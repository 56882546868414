import React,{ useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import jwt from 'jsonwebtoken';
import sistemaHooks from '../hooks/sistema_hook';
const Login = (props) => {
	const [usuarioLoc, setUsuario] = sistemaHooks("UsuarioNom");
    const [currentTab,setTab] = sistemaHooks("TabSelected");
	const [currentMenu,setMenu] = sistemaHooks("MenuSelected");
	const [nivelUsuario,setNivelUsuario] = sistemaHooks("TipoUsuario");
	const [idPromot,setIdPromot] = sistemaHooks("idPromot");
	let history = useHistory();
	const [auxBandera,setBandera] = useState(0);
	const [error,actualizarError] = useState(false);
	let [auxError,enviarError] = useState("");
	let [desBtn,enviarBloqueo] = useState(false);
	const [valTab, setValTab] = useState('');

	const onValueTabChanged = (tabSelect,menuSelect) => {
        setValTab(tabSelect);
        //console.log(`Asigna valor Login TAB[${tabSelect}] MENU[${menuSelect}] `)
		props.onValueAppTabChanged(tabSelect,menuSelect)
    }
	function cerrarSesion() {
        localStorage.clear();
                //window.location.replace('');
        history.push("/");                
        window.location.reload(true);
    }
	useEffect(()=>{ 
		const veriSesion = async () => {
		//	console.log("Login validacion Token");
			const token= localStorage.getItem('token20');
			if (token) {
				jwt.verify(token,process.env.REACT_APP_SERV_TOKEN_KEY ,{algorithm: ['HS512']}, (err, decoded) => {   									
				if (decoded) {  
				//	console.log("Login Token Valido redirige a INICIO");   
										
					const element = <span className=" " style={{fontSize:8+'px'}} id="cabecera_cont_usuario">{usuarioLoc}</span>;
					//ReactDOM.render(element, document.getElementById('cabecera_cont_usuario'));					
										
					if (nivelUsuario!==3&&nivelUsuario!==5) {
						onValueTabChanged("Inicio", "Inicio")//
						history.push("/inicio")
						window.location.reload(true);	
					}else{
						//onValueTabChanged("Inicio", "Inicio")//
						history.push("/promotor-busqueda")
						window.location.reload(true);	
					}
				}
				else{
					localStorage.clear();
					window.location.reload(true);
				}
				});
			} else{
				actualizarError(true);
				
			}    
	}
	veriSesion();
			
	//console.log('Login UseEffect');
	},[auxBandera,valTab,currentTab,currentMenu]);
	
	async function llamada(){
		let auxBand=0;
		//console.log("iniciando");
		//let auxU=`http://`+process.env.REACT_APP_LINK +`/autenticar`;
		let auxU=process.env.REACT_APP_LINK +`/autenticar`;
		let config = {
			url:auxU ,
			method: 'POST',
			headers: { 'content-type' : 'application/json'},
			data: {
				user:email,
				pass:password,
				idDispositivo:"fbab2fe86a3fb751"        
			}
		};
		await axios(config)
			.then(res => {
				//console.log('recibiendo datos');
				console.log(res.data);
				localStorage.setItem('token20',res.data.userToken);
				localStorage.setItem('UsuarioNom',res.data.userNombre);
				localStorage.setItem('UsuNum',res.data.userId);
				if (res.data.userTipo!==3&&res.data.userTipo!==5)
				{	//localStorage.setItem('auxiliar','no entrando');
					setTab("Inicio");
					setMenu("Inicio")	
				}else{
					setIdPromot(res.data.userPromotId)
				}
				setNivelUsuario(res.data.userTipo)
				setUsuario(res.data.userNombre);
				const element = <h4 className="title UserPrincipal" id="cabecera_cont_usuario">{res.data.userNombre}</h4>;
				//ReactDOM.render(element, document.getElementById('cabecera_cont_usuario'));
				setBandera(1);
			}).catch(auxerror => {
				actualizarError(true);
				enviarBloqueo(false);
				console.log(auxerror)
				enviarError(auxerror.response.data.mensaje);
				if(auxerror.response.status === 500){
					console.log(auxerror.response.data.mensaje);
				}
			});
	}

	const [usuario,guardarUsuario] = useState({
			email:'',
			password:''
	});

	//extraer de usuario
	const {email,password} = usuario;
	const onChange = e =>
	{
		guardarUsuario({
			...usuario, //copiamos el usuario
			[e.target.name] : e.target.value  
		})
	};
	const auxiliarF = async() =>{
		llamada();	
	}
	//cuando el usuario quiere iniciar sesion 
	
	const onSubmit = e =>
	{
		e.preventDefault();
		//Validar que no haya campos vacios
		if(!desBtn)
			{	enviarBloqueo(true);						
			if ( email.trim()==='' || password.trim().trim==='' || email.length < 4 || password < 4 ) 
			{
				actualizarError(true);
				enviarError("Debe llenar los campos usuario y contraseña"  );
				enviarBloqueo(false);
				return;	
			}
			actualizarError(false);
			auxiliarF();
	}									 
	}

	const content = !desBtn ? (<input className="button is-info" type="submit" value="Iniciar Sesion"/>  ) : (<div><progress className="progress is-small is-link" max="100">15%</progress><input className="button is-info" value="Verificando..."/></div>)
    return (
		<div className="tile is-parent" >
			<article className="tile is-child ">
				<p className="title" id="ajustarTitulo">Inicio de Sesión</p>            
				<div className="tile" id="login">						
					<form className="box" onSubmit={onSubmit}>
					{error ? <p>{auxError}</p>:null}
						<div className="field">
							<label className="label" htmlFor="email">Usuario</label>
							<div className="control">
								<input
									className="input" 
									// type="email"
									name="email"                         
									placeholder="Usuario"
									value={email}
									onChange={onChange}/>
							</div>
						</div>								
						<div className="field">
							<label className="label">Contraseña</label>
							<div className="control" htmlFor="password">
								<input 
									className="input" 
									type="password" 
									name="password"
									placeholder="********"
									value={password}
									onChange={onChange} 
									/>
							</div>
						</div>
						<div>
							{
								content
							}
						</div>						 
					</form>
				</div>
			</article>
		</div>		
	);
}
 
export default Login;