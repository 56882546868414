import React from 'react';
import ModalP from "../Promos/PromoLlamadaModal";

const BusqTablaSinPromov = ({seccion,infoPromot,setActualizar,actualizar}) => {
    //let datos=valores;        
        
        function TablaSinPromo() {            
            //console.log(tot);
            return(
                <div id="tamTablaPromovidosBusqueda">
                    <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                        <thead >    
                            <tr>
                                <th style={{padding: 0.2 +'rem'}}>Total </th>
                                <th style={{padding: 0.2 +'rem'}}>Agregar</th>
                                <th style={{padding: 0.2 +'rem'}}>Ver</th>               
                                <th style={{padding: 0.2 +'rem'}}>Sección</th> 
                                <th style={{padding: 0.2 +'rem'}}>Nombre</th>               
                                <th style={{padding: 0.2 +'rem'}}>Preferencia</th>                                                        
                                <th style={{padding: 0.2 +'rem'}}>Tel/cel</th>
                                <th style={{padding: 0.2 +'rem'}}>Dirección</th>   
                            </tr>
                                                    
                        </thead>  
                        <tbody>          
                            
                            <tr >
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>Sin</th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>Promovidos</th>
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}></th>                                                                
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>Registrados</th>                                                                                                                   
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7"> </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7"> </span></th>                                                    
                            </tr>              
                                                            
                        </tbody>
                    </table>
                </div>
            );
            }
    
	
    const btnActualizar=e=>{
        e.preventDefault();
        console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <a className="pt-1 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/> 
                        </a>
    const btnNuevoRegistro=<div className="pl-5"><ModalP valProm={infoPromot} seccion={seccion}/></div>         

   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    //const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= (TablaSinPromo())
    return (       
        <div className="contenido-tbPromov pl-2 pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal ">            
                                <div className=" field-body  ">
                                    
                                
                                </div>
                                {btnRecargar}
                                {btnNuevoRegistro}
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>                
        </div>
           
     
    );
}
 
export default BusqTablaSinPromov;