import React,{useState} from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import ModalDatGen from './BusqDatosGeneralModal';
import ModalPrograma from './BusqProgramasModal';
import ReactTooltip from "react-tooltip";
import XLSX from 'xlsx';
import moment from 'moment';
const BusqNombre = ({secciones,listaPromot,validoPromotor}) => {
    
    const [datos,setDatos]=  useState("");   
    const [tabla,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [mensaje,setMensaje]=useState("");
    const [mensaje2,setMensaje2]=useState("");    
    const [promotorSelect,setPromotorSelect]=useState("");
    const[espera,setEspera]=useState(true);
    let auxArreglo=[];
    
    const {nombre} = promovido;   
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
        setPromotorSelect("");
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        let auxCadena=nombre.trim();
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Promovidos por Nombre ["+auxCadena.toUpperCase() +"].xlsx")
    };
    const llamdaModal=()=>{
        setMensaje("is-active")
    }
    const llamdaModal2=()=>{
        setMensaje2("is-active")
    }
    const filtro=()=>{        
        let auxCadena=nombre.trim();  
        let info=""
        auxArreglo=[]   
        if (datos!=="") {
            info=datos.filter((dat)=>dat.promNombreFull.search(auxCadena.toUpperCase() ) !== -1)             
        } 
                  
        
        return TablaPromo(info);
    }
    function obtenerDireccion(promovido)
    {   let direccion="";
        direccion= promovido.promCalle ? (promovido.promCalle) : (direccion);
        direccion= promovido.promNoExt ? (direccion+" "+promovido.promNoExt) : (direccion);
        direccion= promovido.promNoInt ? (direccion+" "+promovido.promNoInt) : (direccion);
        direccion= promovido.promColonia ? (direccion+", "+promovido.promColonia) : (direccion);
        return direccion;
    }
    const llamarApi=(auxNombre)=>{
        const source = axios.CancelToken.source();     
        
        async function conectarJSon()  {         
           // setdesBtn(true); 
            setEspera(false);
            //console.log("antes de llamar api "+ promoto);
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-lista`;               
            let auxU=process.env.REACT_APP_LINK +`/promovido-lista`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({
                'cadena':auxNombre,     
                'idDispositivo': 'csdq21weddewde'                 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                
                //console.log(aux);
                                                                                  
                   if (aux.resultado[0].promId !== -1) {
                        console.log("se consulto");
                        //console.log(aux.resultado);
                        setDatos(aux.resultado)
                   }else{
                       console.log("sin datos ");
                       setDatos("")
                   }
                                                                          
            })
            .catch(function (error) {
                //console.log("se un error");
                console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                 
                }*/
            });  
            //setdesBtn(false);  
            setEspera(true);            
        };
        conectarJSon();
    }
    const TablaPromo =(auxDatos)=> {
        let numRenglon=0;
        let tot= Object.keys(auxDatos).length;
       
        if (auxDatos!=="") {
                       
            let auxi2=["#","Sección","INE","Nombre","Preferencia","Tel/Cel","Dirección"];
            auxArreglo.push(auxi2);    
            if(tot===0){
                let auxi=["","Sin","Promovidos"]
                auxArreglo.push(auxi);
            }           
        return(
            <div id="tamTablaPromovidosBusqueda">
                <ReactTooltip place="left" type="success" effect="solid" />
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>
                            <th style={{padding: 0.2 +'rem'}}>{tot}</th>
                            <th style={{padding: 0.2 +'rem'}}>Prog</th>
                            <th style={{padding: 0.2 +'rem'}}>Det</th>               
                            <th style={{padding: 0.2 +'rem'}}>Sección</th> 
                            <th style={{padding: 0.2 +'rem'}}>INE</th>      
                            <th style={{padding: 0.2 +'rem'}}>Nombre</th>               
                            <th style={{padding: 0.2 +'rem'}}>Preferencia</th>                                                        
                            <th style={{padding: 0.2 +'rem'}}>Tel/cel</th>
                            <th style={{padding: 0.2 +'rem'}}>Dirección</th>   
                        </tr>
                                                
                    </thead>  
                    <tbody>  
                        { auxDatos.map((promo,index) => {
                            let auxNombre="";
                            //auxNombre = promotor.promovPaterno+" "+promotor.promovMaterno+" "+promotor.promovNombre ;
                           // console.log(promo);
                            auxNombre = promo.promNombre+" "+promo.promPaterno+" "+promo.promMaterno;
                            let auxDireccion = obtenerDireccion(promo);
                            //console.log(auxNombre);
                            let auxTelefono="";
                            if (promo.promTel !==""||promo.promTel !==null) {
                                auxTelefono=promo.promTel;

                            }else{
                                if (promo.promCel !==""||promo.promCel !==null) {
                                    auxTelefono=promo.promCel;
                                }
                            }
                            let auxi=[index+1,promo.promSeccion,promo.promINE ,auxNombre,promo.promPreferencia,auxTelefono,auxDireccion]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === promo.promId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promo.promId,promo) }  >
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{numRenglon}  </span></th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>     
                                    <span data-tip="Aportaciones del Promovido">
                                        <a className="" onClick={llamdaModal}> <img src={tabla === promo.promId ? "archivoBlanco.svg" : "archivo.svg"}   alt="" border="0" height="20" width="20"/> </a> 
                                    </span>
                                </th>
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span data-tip="Información del Promovido">
                                        <a className="" onClick={llamdaModal2}> <img src={tabla === promo.promId ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                    </span>                              
                                </th>                                                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promSeccion}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promINE}  </span></th>                                                    
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promPreferencia}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxTelefono}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxDireccion}  </span></th>                                                    
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );}
        else{
            TablaSinPromo()

        }
        }

        const TablaSinPromo=()=> {            
            //console.log(tot);
            
            return(
                <div id="tamTablaPromovidosBusqueda">
                    <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                        <thead >    
                            <tr>
                                <th style={{padding: 0.2 +'rem'}}>Total </th>
                                <th style={{padding: 0.2 +'rem'}}>Agregar</th>
                                <th style={{padding: 0.2 +'rem'}}>Ver</th>               
                                <th style={{padding: 0.2 +'rem'}}>Sección</th> 
                                <th style={{padding: 0.2 +'rem'}}>Nombre</th>               
                                <th style={{padding: 0.2 +'rem'}}>Preferencia</th>                                                        
                                <th style={{padding: 0.2 +'rem'}}>Tel/cel</th>
                                <th style={{padding: 0.2 +'rem'}}>Dirección</th>   
                            </tr>
                                                    
                        </thead>  
                        <tbody>          
                            
                            <tr >
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>Sin</th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>Promovidos</th>
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}></th>                                                                
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}></th>                                                                                                                                                   
                            </tr>              
                                                            
                        </tbody>
                    </table>
                </div>
            );
            }
	//extraer el promo
	     
    const onChange = e =>
    {   let expNopermitida = new RegExp('[0-9._:$!%-({})/*?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value.toUpperCase()  
            })
        }
        
    };
    //
    const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        if (nombre.trim().length>=3) {
            llamarApi(nombre);    
        }
        
    }
    const btnExportar = espera&&datos!=="" ?(<span data-tip="Exportar Promovidos"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>):(null)
    const btnRecargar = <span data-tip="Recuperar Promovidos"><a className="pt-1 pl-2"  onClick={btnActualizar}>
                            <img className="pt-2 " src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/> 
                        </a></span>        
   const modal2= mensaje2? (<ModalDatGen datGen={registro} secciones={secciones} mostrar={mensaje2} setMensaje={setMensaje2} listaPromot={listaPromot} infoPromot={promotorSelect} validoPromotor={validoPromotor}/>):(null);      
   const modal1= mensaje? (<ModalPrograma idValor={tabla} mostrar={mensaje} setMensaje={setMensaje}/>):(null);        
   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const tablas = datos!==""?(contenido):(TablaSinPromo())  
    const llamada= espera?(tablas):(<div id="tamTablaPromovidosBusqueda"><progress className="progress is-small is-sistema" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div>)      
    
    return (       
        <div className="contenido-tbPromov pl-2 pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal ">            
                                <div className=" field-body  ">
                                    <input
                                        className="input " 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar promovido"
                                        value={nombre}
                                        onChange={onChange}/>
                                      
                                
                                </div>
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                {btnRecargar}                                
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>    
            {modal2}
            {modal1}
        </div>
           
     
    );
}
 
 
export default BusqNombre;