import React,{useState,useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
import {Facebook} from "react-content-loader";
import TablaPromovidos from './TablaPromovidosConsultar';
import MapaMarcadores from './MapaConsultaMarcador';
import TablaSinPromovidos from './TablaSinPromovConsul';
const MapaPanelConsultar = ({promotor,poligonos}) => {
//    console.log(promotor.label);
    const [lista,setLista]= useState(""); 
    const [centro,setCentro] = useState({lat: 19.5953512,  lng: -98.9210809});
    const [vacio,setVacio] = useState(false);    
    const [esperando,setEsperando] = useState(false);    
    const [actualizar,setActualizar] = useState(0);
    const [cambiaMarcador,setCambiaMarcador] = useState(true);
    const [promoSeleccionado,setPromoSeleccionado] = useState(true);
    let history = useHistory();
    let idusu =localStorage.getItem('UsuNum') ;
    let token =localStorage.getItem('token20') ;          
    useEffect(()=>{                 
        const source = axios.CancelToken.source();
        setEsperando(false)
        async function conectarJSon()  {       
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-listxubicacion-gps`;   
            let auxU=process.env.REACT_APP_LINK +`/promovido-listxubicacion-gps`;   
            let dataInfo = qs.stringify({
                'promotor':promotor.value,
                'usuario': idusu,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {
  //             console.log("entrando ");
                aux=  response.data;
               console.log(aux);                
                if (aux.resultado) 
                {   setLista(aux.resultado);
                   // console.log(aux.resultado);                    
                   setVacio(false);
                }else
                {   setVacio(true)
                    setLista([]);
                }                                            
            })
            .catch(function (error) {                                
                console.log(`Error: ${error} `);
                if (error.response.status>200)
                {
                //  console.log("error con el token");
                //console.log(`Error: ${error.response.data.mensaje} `);
                    if ( error.response.status!==401 && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
                    { //  console.log(error.response.data.error.name);
                        //console.log('expiro token , loguearse de nuevo');
                        localStorage.clear();
                        //window.location.replace('');
                        history.push("/");                
                        window.location.reload(true);
                        //console.log("seguir imprimiendo");                
                    }
                    else{
                        if (error.response.data.mensaje==="No se agrego el Token en los Headers de la petición.") {
                          //  console.log('loguearse de nuevo');
                            localStorage.clear();
                            //window.location.replace('');
                            history.push("/");                
                            window.location.reload(true);
                        }
                    }
                }
            });                            
        };  
        setVacio(false);      
        setLista(null);        
        conectarJSon();        
        setEsperando(true);

    },[promotor,actualizar]);
    const mapa =  (<div className="pl-2"><MapaMarcadores poligonos={poligonos} listaPromov={lista} centro={centro} cambiaMarcador={cambiaMarcador} setCambiaMarcador={setCambiaMarcador} promoSeleccionado={promoSeleccionado}/></div>) 
    const tablaSinPromov = vacio ? (<div className="pt-5 columns"><TablaSinPromovidos   setActualizar={setActualizar} actualizar={actualizar}/>{mapa}</div>) :(<Facebook/>)
    
    const tablaPromo = lista  ? (<div className="pt-5 columns"><TablaPromovidos  datosJS={lista}  setCentro={setCentro} setActualizar={setActualizar} actualizar={actualizar} setCambiaMarcador={setCambiaMarcador} setPromoSeleccionado={setPromoSeleccionado}/>{mapa}</div>) :(<Facebook/>)
    const contenido = vacio&&esperando? (tablaSinPromov):(tablaPromo)
    return (
        <div >    
                {contenido}
                {}     
        </div>
    );
}
 
export default MapaPanelConsultar;