import React,{useState,useEffect} from 'react';
import IncTablaTipo from './IncTablaTipo';
import IncGrafica from './IncGrafica';
import IncTablaDetalle from './IncTablaDetalles';

const PanelIncidencias = ({datosJS,setActualizar,actualizar}) => {
    const[espera,setEspera]=useState(true);
    const [listaXTipo,setListaXTipo]=  useState([]);       
    //    let idusu =localStorage.getItem('UsuNum') ;
    //const [actualizarLista,setActualizarLista] = useState(0);     
      
    return (
        <div className=" is-flex is-flex-direction-column">
            
        
        <div className=" pl-2 is-flex is-flex-direction-row">            
            <IncTablaTipo datosJS={datosJS}  setListaXTipo={setListaXTipo} setActualizar={setActualizar} actualizar={actualizar} />
            <IncGrafica valor={datosJS} descripcion="Grafica Incidencias" Auxtam={1}/>
            
        </div>
            {listaXTipo ? <IncTablaDetalle valores={listaXTipo}/>:null}
        </div>
    );
}
 
export default PanelIncidencias;