import React from 'react';
import NumberFormat from 'react-number-format';
//import Graficas from './RPGraficas';
const RPCardSeccion = ({Seccion}) => {
    //console.log("recibiendo datos:");
    let auxMTotal=Seccion.MetaTotal;
    let auxEfe=Seccion.Efectividad;
    //console.log(auxAGlobal);
    //const {RutaId}=valor;
    //console.log(Promotor.MetaTotal);
    //console.log("promotor");
    //console.log(auxEfe);
    
    function llenadoTiles(nombre,datos) {
        let colorEti='etiqBlanProm';
        let auxDatos =datos;        
        
        if(auxDatos!==''){
            //auxDatos=datos;
            if(nombre.trim()==='Avance Global')
            {  //console.log(datos);
                auxDatos=datos+'%';
            }
            if(nombre.trim()==='Efectividad')
            {  //console.log(auxDatos);
                colorEti=validandoColor(auxDatos);
                auxDatos=datos+'%';
            }
        }
        else{
            auxDatos="";
        }              
        return(            
            <div className="tile is-parent" >
                <article className="tile is-child box  ">                
                    <p className="tamLetra" id={colorEti}><span id="ajustarTitulo">{auxDatos}</span></p>                
                    <p  className="tamLetra2" id="ajustarTitulo">{nombre}</p>                                
                </article>
            </div>                        
        );
    }
    
    function validandoColor(vEfecti) {
        var colorEfecti='dato';    
        if(vEfecti<60||vEfecti===0){
            colorEfecti= 'etiqRojaProm';        
        }
        else{
            if(vEfecti>=60 && vEfecti<80)
            {
                colorEfecti= 'etiqNaraProm';               
            }
            else{
                if(vEfecti>=80 )
                {
                    colorEfecti= 'etiqVerdProm';
                }
            }
        }
        
        return(colorEfecti);
    }
    
    return ( 
        
        <section className="py-1" >
            <div className="tile is-ancestor " >
                <div>{llenadoTiles('Seccion',<NumberFormat value={Seccion.SeccionNombre} displayType={'text'}  thousandSeparator={true}/>)}</div>
                <div>{llenadoTiles('Promovidos',<NumberFormat value={Seccion.Promovidos} displayType={'text'}  thousandSeparator={true}/>)}</div>
                <div>{llenadoTiles('Meta Total',auxMTotal)}</div>
                <div>{llenadoTiles('Meta actual',<NumberFormat value={Seccion.MetaActual} displayType={'text'}  thousandSeparator={true}/>)}</div>
                {/* <div>{llenadoTiles('Campo1 ',0)}</div>
                <div>{llenadoTiles('Campo2 ',0)}</div> */}
                <div>{llenadoTiles('Efectividad',auxEfe)}</div>
                
            </div> 
                                
        </section>
    );
    }
     
 
export default RPCardSeccion;