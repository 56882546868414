import React from 'react';

const TablaSinPromovConsul = ({seccion,infoPromot,setActualizar,actualizar}) => {
    //let datos=valores;        
        
        function TablaSinPromo() {            
            //console.log(tot);
            return(
                <div id="tamTablaMapa">
                    <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                        <thead >    
                            <tr>
                                <th style={{padding: 0.2 +'rem'}}>0 </th>
                                <th style={{padding: 0.2 +'rem'}}>Promovidos</th>
                      
                            </tr>
                                                    
                        </thead>  
                        <tbody>          
                            
                            <tr >
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}></th>
                                <th className=" has-text-centered" style={{padding: 0 +'rem'}}>Sin Promovidos Registrados</th>                                                                                                                                                                                                  
                            </tr>              
                                                            
                        </tbody>
                    </table>
                </div>
            );
            }
    
	
    const btnActualizar=e=>{
        e.preventDefault();
        console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <a className="pt-1 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/> 
                        </a>    
   
    const llamada= (TablaSinPromo())
    return (       
        <div className="contenido-tbPromov pl-2 pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal centered ">            
                                
                                {btnRecargar}                                
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>                
        </div>
           
     
    );
}
 
export default TablaSinPromovConsul;