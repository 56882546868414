import React,{useState,useEffect} from 'react';
import Coordinador from './coordinador';
import GraficaProm  from './PresidenteGraficas';
import axios from 'axios';
import qs from 'qs';

import { useHistory } from "react-router-dom";

const Presidencia = () => {
    const [datos,setDatos] = useState("");
    let history = useHistory();
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;
 useEffect(()=>{ 
   
    const conectarJSon = async () => {
    
    //let auxU=`http://`+process.env.REACT_APP_LINK +`/presidente`;
    let auxU=process.env.REACT_APP_LINK +`/presidente`;    
    let token =localStorage.getItem('token20') ;
    let id =localStorage.getItem('UsuNum') ;
    //console.log('token es: '+token);    
    let dataInfo = qs.stringify({
        'usuario': id,
        'idDispositivo': 'csdq21weddewde' 
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo
    };

    let aux ={};
    await axios(config)
    .then(function (response) {
       // console.log('mostrando datos');  
        //console.log(JSON.stringify(response.data));     
        aux =  response.data;
        if (aux.respuesta) 
        {
            //const datos =  await aux;      
             setDatos(aux);    
        }
    })
    .catch(function (error) {
        console.log(`Error: ${error.response.data.mensaje} Codigo: ${error.response.data.error.name}`);
        if (error.response.data.error.name==="JsonWebTokenError" ||error.response.data.error.name==="TokenExpiredError") 
        {  // console.log(error.response.data.error.name);
            //console.log('loguearse de nuevo');
            localStorage.clear();
            //window.location.replace('');
            history.push("/");                
            window.location.reload(true);
            //console.log("seguir imprimiendo");                
        }
        else{
            if (error.response.data.mensaje==="No se agrego el Token en los Headers de la petición.") {
                console.log('loguearse de nuevo');
              //  localStorage.clear();
                //window.location.replace('');
                history.push("/");                
                window.location.reload(true);
            }
        }
    });
    
}
    if (nivelUsuario!=='3'&&nivelUsuario!=='5'&&nivelUsuario!==''&&nivelUsuario!==' ') 
    {  conectarJSon();      
    }
    else
    {   localStorage.clear();            
        history.push("/");                
        window.location.reload(true);
    }

},[]);

    //const content = datos ? (<div><Coordinador valor={datos}/><div className="contenido-coordi"><GraficaProm valor={datos}/></div> </div>  ) : (<h1 className="title is-l">Cargando...</h1>)
    const content = datos ? (<div><Coordinador valor={datos}/><div className="contenido-coordi"><GraficaProm valor={datos}/></div> </div>  ) : (<div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div>)
    return (  
        <div className="contenido-coordi pt-2">  
        {
            content
        } 
        </div>
    );
}
 
export default Presidencia;