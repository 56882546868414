import React, { useState }  from 'react';
import Barra from './Barra';
import Sidebar2 from './SideBar2';
import TabPage from './TabPage';
const { forwardRef, useImperativeHandle } = React;

const Layout = forwardRef((props, ref) => { 
    const [valTab, setValTab] = useState('');
    const { useRef } = React;
    const childRef = useRef();
    const childRefMenu = useRef();
    const onValueTabChanged = (tabSelect,menuSelect) => {
        setValTab(tabSelect);
        props.onValueAppTabChanged(tabSelect,menuSelect)
      //  console.log(`Asigna valor Lay TAB[${tabSelect}] MENU[${menuSelect}] `)
    }
    useImperativeHandle(ref, () => ({
        onTabChanged(tabSelect,menuSelect) {
         //   console.log(`Layout recibo valor TAB[${tabSelect}] MENU[${menuSelect}] `)
            childRef.current.setAppBarra(tabSelect,menuSelect)
            childRefMenu.current.setAppMenu(tabSelect,menuSelect)
    }}));

    return(
        <div className="elgrande">
            <div id="navBar">
                <div   className="content ">
                    <Barra onValueTabChanged={onValueTabChanged} ref={childRef}/>
                </div>
            </div>         
            <div className="separacion">
                <div  id="miside-bar" >
                    <div>
                        <Sidebar2 onValueTabChanged={onValueTabChanged} ref={childRefMenu}/>
                    </div>
                </div>
                <div id="principal-centro" >
                {
                    props.children
                }
                </div>
            </div>
        </div>
    );
})
 
export default Layout;