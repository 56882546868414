import React,{useState,useEffect} from 'react';
import Select, { components } from 'react-select';
import PanelConsultar from './MapaPanelConsultar';
const MapaListaPromotor = ({listaPromotores,poligonos}) => {
    const [lista,setLista]= useState(""); 
    const [espera,setEspera]= useState(true); 
    const [promotor,setPromotor]= useState(""); 
    useEffect(()=>{                 
        function llenaSelect()
        {   let arrePromotor=[];   
            listaPromotores.map((promotor) => {
                arrePromotor.push({ value : promotor.PromotId, label :promotor.PromotNombre })
            })                        
            setLista( arrePromotor);
        }
        llenaSelect();

    },[]);
    const guardaPromotor=(auxpromot)=> {
        setEspera(false);        
        const dato= lista.filter(  (registro) => registro.value === auxpromot )         
        //console.log(dato[0]);         
        setPromotor(dato[0]);    setEspera(true);                   
    }

    const selectorPromotor=
        (<Select placeholder={"Promotores"} 
            styles={
                {
                    control: styles => ({ ...styles, fontSize: '12px', fontWeight: '600' ,width:'200px'}),
                    option: styles => ({ ...styles, fontSize: '12px',width:'200px' }),
                    menuList: styles => ({ ...styles,width:'200px' }),
                    container: styles => ({ ...styles,width:'200px' })
                }
            }
            options={lista}
            onChange={opt =>  guardaPromotor(opt.value)}
            
            noOptionsMessage={ () =>  ('Sin Resultados') }/>     
        );    
    const selector =  listaPromotores ?(selectorPromotor):(null)   
    const contenido = promotor&&espera ? ( <PanelConsultar promotor={promotor} poligonos={poligonos}/> ):(null)   
    return (
        <div>
            { selector }
            {contenido}
        </div>
    );
}
 
export default MapaListaPromotor;