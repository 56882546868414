import React,{useState} from 'react';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
const RepApoTablaPromovidos = ({apoyo,datos}) => {
    //const [datos,setDatos] = useState("");
    let auxArreglo=[];
    const [tabla,setTabla]=  useState("");
    const [registro,setRegistro]=useState("")      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [arreExcel,setArreExcel]=useState(["#","INE","Promovido","Dirección","Nacimiento"])
    
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        setTabla(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Apoyos de "+apoyo.label +".xlsx")
    };
    const filtro=(datos)=>{        
        let auxCadena=nombre.trim();      
        //let auxNombre = promo.promovidoNombre+" "+promo.promovidoPaterno+" "+promo.promovidoMaterno;      
        auxArreglo=[]                  
        let info=datos.filter((dat)=>dat.promovidoNombreFull.search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info);
    }
    function TablaPromo(datos) {
        let numRenglon=0;
        let tot= Object.keys(datos).length;
        let auxi2=["#","Sección","Tel/cel","INE","Nombre","email"];
        auxArreglo.push(auxi2);
       // console.log(tot);
       if(tot===0){
            let auxi3=["","Sin","Apoyos"]
            auxArreglo.push(auxi3);            
         }   
        return(
            <div id="tamTablaPromovReportApoyo">
                <ReactTooltip place="left" type="success" effect="solid" />
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>
                            <th style={{padding: 0.2 +'rem'}}>{tot}</th>                            
                            <th style={{padding: 0.2 +'rem'}}>Sección</th> 
                            <th style={{padding: 0.2 +'rem'}}>Tel/cel</th>                            
                            <th style={{padding: 0.2 +'rem'}}>INE</th>      
                            <th style={{padding: 0.2 +'rem'}}>Nombre</th>                                                                                                
                            <th style={{padding: 0.2 +'rem'}}>email</th>   
                        </tr>
                                                
                    </thead>  
                    <tbody>          
                        {datos.map((promo,index) => {
                            let auxNombre="";
                            //auxNombre = promotor.promovPaterno+" "+promotor.promovMaterno+" "+promotor.promovNombre ;
                           // console.log(promo);
                            auxNombre = promo.promovidoNombreFull;
                          //  let auxDireccion = obtenerDireccion(promo);
                            //console.log(auxNombre);
                            let auxTelefono="";
                            if (promo.promovidoTel !==""||promo.promovidoTel !==null) {
                                auxTelefono=promo.promovidoTel;

                            }else{
                                if (promo.promovidoCel !==""||promo.promovidoCel !==null) {
                                    auxTelefono=promo.promovidoCel;
                                }
                            }
                            let auxi=[index+1,promo.promovidoSeccion,auxTelefono,promo.promovidoINE,auxNombre,promo.promovidoEmail]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === promo.promovidoId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promo.promovidoId,promo) }  >
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{numRenglon}  </span></th>
                                                                                               
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promovidoSeccion}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxTelefono}  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promovidoINE}  </span></th>                                                    
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>                                
                                
                                 <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promo.promovidoEmail}  </span></th>                                                  
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
    const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[0-9._:$!%-({})/*?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-zñÑ]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
        
    };
    const contenido = promovido? (filtro(datos)):(TablaPromo(datos));
    const contenido2 = datos? contenido:null     
    const llamada= datos?(contenido):(<div><h2>No contiene datos</h2></div>)   
    const btnExportar = <span data-tip="Exportar Promovidos"><a className="pt-2 pl-2"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>     
    return (
        <div className="contenido-tbPromov  pb-2 pt-0 ">
        <div className="tile ">
            <div className="tile is-3 is-horizontal is-parent">
                <div className=" is-child box">                        
                    <div className="container">
                        <div className="field is-horizontal ">            
                            <div className=" field-body  ">
                                <input
                                    className="input " 
                                    type="promo"
                                    name="nombre"                         
                                    placeholder="Buscar promovido"
                                    value={nombre}
                                    onChange={onChange}/>
                                  
                            
                            </div>   
                            {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                            {btnExportar}
                            <ReactTooltip place="left" type="success" effect="solid" />
                        </div>
                        {llamada}		                                    
                    </div>
                </div>
            </div>
        </div>            
    </div>
      );
}
 
export default RepApoTablaPromovidos;