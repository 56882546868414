import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';

const RPGraficas = ({valor}) => {
    let color1= '#3273DC';
    let color2= '#FF0505E8';
    let metfinalt2="";
    //let metfinalt="";
    //console.log(valor);
    let AvanProm ="";
    let AvanMeta ="";
    let EfecProm ="";
    let EfecMeta ="";
    
  if (valor !== 0) {
     color1= '#3273DC';
     color2= '#FF0505E8';
     metfinalt2=(100-valor.Efectividad).toFixed(2);
    const metfinalt=(100-valor.Avance).toFixed(2);    
    //console.log(valor);
     AvanProm =valor.Avance+"% "+ "Promovidos";
     AvanMeta =(metfinalt)+"% "+ " Meta final";
     EfecProm =valor.Efectividad+"% "+ "Promovidos";
     EfecMeta =(metfinalt2)+"% "+ " Meta Actual";
    
  }
  let valEFecti = valor.Efectividad >= 100  ? (0):(100-valor.Efectividad );
  let auxAvance = valor.Avance >= 100  ? (0):(100-valor.Avance );
  const dataGrafica1 = {
    labels: ['Avance'],
    datasets: [
      {
        label: 'Promovidos',
        data: [valor.Avance],
        backgroundColor: color1,
      },
      {
        label: 'Meta Total',
        data: [auxAvance],
        backgroundColor: color2,
      },
    ],
  }
  const dataGrafica2 = {
    labels: ['Efectividad'],
    datasets: [
      {
        label: 'Promovidos',
        data: [valor.Efectividad],
        backgroundColor: color1,
      },
      {
        label: 'Meta Actual',
        data: [valEFecti],
        backgroundColor: color2,
      },
    ],
  }
      function  llenadoTabla(datos,nombre)
      {
        return(
            <div className="card  "id="RPgrafica" >
              <div className=" marcoGrafica" >
                <div className="content">
                {<span id="ajustarGrafica" style={{fontSize: '10px' }} >{nombre}</span>}
                  <HorizontalBar
                  data={datos}
                  width={300}
                  height={100}  
                  options={options}
                  />
                  </div>
              </div>
            
        </div>
        );
      }
      const options = {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 5
          }
        },
        title:{
          fontStyle: 'bold',
        },
       scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              scaleLabel :{
                fontSize:6,
              },
            },
          ],
        },
      }
    return (
        <div className="pl-3">      
            <div className="tile  ">
                <div className="content">
                  <div  className="columns ">      
                    <div className="column">
                        {llenadoTabla(dataGrafica1,'Promovidos '+valor.Promovidos+' / MetaTotal '+valor.MetaTotal)}
                    </div>
                    <div className="column">
                        {llenadoTabla(dataGrafica2,'MetaActual '+valor.MetaActual+' / MetaTotal '+valor.MetaTotal)}
                    </div>
                </div>
              </div>
        </div>
        </div>
      );
}
 
export default RPGraficas;