import React,{useState} from 'react';
//import LlenaPromovidos from './RPLlenaPromovidos';
import XLSX from 'xlsx';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import qs from 'qs';
import NumberFormat from 'react-number-format';
import ModalGaleria from  './PModalGaleria';
import AsignarVotacion from  './PModalAsignarVotacion'
const PTablaXSeccion = ({datosJS,setListaXSecCasilla,setActualizar,actualizar,setTituloSeccion, tituloMunicipio}) => {
    let datos=datosJS;    
    const [tabla2,setTabla]=  useState("");      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [nombrePromot,setNombrePromot]=useState("")
    let idusu =localStorage.getItem('UsuNum') ;
    const [mensaje,setMensaje]=useState("");
    const llamarApi=(numSeccion)=>{
        const source = axios.CancelToken.source();             
        async function conectarJSon()  {                                             
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/votacion-movil-seccion-casilla`;  
            let auxU=process.env.REACT_APP_LINK +`/votacion-movil-seccion-casilla`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                                      
                'periodo':3,                                
                'seccion':numSeccion,
                'idDispositivo':'7y8dyguwjhds'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux) 
                   {//console.log(aux);                       
                    setListaXSecCasilla(aux);
                   }              
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });                                  
        };
        conectarJSon();        
    }
    let auxArreglo=[];  
    const llamdaModal=()=>{
        setMensaje("is-active")
    }  
    const RenglonNum =(valorId,regis,PromNomb)=>{
        //console.log(regis);
        setTabla(valorId);
        //setNombrePromot(PromNomb);
        setTituloSeccion(valorId);
        llamarApi(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Votacion de secciones en "+tituloMunicipio +".xlsx")
    };
    function filtro(){        
        let auxCadena=nombre.trim();   
        auxArreglo=[]                  
        let info=datos.filter((dat)=>dat.seccNom.toUpperCase().search(auxCadena.toUpperCase() ) != -1)         
        return TablaPromo(info);
    }
    function Color(elcolor,auxvotos)
     {let colorEfecti= "etiqueta";      
      if(elcolor<30){
          colorEfecti= "etiqRojaTab";
      }
      else{
          if(elcolor>=30 && elcolor<60)
          {
              colorEfecti= "etiqNaraTab";               
          }
          else{
              if(elcolor>=60 )
              {
                  colorEfecti= "etiqVerdTab";
              }
          }
      }
      elcolor=Number(elcolor).toFixed(2);
      return (               
            <span id={colorEfecti} >
                 
                 <NumberFormat value= {auxvotos}displayType={'text'}  thousandSeparator={true}/>
            </span>         
          )      
    };
    function TablaPromo(auxdatos) {
        let numRenglon=0;
        let auxi2=["#","LNomminal","Votos","Municipio","Partido","Votación"];
        auxArreglo.push(auxi2);
        let tot= Object.keys(auxdatos).length;
        if(tot===0){
            let auxi3=["","Sin","Tipos"]
            auxArreglo.push(auxi3);            
        }           
        return(
            <div id="TablaPrepVotSecc">
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>            
                            <th style={{padding: 0.2 +'rem'}}>LNom</th>  
                            <th style={{padding: 0.2 +'rem'}}>Votos</th>  
                            <th style={{padding: 0.2 +'rem'}}>Secc</th>   
                            <th style={{padding: 0.2 +'rem'}}> </th>   
                            {/* <th style={{padding: 0.2 +'rem'}}>Partido</th>  */}
                            <th style={{padding: 0.2 +'rem'}}>Part</th> 
                            <th style={{padding: 0.2 +'rem'}}>Votos</th>            
                        </tr>                        
                    </thead>  
                    <tbody>          
                        {auxdatos.map((seccion,index) => {
                            let auxNombre="";
                            auxNombre= seccion.seccNom;    
                            //console.log(auxNombre);
                            let auxi=[index+1,seccion.lNominal, seccion.votacion,auxNombre,seccion.partNom,seccion.partVoto]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla2 === seccion.seccId ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(seccion.seccId,seccion,auxNombre) }  >                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">  <NumberFormat value= {seccion.lNominal} displayType={'text'}  thousandSeparator={true}/></span></th>                                
                                <th className="tablaTR" style={{padding: 0.2 +'rem'}}>                                         
                                    {Color((seccion.votacion/seccion.lNominal)*100 , seccion.votacion)}                    
                                </th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{auxNombre}  </span></th>
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                    <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span data-tip="Galeria de fotos">
                                        <a className="" onClick={llamdaModal}> <img src={tabla2 === seccion.seccId ? "galeriaBlanco.svg" : "galeria.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                    </span>                              
                                </th>
                                {/* <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{seccion.partNom}  </span></th> 
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{seccion.partNom+" "+seccion.partVoto}  </span></th>                                                                                                        
                                */}
                                
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                    <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span >
                                         <img src= {seccion.partImgWeb}  alt="" border="0" height="20" width="20"/>
                                        
                                    </span>                              
                                </th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{seccion.partVoto}  </span></th>
                            </tr>              
                            );
                        })
                        }                                
                    </tbody>
                </table>
            </div>
        );
        }
    

	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[A-Za-z._:$!%-({})?/*|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
        guardarPromovido({
            ...promovido, //copiamos el nombre
            [e.target.name] : e.target.value  
        })
    }        
    };
    const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Secciones"><a className="pt-2 pl-1"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  className="pt-2 " alt="" border="0" height="30" width="30"/> 
                        </a></span>
    const modal= mensaje? (<ModalGaleria datGen={registro} mostrar={mensaje} setMensaje={setMensaje} />):(null);    
    //const datGeneral =registro? ( <LlenaPromovidos promid={tabla2} nombrePromot={nombrePromot}/> ):(null)
    
    const btnNuevoRegistro=<div className="pl-1"><AsignarVotacion mostrar={mensaje} setMensaje={setMensaje} /></div>     
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    const btnExportar = <span data-tip="Exportar Secciones"><a className="pt-2 pl-1"  onClick={exportFile}>
        <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
    </a></span>
    return (

        <div className=" ">
            <div className="tile is-ancestor ">
                <div className="tile is-2 is-horizontal is-parent">
                    <div className="tile is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal">            
                                <div className="field-body">
                                    <input
                                        className="input" 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar Sección"
                                        value={nombre}
                                        onChange={onChange}/>
                                </div>
                                {/* <button disabled={!arreExcel.length>1} className="button pl-2" onClick={exportFile}>Exportar</button> */}
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                {btnRecargar /*  */}
                                {btnNuevoRegistro}
                                {modal}
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        
      );
}
  
export default PTablaXSeccion;