import React,{useState,useEffect} from 'react';
import Cabecera from './CabeceraPromoClase'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
import {Facebook} from "react-content-loader"
const Promotores = () => {
    const [datos,setDatos] = useState("");
    let history = useHistory();
    const [error,setError] = useState("");
    const [vacio,setVacio] = useState(true);
    let idruta =localStorage.getItem('RutaNum') ;
    let idcoord =localStorage.getItem('CoordNum') ;
    let idusu =localStorage.getItem('UsuNum') ;
    let mensaje="";
    let contador=0;
    const [secciones,setSecciones] = useState("");   
    let nivelUsuario =localStorage.getItem('TipoUsuario') ;     
    useEffect(()=>{ 
        const source = axios.CancelToken.source();
        async function conectarJSon()  {
            let idruta =localStorage.getItem('RutaNum') ;
            let idcoord =localStorage.getItem('CoordNum') ;    
        
            
            //console.log(`Entre API: [${valorRuta}]`)
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promotor`;   
            let auxU=process.env.REACT_APP_LINK +`/promotor`;   
            let token =localStorage.getItem('token20') ;    
            //console.log(`Ruta Store: ${promRuta}`)       
            let dataInfo = qs.stringify({
                'usuario': idusu,
                'coordinador': idcoord ,
                'ruta': idruta,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
           // console.log("antes del axios");
            await axios(config)
            .then(function (response) {
             //   console.log("entrando ");
                aux=  response.data;
               // console.log(aux.status);
                // const auxdat =  await aux; 
                if (aux.promotores) 
                {   setDatos(aux);
                }else{
                    setVacio(false)
                }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`) 
                //console.log("contador es : "+contador+" "+error);                    
                //contador++;
                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    //console.log("seguir imprimiendo");                
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                    setVacio(false)  
                }
            });
                
            //console.log(auxdat.promotores);   
                
            //}
        };
        async function llenaSeccion () 
        { 
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/seccion`;                  
            let auxU=process.env.REACT_APP_LINK +`/seccion`;                  
        let token =localStorage.getItem('token20') ;                 
        let dataInfo = qs.stringify({
            'usuario': idusu,             
            'idDispositivo': 'csdq21weddewde' 
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};
        await axios(config)
        .then(function (response) {
            aux=  response.data;                                
            if (aux.secciones) {
                     
                //console.log(`dentro secciones API: ${JSON.stringify(aux.secciones)}`) 
                setSecciones(aux.secciones);                                       
            }                            
        })
        .catch(function (error) {
            //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)             
            //console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
            
            if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
            {   console.log('loguearse de nuevo');
                localStorage.clear();
                //window.location.replace('');
               // history.push("/");                
                window.location.reload(true);
                //console.log("seguir imprimiendo");                
            }
            if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
            {
                console.log('sin datos' );
                //setVacio(false)  
            }
        }); 
        }
    if (nivelUsuario==='3'||nivelUsuario==='5'||nivelUsuario===''||nivelUsuario===' '||nivelUsuario===null) 
    {                  
        localStorage.clear();            
        history.push("/");                
        window.location.reload(true);      
    }
    else
    {
        if ( idcoord === null || idcoord === 0 ) {
            mensaje="el coordinador";
            if( idruta === "" || idruta === 0 || idruta === null )
            {
                mensaje=mensaje+" y la ruta";            
            }
            if(idusu === "" || idusu === 0||idusu === null)
            {
                console.log('loguearse de nuevo');
                localStorage.clear();
                //window.location.replace('');
                history.push("/");                
                window.location.reload(true);
            }
            else{
                setError(mensaje);
            }  
        }
        else{
            if( idruta === "" || idruta === 0  )
            {  // console.log("aiuda");
                mensaje=" la ruta";
                setError(mensaje);
            }
            else
            {   llenaSeccion();
                conectarJSon();
                
            }
        }
           
        
            
    }
    return ()=> {
        source.cancel();
    }    
        

},[]);

    //console.log(datos);
    //const cargando = <div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div>;   
    const cargando = vacio ? (<div> <Cargando /> <Facebook/></div>):(<h1 className="title is-l has-text-centered">Ruta sin promotores</h1>);
    const auxmensaje = error ? (<h1 className="title is-l has-text-centered">Falta seleccionar {error}</h1>) : (cargando);
    const content = secciones&&datos ? (<div ><Cabecera valores= {datos.promotores} seccion={secciones}/>   </div> ) : (auxmensaje);
    
    //const content = datos ? (<div ><Cabecera valores= {datos.promotores}/>   </div>  ) : (<div><progress className="progress is-small is-link" max="100">15%</progress><br/><h1 className="title is-l">Cargando...</h1></div>)
    return (
        <div className= "cabeza-promotor pt-2">
            {content}
        </div>
        
        
        );
}
 
export default Promotores;


