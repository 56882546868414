import React,{useState,useEffect} from 'react';
import PanelMun from './MPanelMunicipio';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
const IncidenciasInicio = () => {
    const[espera,setEspera]=useState(false);
    const [datos,setDatos]=  useState("");       
    let idusu =localStorage.getItem('UsuNum') ;
    let history = useHistory();
    const [actualizar,setActualizar] = useState(0);    
    useEffect(()=>
    {   const source = axios.CancelToken.source();     
        async function conectarJSon()  {         

            setEspera(false);            
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-web-promocion-listsecc`;               
            let auxU=process.env.REACT_APP_LINK +`/promovido-web-promocion-listsecc`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                            
                'idDispositivo': 'I9UC8SIDNJKS'                 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                  
                if (aux)
                { //  console.log(aux);
                    setDatos(aux)
                }                                                          
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log("se un error");
                console.log(error);
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {   localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );
                    
                }
            });  
            setEspera(true);            
        };                
        
        conectarJSon();
                
        return ()=> {
            source.cancel();
        }
    },[actualizar]);
    const auxPrincipal = espera ? (<PanelMun datosJS={datos} setActualizar={setActualizar} actualizar={actualizar} />):<Cargando/>
    //const auxPrincipal = espera ? ("Desde movilizacion"):<Cargando/>
    return (
        <div >                    
            {auxPrincipal}
        </div>
    );
}
 
export default IncidenciasInicio;