import React,{useState,useEffect} from 'react';
//import ModalP from "../Promos/PromoLlamadaModal";
//import ModalDatGen from './IncModalDatGen';
import ModalMovilizar from './ModalMovilizar';
import ReactTooltip from "react-tooltip";
import XLSX from 'xlsx';
import moment from 'moment';
const MTablaPromovidos = ({valores,numSeccion,espera}) => {
    //let datos=valores;        
    let auxArreglo=[]
    const [tabla,setTabla]=  useState("");   
    const [datos,setDatos]=  useState([]);      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [registro,setRegistro]=useState("")
    const [mensaje,setMensaje]=useState("");
    const [mensaje2,setMensaje2]=useState("");
    //const [arreExcel,setArreExcel]=useState(["#","Horario","Estatus","Nombre","Tel","Cel"])
    useEffect(()=>{                         
        //let today2 = new Date();                    
        //let fec2 = moment(today2);
                
        //console.log("guardando los datos de la api "+fec2.format("HHmmss.SSS")); 
        setDatos(valores)                
        
    },[valores]);
    const RenglonNum =(valorId,regis)=>{
        //console.log(regis);
        
        setTabla(valorId);
        setRegistro(regis);
    }
    const exportFile = () => {
        /* convert state to workbook */
        let today = new Date();                    
        let fec = moment(today);
        //console.log(fec.format("YYMMDDHHMMSS"));
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista de promovidos de " +numSeccion+" .xlsx")
    };
    const llamdaModal=()=>{
        setMensaje("is-active")
    }
    const llamdaModal2=()=>{
        setMensaje2("is-active")
    }
    function filtro(){        
        let auxCadena=nombre.trim();    
        auxArreglo=[]               
        let info=datos.filter((dat)=>dat.Nombre.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info);
    }
    function Color(leyenda,elcolor)
     {let colorEfecti= "etiqueta";      
      
          if(elcolor===0)
          {
              colorEfecti= "etiqRojaTab";               
          }
          else{
              if(elcolor===1 )
              {
                  colorEfecti= "etiqVerdTab";
              }
          }
      
      //elcolor=Number(elcolor).toFixed(2);
      return (               
            <span id={colorEfecti} >
                 
                 {leyenda}
            </span>         
          )      
    };
    function TablaPromo(auxdatos) {
        let numRenglon=0;
        let tot= Object.keys(auxdatos).length;
        let auxi2=["#","Estatus","Horario","Nombre","Tel","Cel"];        
        auxArreglo.push(auxi2);
       // console.log(tot);
       //let today = new Date();                    
        //let fec = moment(today);
        //console.log("mostrando los datos de la api " +fec.format("HHmmss.SSS"));      
       if(tot===0){
            let auxi3=["","Sin","Incidencias" ,"de este ","tipo"]
            auxArreglo.push(auxi3);            
        }   
        return(
            <div id="TablaMoviliPromv">
                <ReactTooltip place="left" type="success" effect="solid" />
                <table className="table is-sobrepuesto " style={{overflowY: +'auto' }} >
                    <thead >    
                        <tr>                                                        
                                         
                            <th style={{padding: 0.2 +'rem'}}>Estatus</th> 
                            <th style={{padding: 0.2 +'rem'}}>{tot} </th>  
                            <th style={{padding: 0.2 +'rem'}}>Hora</th>
                            
                            <th style={{padding: 0.2 +'rem'}}>Nombre</th>      
                            <th style={{padding: 0.2 +'rem'}}>Tel</th>                                           
                            <th style={{padding: 0.2 +'rem'}}>Cel</th>                                           
                        </tr>                                                
                    </thead>  
                    <tbody>          
                        {auxdatos.map((promov,index) => {        
        
                            let auxMovi = promov.Moviliza ===1 ?"Movilizado" :"Pendiente";                                                            
                            let auxi=[ index+1 ,auxMovi , promov.Hora, promov.Nombre , promov.Tel,promov.Cel ]
                            auxArreglo.push(auxi);
                            numRenglon++;                          
                        return (                  
                            <tr className={ tabla === promov.Id ? 'is-seleccionado' : '' }  key={numRenglon} onClick={ () => RenglonNum(promov.Id,promov) }  >                                
                                <th className="tablaTR" style={{padding: 0.2 +'rem'}}>                                         
                                    {Color(auxMovi,promov.Moviliza)}                    
                                </th>
                                <th className="has-text-centered  " style={{padding: 0+'rem'}}>
                                <div style={{paddingTop: 0.2+'rem'}}></div>
                                    <span data-tip="Información de la incidencia">
                                        <a className="" onClick={llamdaModal2}> <img src={tabla === promov.Id ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                    </span>                              
                                </th>                                                                                                                                                                                                                  
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promov.Hora}  </span></th>
                                
                                
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7">{promov.Nombre }  </span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7 " > {promov.Tel}</span></th>
                                <th className="tablaTR " style={{padding: 0.2 +'rem'}}><span className="alinear is-size-7 " > {promov.Cel}</span></th>                                                                                                          
                            </tr>              
                            );
                        })
                        }                                
         
                    </tbody>
                </table>
            </div>
        );
        }

    
	//extraer el promo
	const {nombre} = promovido;        
    const onChange = e =>
    {   let expNopermitida = new RegExp('[0-9._:$!%-({})/*?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&!expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
        
    };
    //
    /*const btnActualizar=e=>{
        e.preventDefault();
        //numRenglon++;
        //console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <span data-tip="Recuperar Promovidos"><a className="pt-2 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  className="pt-2 " alt="" border="0" height="30" width="30"/> 
                        </a></span>*/
    const btnExportar = <span data-tip="Exportar Promovidos"><a className="pt-2 pl-2"  onClick={exportFile}>
                            <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
                        </a></span>                        
    
    const modal2= mensaje2? (<ModalMovilizar promovido={registro} mostrar={mensaje2} setMensaje={setMensaje2} datos={datos} setDatos={setDatos} />):(null);      
   //const modal1= mensaje? (<ModalPrograma idValor={tabla} mostrar={mensaje} setMensaje={setMensaje}/>):(null);        
   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= valores?(contenido):(<div><h2>No contiene datos</h2></div>)        
    return (       
        <div className="  pb-2 pt-0 ">
            <div className="tile ">
                <div className="tile is-3 is-horizontal is-parent">
                    <div className=" is-child box">                        
                        <div className="container">
                            <div className="field is-horizontal ">            
                                <div className=" field-body  ">
                                    <input
                                        className="input " 
                                        type="promo"
                                        name="nombre"                         
                                        placeholder="Buscar Promovido"
                                        value={nombre}
                                        onChange={onChange}/>
                                      
                                
                                </div>                                
                                {btnExportar}
                                <ReactTooltip place="left" type="success" effect="solid" />
                                
                                
                            </div>		            
                            {llamada}
                        </div>
                    </div>
                </div>
            </div>    
            
            {modal2 }
        </div>
           
     
    );
}
 
export default MTablaPromovidos;