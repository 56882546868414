import React,{useState,useEffect} from 'react';
import MapaEditar  from './MapaInicio2';
import MapaConsultar  from './MapaConsultar';
import MapaRastreo from './MapaRastreo';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
const Mapa = () => {
    
    const [seleccion,setSeleccion] = useState(0);
    const [datos,setDatos] = useState("");
    const [vacio,setVacio] = useState(true);   
    let idusu =localStorage.getItem('UsuNum') ;
    let token =localStorage.getItem('token20') ;        
    let history = useHistory();
    function onClick(valor) {
        setSeleccion(valor);
    }
    useEffect(()=>{                 
        const source = axios.CancelToken.source();
        setVacio(false);  
        
        const Poligonos = async () => {
            // console.log("entrando a la llamada");
             //let auxU=`http://`+process.env.REACT_APP_LINK +`/seccion-poligono`;     
             let auxU=process.env.REACT_APP_LINK +`/seccion-poligono`;            
             let dataInfo = qs.stringify({
                 'usuario': idusu,
                 'idDispositivo': 'csdq21weddewde' 
                 });
             let config = {
                 url:auxU ,
                 method: 'POST',
                 headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                 data: dataInfo
             };
         
             let aux ={};
             await axios(config)
             .then(function (response) {
                 aux =  response.data;
                 if (aux.resultado) 
                 {   
                      setDatos(aux.resultado);    
                 }
             })
             .catch(function (error) {
                 console.log(`Error: ${error.response.data.mensaje} Codigo: ${error.response.data.error.name}`);
                 if (error.response.data.error.name==="JsonWebTokenError" ||error.response.data.error.name==="TokenExpiredError") 
                 {  // console.log(error.response.data.error.name);
                     //console.log('loguearse de nuevo');
                     localStorage.clear();
                     //window.location.replace('');
                     history.push("/");                
                     window.location.reload(true);
                     //console.log("seguir imprimiendo");                
                 }
                 else{
                     if (error.response.data.mensaje==="No se agrego el Token en los Headers de la petición.") {
                         console.log('loguearse de nuevo');
                       //  localStorage.clear();
                         //window.location.replace('');
                         history.push("/");                
                         window.location.reload(true);
                     }
                 }
             });    
             }        
        Poligonos();
        setVacio(true)  

    },[]);
    const selectorBread =   <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                                <ul>
                                    <li className={seleccion===1?("is-active"):("")}>
                                        <a href="#" onClick={()=>onClick(1)}>Consultar</a>
                                    </li>
                                    <li className={seleccion===2?("is-active"):("")}>
                                        <a href="#" aria-current="page" onClick={()=>onClick(2)}>Editar</a>
                                    </li>          
                                    {/* <li className={seleccion===3?("is-active"):("")}>
                                        <a href="#" aria-current="page" onClick={()=>onClick(3)}>Rastreo</a>
                                    </li> */}
                                </ul>
                            </nav>

    const ventana = ()=>{
        if ( seleccion === 1 )
        {
            console.log(seleccion);
            return <MapaConsultar/>  ;
        }
        else
        {   if ( seleccion === 2 ) 
            {console.log(seleccion);
                return <MapaEditar/>  ;
            }
            else
            {   if ( seleccion === 3 )
                {console.log(seleccion);
                    return <MapaRastreo/>;
                }                   
            }   
        }
    }
    const mapEdit2= seleccion===3 ? (<MapaRastreo/>):(null)
    const mapEdit= seleccion===2 ? (<MapaEditar/>):(mapEdit2)
    const contenido= seleccion===1 ? (<MapaConsultar poligonos={datos}/>):(mapEdit)
    return (
        <div>
            {selectorBread}
            {contenido}
        </div>
      );
}
 
export default Mapa;