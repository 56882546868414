import React,{useState,useEffect} from 'react';
import TablaPromotor from './BusqTablaPromotor';
import TablaUnPromotor from './BusqTablaUnPromotor';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
const Busqueda = ({datos,lista,validoPromotor,secciones}) => {

    let nivelUsuario =localStorage.getItem('TipoUsuario') ;    
    
    

    //const cargando = vacio ? (<div> <Facebook/></div>):(<h1 className="title is-l has-text-centered">Ruta sin promotores</h1>);
//    const auxmensaje = error ? (<h1 className="title is-l has-text-centered">Falta seleccionar {error}</h1>) : (cargando);
    const carga = <TablaPromotor datosJS={datos} seccion={secciones} validoPromotor={validoPromotor} />;
    const tablaPromo = (nivelUsuario==='3'||nivelUsuario==='5')/*&& !(idusu==='1'||idusu==='3'||idusu==='4'||idusu==='257')*/ ? (<div ><TablaUnPromotor datosJS={datos} seccion={secciones}  lista={lista} validoPromotor={validoPromotor}/>     </div> ) : ( carga);
    const content =secciones&&datos ? (<div >{ tablaPromo}     </div> ) : ( <Cargando />);

    return (<div className="is-flex-direction-row ">
            
                {content}                      
        </div> 
         );
}
 
export default Busqueda;