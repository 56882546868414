import React,{useState,useEffect} from 'react';
import {HorizontalBar} from 'react-chartjs-2';
//import BuscaColor from "../Color";
//import Chart from 'chart.js';

const PGraficaMunicipio = ({valor,descripcion,Auxtam}) => {  
  
    let colorTabla =[];
    const auxCoordi = valor;
    let contador=0; 
    //let miLabel=[];
    //let midata=[];
    //let miColor=[];
    const [miLabel,setMiLabel]=  useState([]);      
    const [midata,setMidata]=  useState([]);      
    const [miColor,setMiColor]=  useState([]);    
    let auxDis ="espacioGraf2";
    useEffect(()=>
    {  contador=0;
        let auxiliar =""
        let auxArreglo=[];
        let auxmiLabel=[]
        let auxmidata=[]
        let auxmiColor=[]
        auxArreglo.push({ value : -1 , label : "Excel" })
        auxArreglo.push({ value : 0 , label : "Secc" })
        function SumaPartidos() 
        {          
          auxCoordi.forEach( secc =>
          {             
              if (contador<5) {
                const voto=secc.Voto>0?secc.Voto:""
                auxmidata[contador]=secc.Voto;
                auxmiLabel[contador]=secc.ParImg+" "+voto;                
                auxmiColor[contador]=secc.ParColor.substring(0, 7);    
                contador++; 
              }            
          });  
        }
        SumaPartidos();        
        setMiLabel(auxmiLabel);
        setMidata(auxmidata);
        setMiColor(auxmiColor);

    },[valor]);
    
   /* auxCoordi.map((coordi,index)  =>{
      if (index<5) {
        midata[index]=coordi.Voto;
      miLabel[index]=coordi.ParImg +" "+coordi.Voto;      
      miColor[index]=coordi.ParColor;
        console.log(coordi.ParColor);      
      contador++;   
      }
      
       
   });*/ 
   

    const options = {
      legend: {
        display: false,
        
      },
      
      tooltips: {
        enabled: true,
      },
      layout: {
        padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 5
        }
      },
      
      title:{
        fontStyle: 'bold',
      },
     scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            scaleLabel :{
              fontSize:6,
            },
          },
        ],
      },
    }  
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Votos',//
          backgroundColor: miColor,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: '#3273DC',
          hoverBorderColor: 'rgb(0,88,101)',
          data: midata 
        }
      ]
    };    
    if (Auxtam===1) {
      auxDis="espacioGraf";
    }

  return ( 
    <div className="card  "id="PrepVotosgrafica" >
      <div className=" marcoGraficaIncid" >
        <div className="content">
          {<h4 className=" pt-1 pb-0"  style={{fontWeight: 700},{fontSize: 10+"px"},{textAlign: "center"}}>{descripcion}</h4>}
          <HorizontalBar
              id="graficaHorBarIncidencia"
              data={horizontalRuta}
              width={1200}
              height={400}
              options={options}
            />                   
        </div>
      </div>
  </div>
   );
}

 
export default PGraficaMunicipio;