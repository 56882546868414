import React from 'react';
import NumberFormat from 'react-number-format';
const Seccion = ({valor}) => {
    //console.log("recibiendo datos:");
    let auxAGlobal=valor.avance_global;
    let auxEfe=valor.efectividad;
    //console.log(auxAGlobal);
    // console.log(valor);
    //console.log("seccion");
    //console.log(auxEfe);
    
    function llenadoTiles(nombre,datos) {
        let colorEti='etiqBlanGfS';
        let auxDatos =datos;        
        
        if(auxDatos!==''){
            //auxDatos=datos;
            if(nombre.trim()==='Avance Global')
            {  //console.log(datos);
                auxDatos=datos+'%';
            }
            if(nombre.trim()==='Efectividad')
            {  //console.log(auxDatos);
                colorEti=validandoColor(auxDatos);
                auxDatos=datos+'%';
            }
        }
        else{
            auxDatos="";
        }              
        return(            
            <div className="tile is-parent" >
                <article className="tile is-child box  ">                
                    <p className="title" id={colorEti}><span id="ajustarTitulo">{auxDatos}</span></p>                
                    <p className="subtitle" id="ajustarTitulo">{nombre}</p>                                
                </article>
            </div>                        
        );
    }
    
    function validandoColor(vEfecti) {
        var colorEfecti='dato';    
        if(vEfecti<60||vEfecti===0){
            colorEfecti= 'etiqRojaGfS';        
        }
        else{
            if(vEfecti>=60 && vEfecti<80)
            {
                colorEfecti= 'etiqNaraGfS';               
            }
            else{
                if(vEfecti>=80 )
                {
                    colorEfecti= 'etiqVerdGfS';
                }
            }
        }
        
        return(colorEfecti);
    }
    
    return ( 
        
        <section className="" >
            <div className="tile is-ancestor">
                <div>{llenadoTiles('Meta final',<NumberFormat value={valor.meta_final} displayType={'text'}  thousandSeparator={true}/>)}</div>
                <div>{llenadoTiles('Promovidos',<NumberFormat value={valor.promovidos} displayType={'text'}  thousandSeparator={true}/>)}</div>
                <div>{llenadoTiles('Avance Global',auxAGlobal)}</div>
                <div>{llenadoTiles('Meta actual',<NumberFormat value={valor.meta_actual} displayType={'text'}  thousandSeparator={true}/>)}</div>
                <div>{llenadoTiles('Efectividad',auxEfe)}</div> 
            </div>                    
        </section>
    );
    }
     
export default Seccion;