import React from 'react';
//import logo from '../../map.svg';
//import logo2 from '../../photo-camera.svg';
import TablaProgramas from './PromoTablaProgramas';
const DatGenPromov = ({datGen}) => {
    
    let foto =datGen.promFoto;
    let nombre=datGen.promNombre;
    let calle=datGen.promCalle;
    let colonia=datGen.promColonia;
    let NoExt=datGen.promNoExt;
    let NoInt= datGen.promNoInt;
    //let auxNum="NoExt: "+NoExt+ "     NoInt: " +NoInt;
    let CodPost=datGen.promCP;
    let telefono=datGen.promTel ;
    let celular=datGen.promCel;
    let email=datGen.promEmail;
    let seccion =datGen.promSeccion;
    let ine =datGen.promINE;
    let prefe = datGen.promPreferencia;
    let participacion= datGen.promParticipaRGRC
    //console.log(datGen.promId);

    function auxFoto(){
        return (<div id="card-img-izq ">        
        <article className="tile is-child ">                    
            <figure className="image is-4by3 ">
                <img src={foto}/>                        
            </figure>
        </article>
        <div id="card-img-der">                                
            {/* <img width="30"  src={logo} />                    
            <img width="30"  src={logo2} />       */}              
        </div>                                                                                                                                                            
    </div>)
    }
    let cambiaPartici=participacion===0 ?('No'):('Si');
    let auxParti =(participacion ===0 ||participacion===1 )? (cambiaPartici):(null);
    let auxNumInt =(NoInt !==''&&NoInt!==null&&NoInt!==" " )? ("   NoInt: "+NoInt):("")
    let numeros = (NoExt !==''&&NoExt!==null&&NoExt!==" " )? ("NoExt: "+NoExt +auxNumInt):(null)
    const fotografia = (foto) ? (auxFoto()): (<div className="box is-1"><h1 className="has-text-centered">Sin fotografia</h1></div>);    
    
    return (
        <div id="card-img-izq tile is-parent  " className="tile  is-horizontal">
            <div className="tile is-4 pl-3 pr-6 mx-2">
                <div className="tile">
                    <div className="tile is-vertical">
                        {fotografia}
                        <article className="tile is-child box  ">
                        <p className="tamLetra2"  style={{ fontWeight:700}}>{nombre}</p>                            
                        <p className="tamLetra2" style={{ fontSize:1.2+'rem', fontWeight:500}}> <span className="letraColorSecc" >{seccion}</span> <span >{ine}</span></p>                                                                                
                            <p className="tamLetra2"  style={{ fontWeight:700}}>{nombre}</p>                            
                            {prefe ? (<p className="tamLetra2">Preferencia política <span className="letraColorSecc" >{prefe}</span> </p>):(null)} 
                            {auxParti ? (<p className="tamLetra2">Participacion RG / RC <span className="letraColorSecc" >{cambiaPartici}</span> </p>):(null)} 
                            <p className="tamLetra2">Colonia: {colonia}</p>
                            <p className="tamLetra2">Calle: {calle}</p>                           
                            {numeros ? (<p className="tamLetra2">{numeros} </p>):(null)} 
                            <p className="tamLetra2">Codigo Postal: {CodPost}</p>                            
                            {(telefono!==''&&telefono!==null&&telefono!==" " )? (<p className="tamLetra2">Telefono: {telefono} </p>):(null)} 
                            {(celular !==''&&celular!==null&&celular!==" " )? (<p className="tamLetra2">Celular: {celular} </p>):(null)} 
                            {(email !==''&&email!==null&&email!==" ") ? (<p className="tamLetra2">Correo: {email} </p>):(null)}                           
                               
                        </article>
                    </div>      
                </div>    
            </div>  
            <div >
                <TablaProgramas idValor={datGen.promId} />
            </div>
        </div>
    
      );
}
 
export default DatGenPromov;