import React,{useState,useEffect} from 'react';
import TablaPromovidos from './BusqTablaPromovidos'
import TablaSinPromov from './BusqTablaSinPromv'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Cargando from '../layout/Cargando2';
const BusqPanelPromovido = ({promId,secciones,infoPromot,listaPromot,validoPromotor}) => {

    const [datos,setDatos] = useState("");
    let history = useHistory();
    const[espera,setEspera]=useState(false);
    const [vacio,setVacio] = useState(false); 
    const [lista,setLista]= useState(""); 
    const [actualizar,setActualizar] = useState(0); 
   // let idusu =localStorage.getItem('UsuNum') ;
    //let mensaje="";
    
   
    useEffect(()=>{ 
        const source = axios.CancelToken.source();
        async function conectarJSon()  {
            //setActualizar(false);
            setEspera(false);
            //let auxU=`http://`+process.env.REACT_APP_LINK +`/promovido-listxpromotor`;   
            let auxU=process.env.REACT_APP_LINK +`/promovido-listxpromotor`;   
            let token =localStorage.getItem('token20') ;         
            let dataInfo = qs.stringify({
                'promotor': promId,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            setVacio(true)
           // console.log("antes del axios");
            await axios(config)
            .then(function (response) {
               //console.log("entrando ");
                aux=  response.data;
                //console.log(aux.status);
                // const auxdat =  await aux; 
                if (aux.resultado) {
                    //console.log("contador es : "+contador);
                   // console.log(aux.resultado);
             //       contador++;             
                    setDatos(aux.resultado);
                    setVacio(true)
                }else{
                   // console.log("vacio");
                    setDatos("");
                    setVacio(false)
                }
                            
                //console.log(`Promotores API: ${JSON.stringify(response.data)}`) 
            })
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`) 
                //console.log("contador es : "+contador+" "+error);                    
                //contador++;
                //console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {
                    //console.log('loguearse de nuevo');
                    localStorage.clear();
                    //window.location.replace('');
                    history.push("/");                
                    window.location.reload(true);
                    //console.log("seguir imprimiendo");                
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                  //  console.log('sin datos' );
                    setVacio(false)  
                }
            });
            setEspera(true)  ;
            //console.log(auxdat.promotores);   
                
            //}
        };           
        conectarJSon();

       const llenaPromo=()=> {
            //console.log(`Cabecera Llena Select`);
            
            let arrePromot=[];   
            listaPromot.map((promotor) => {
                arrePromot.push({ value : promotor.PromotId, label :promotor.PromotNombre })
            })
            setLista( arrePromot);
            
        }
        llenaPromo();
        return ()=> {
            source.cancel();
        }               
},[promId,actualizar]);


const carga = vacio ?(<div className="pl-4 pt-2"><Cargando /></div> ):(<div><TablaSinPromov seccion={secciones} infoPromot={infoPromot} setActualizar={setActualizar} actualizar={actualizar}/></div>);
const content = espera && datos!=="" ? (<div > <TablaPromovidos valores={datos} seccion={secciones} infoPromot={infoPromot} setActualizar={setActualizar} actualizar={actualizar} validoPromotor={validoPromotor}  listaPromot={lista} />    </div> ) : ( carga);

    return (
        <div className="is-flex-direction-column">            
            {content}
           
         </div>
      );
}
 
export default BusqPanelPromovido;